import { openPopup } from "@vfit/shared/data-access";
var handleFWA = function(linkFwa) {
    if (linkFwa) openPopup(linkFwa);
};
var handleKo = function(linkKO) {
    if (linkKO) openPopup(linkKO);
};
var handleR2Logged = function(linkR2) {
    if (linkR2) openPopup(linkR2);
};
export { handleFWA, handleR2Logged, handleKo };
