import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var useHeroSlider = function(heroSlides, isReverse) {
    var slides = heroSlides === null || heroSlides === void 0 ? void 0 : heroSlides.slides;
    if (!slides) {
        return {
            slides: []
        };
    }
    return {
        slides: isReverse ? _to_consumable_array(slides).reverse() : slides
    };
};
