export var organizeModalData = function(termsAndConditionCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    return {
        title: (termsAndConditionCMS === null || termsAndConditionCMS === void 0 ? void 0 : (ref = termsAndConditionCMS.termsandconditions) === null || ref === void 0 ? void 0 : (ref1 = ref.activationDetail) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        itemList: termsAndConditionCMS === null || termsAndConditionCMS === void 0 ? void 0 : (ref2 = termsAndConditionCMS.termsandconditions) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.activationDetail) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.descriptions) === null || ref4 === void 0 ? void 0 : ref4.map(function(el) {
            return {
                subtitle: (el === null || el === void 0 ? void 0 : el.title) || "",
                paragraph: (el === null || el === void 0 ? void 0 : el.text) || ""
            };
        }),
        accordions: (termsAndConditionCMS === null || termsAndConditionCMS === void 0 ? void 0 : (ref5 = termsAndConditionCMS.termsandconditions) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.activationDetail) === null || ref6 === void 0 ? void 0 : ref6.accordion) || []
    };
};
export var organizeTermsAndCondition = function(termsAndConditionCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
    var prefix = termsAndConditionCMS === null || termsAndConditionCMS === void 0 ? void 0 : (ref = termsAndConditionCMS.termsandconditions) === null || ref === void 0 ? void 0 : ref.card;
    var dataToReturn = {
        title: (prefix === null || prefix === void 0 ? void 0 : prefix.title) || "",
        description: (prefix === null || prefix === void 0 ? void 0 : prefix.description) || "",
        documents: termsAndConditionCMS === null || termsAndConditionCMS === void 0 ? void 0 : (ref1 = termsAndConditionCMS.termsandconditions) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.modals) === null || ref2 === void 0 ? void 0 : ref2.map(function(modals) {
            return {
                text: (modals === null || modals === void 0 ? void 0 : modals.title) || "",
                content: (modals === null || modals === void 0 ? void 0 : modals.description) || "",
                pdfUrl: (modals === null || modals === void 0 ? void 0 : modals.url) || "",
                pdfLabel: (modals === null || modals === void 0 ? void 0 : modals.buttonLabel) || ""
            };
        }),
        documentsCheck: (prefix === null || prefix === void 0 ? void 0 : prefix.conditions) || "",
        subtitle: (prefix === null || prefix === void 0 ? void 0 : (ref3 = prefix.activation) === null || ref3 === void 0 ? void 0 : ref3.title) || "",
        details: {
            text: (prefix === null || prefix === void 0 ? void 0 : (ref4 = prefix.activation) === null || ref4 === void 0 ? void 0 : ref4.detailLinkLabel) || "",
            content: (prefix === null || prefix === void 0 ? void 0 : (ref5 = prefix.activation) === null || ref5 === void 0 ? void 0 : ref5.description) || ""
        },
        subDescription: (prefix === null || prefix === void 0 ? void 0 : (ref6 = prefix.activation) === null || ref6 === void 0 ? void 0 : ref6.description) || "",
        checklist: [
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref7 = prefix.activation) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.choice) === null || ref8 === void 0 ? void 0 : ref8.option1) || "",
                value: "yes"
            },
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref9 = prefix.activation) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.choice) === null || ref10 === void 0 ? void 0 : ref10.option2) || "",
                value: "no"
            }, 
        ]
    };
    return dataToReturn;
};
export var getWithdrawalLabels = function(cmsData) {
    var ref;
    var withdrawal = cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.termsandconditions) === null || ref === void 0 ? void 0 : ref.withdrawal;
    return {
        titlePopup: (withdrawal === null || withdrawal === void 0 ? void 0 : withdrawal.title) || "Sei sicuro?",
        descriptionPopup: (withdrawal === null || withdrawal === void 0 ? void 0 : withdrawal.description) || "",
        imagePopup: (withdrawal === null || withdrawal === void 0 ? void 0 : withdrawal.imageUrl) || "",
        buttonWaitLabel: (withdrawal === null || withdrawal === void 0 ? void 0 : withdrawal.labelButtonSubmit) || "",
        buttonActiveLabel: (withdrawal === null || withdrawal === void 0 ? void 0 : withdrawal.labelButtonSecond) || ""
    };
};
export var organizeTermsCard = function(cmsData) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    return {
        titleModal: (cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.termsandconditions) === null || ref === void 0 ? void 0 : (ref1 = ref.card) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.modalError) === null || ref2 === void 0 ? void 0 : ref2.title) || "",
        messageModal: (cmsData === null || cmsData === void 0 ? void 0 : (ref3 = cmsData.termsandconditions) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.card) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.modalError) === null || ref5 === void 0 ? void 0 : ref5.message) || ""
    };
};
