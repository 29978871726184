import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
/**
 * Return back the formatted string to be pushed in query string parameters
 * @param val
 * @returns
 */ export var formatQueryParam = function(val) {
    var ref;
    return (ref = val === null || val === void 0 ? void 0 : val.toLowerCase()) === null || ref === void 0 ? void 0 : ref.replace(/\s/g, "-");
};
/**
 * Organize Device, mapping from CMS device in FE device
 * @param device
 * @param slug
 */ export var organizeDevice = function(device, slug) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13;
    return _object_spread_props(_object_spread({}, device), {
        devicedetail: _object_spread_props(_object_spread({}, device.devicedetail), {
            slug: slug,
            isAvailable: (device === null || device === void 0 ? void 0 : (ref = device.devicedetail) === null || ref === void 0 ? void 0 : ref.isAvailable) === "true",
            isSellable: (device === null || device === void 0 ? void 0 : (ref1 = device.devicedetail) === null || ref1 === void 0 ? void 0 : ref1.isSellable) === "true",
            haveMarketingLabel: (device === null || device === void 0 ? void 0 : (ref2 = device.devicedetail) === null || ref2 === void 0 ? void 0 : ref2.haveMarketingLabel) === "true",
            hub: _object_spread_props(_object_spread({}, device === null || device === void 0 ? void 0 : (ref3 = device.devicedetail) === null || ref3 === void 0 ? void 0 : ref3.hub), {
                availableColor: _object_spread_props(_object_spread({}, device === null || device === void 0 ? void 0 : (ref4 = device.devicedetail) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.hub) === null || ref5 === void 0 ? void 0 : ref5.availableColor), {
                    enabled: (device === null || device === void 0 ? void 0 : (ref6 = device.devicedetail) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.hub) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.availableColor) === null || ref8 === void 0 ? void 0 : ref8.enabled) === "true"
                })
            }),
            pdp: _object_spread_props(_object_spread({}, device === null || device === void 0 ? void 0 : (ref9 = device.devicedetail) === null || ref9 === void 0 ? void 0 : ref9.pdp), {
                keywords: ((ref11 = device === null || device === void 0 ? void 0 : (ref10 = device.devicedetail) === null || ref10 === void 0 ? void 0 : ref10.pdp.keywords) === null || ref11 === void 0 ? void 0 : ref11.map(function(keyword) {
                    return keyword.key;
                })) || []
            }),
            models: (device === null || device === void 0 ? void 0 : (ref12 = device.devicedetail) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.models) === null || ref13 === void 0 ? void 0 : ref13.map(function(model) {
                var ref;
                return _object_spread_props(_object_spread({}, model), {
                    isDefault: model.isDefault === "true",
                    isAvailable: model.isAvailable === "true",
                    isSellable: model.isSellable === "true",
                    images: ((ref = model.images) === null || ref === void 0 ? void 0 : ref.map(function(image) {
                        return image.key;
                    })) || []
                });
            })) || []
        })
    });
};
