import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { useMutation, useQueryClient } from "react-query";
import { errorMock } from "../../utils/utils";
var acceptCampaignService = function(campaignId, payload) {
    return nextClient.post("".concat(API.CAMPAIGN, "/").concat(campaignId, "/accept"), payload);
};
export var useAcceptCampaign = function() {
    var queryClient = useQueryClient();
    return useMutation("acceptCampaign", function(param) {
        var phoneNumber = param.phoneNumber, campaignId = param.campaignId;
        var ref, ref1;
        var getSubscriberByResource = queryClient.getQueryData("getSubscriberByResource");
        var audienceId = (getSubscriberByResource === null || getSubscriberByResource === void 0 ? void 0 : (ref = getSubscriberByResource[0]) === null || ref === void 0 ? void 0 : ref.subscriberId) || "";
        var customerId = (getSubscriberByResource === null || getSubscriberByResource === void 0 ? void 0 : (ref1 = getSubscriberByResource[0]) === null || ref1 === void 0 ? void 0 : ref1.customerId) || "";
        var _payload = {
            audienceType: "SUBSCRIBER_ID",
            msisdn: phoneNumber,
            isWinbackCampaign: "true",
            audienceId: audienceId,
            customerId: customerId
        };
        return acceptCampaignService(campaignId, _payload);
    }, {
        onSuccess: function(data) {
            queryClient.setQueryData("acceptCampaign", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - acceptCampaign", error);
            queryClient.setQueryData("acceptCampaign", errorMock("acceptCampaign", error));
        }
    });
};
