import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
/**
 * Recall service POST /updateProductCharacteristicLight
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ var updateProductCharacteristicLightService = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.UPDATE_PRODUCT_CHARACTERISTIC_LIGHT), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run updateProductCharacteristicLightService.
 * @returns
 */ export var useUpdateProductCharacteristicLight = function() {
    var queryClient = useQueryClient();
    // const consensus: any | undefined = queryClient.getQueryData('consensusMutation');
    var shoppingCartQuery = queryClient.getQueryData("shoppingCart");
    var shoppingCartSaved = localStorage.getItem("shoppingCart");
    var shoppingCart = !!shoppingCartSaved && JSON.parse(shoppingCartSaved) || shoppingCartQuery;
    var cartId = shoppingCart && (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id);
    return useMutation([
        "doUpdateProductCharacteristicLight"
    ], function(optionToReconsider) {
        var payload = {
            attributeMap: [
                {
                    key: "IS_CONTRACT_SIGNED",
                    value: "Y"
                }, 
            ],
            optionToReconsider: optionToReconsider.replace(/^./, optionToReconsider[0].toUpperCase()),
            runRules: false
        };
        return updateProductCharacteristicLightService(cartId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("updateProductCharacteristicLight", JSON.stringify(data));
            queryClient.setQueryData("updateProductCharacteristicLight", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - updateProductCharacteristicLight: ", error);
            queryClient.setQueryData("updateProductCharacteristicLight", errorMock("updateProductCharacteristicLight", error));
            localStorage.setItem("updateProductCharacteristicLight", JSON.stringify(errorMock("updateProductCharacteristicLight", error)));
        }
    });
};
