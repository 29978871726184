import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { nextClient, handleUseQuery } from "@vfit/shared/providers";
var getCampaigns = function(audienceId, audienceType, customOptions) {
    return nextClient.get("".concat(API.CAMPAIGNS), _object_spread({
        searchParams: {
            salesChannel: "selfService",
            audienceId: audienceId,
            audienceType: audienceType,
            limit: 9
        }
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
export var useCampaignsInfo = function(customOptions) {
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "campaignsData";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var ref, ref1;
            var user = getFromLocalStorageByKey("user");
            var customerSaved = getFromLocalStorageByKey("customerData");
            var findCaller = getFromLocalStorageByKey("findCaller");
            var customerData = (user === null || user === void 0 ? void 0 : user.customer) || customerSaved;
            var audienceId = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref1 = findCaller.customerRef) === null || ref1 === void 0 ? void 0 : ref1.id);
            var audienceType = "CUSTOMER_ID";
            return getCampaigns(audienceId, audienceType, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : false,
            onSuccess: function(data) {
                localStorage.setItem("campaignsData", JSON.stringify(data));
            },
            onError: function(error) {
                localStorage.setItem("campaignsData", JSON.stringify(errorMock("campaignsData", error)));
            }
        })
    };
    return handleUseQuery(options);
};
export var useCampaignsInfoMobile = function(customOptions) {
    var ref;
    var customer = getFromLocalStorageByKey("customerData");
    var audienceId = customer === null || customer === void 0 ? void 0 : (ref = customer[0]) === null || ref === void 0 ? void 0 : ref.id;
    var audienceType = "CUSTOMER_ID";
    var keysDependency = customOptions.keyDependency ? customOptions.keyDependency : "campaignsData";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return getCampaigns(audienceId, audienceType, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : false,
            onSuccess: function(data) {
                localStorage.setItem("campaignsData", JSON.stringify(data));
            },
            onError: function(error) {
                localStorage.setItem("campaignsData", JSON.stringify(errorMock("campaignsData", error)));
            }
        })
    };
    return handleUseQuery(options);
};
