import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useEffect, useState } from "react";
import { useGetDataFromStorage, useCreateAddress, useServiceabilityAddressMutation, useValidateAddress, CUSTOMER_TYPES, isAppMock, retrieveProduct, getParamsInUrl } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { LoggerInstance } from "@vfit/shared/providers";
import { CoverageErrorType } from "@vfit/shared/models";
import { checkWindow, getFromLocalStorageByKey, getHome } from "@vfit/shared/data-access";
import { usePageProvider } from "@vfit/consumer/providers";
export var useCoverageToolFlow = function(param) {
    var offerId = param.offerId, onConfirmAddress = param.onConfirmAddress, alternativeProductList = param.alternativeProductList, alternativeProductListByTech = param.alternativeProductListByTech, step = param.step;
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    var queryClient = useQueryClient();
    var ref7 = useState(false), isSuccess = ref7[0], setIsSuccess = ref7[1];
    var ref8 = useState(false), isLoading = ref8[0], setIsLoading = ref8[1];
    var ref9 = useState(false), isError = ref9[0], setIsError = ref9[1];
    var ref10 = useState(CoverageErrorType.KO), errorType = ref10[0], setErrorType = ref10[1];
    var ref11 = useState(false), mutationIsNotFull = ref11[0], setMutationIsNotFull = ref11[1];
    var ref12 = useState(""), errorMessage = ref12[0], setErrorMessage = ref12[1];
    var page = usePageProvider().page;
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var coexistenceData = getFromLocalStorageByKey("coexistenceUser");
    var silentLoginR2 = queryClient.getQueryData("silentLoginR2");
    var isLoggedR2 = (silentLoginR2 === null || silentLoginR2 === void 0 ? void 0 : silentLoginR2.flowId) && (silentLoginR2 === null || silentLoginR2 === void 0 ? void 0 : silentLoginR2.flowId) === "existingLogin" && (coexistenceData === null || coexistenceData === void 0 ? void 0 : coexistenceData.userType) === CUSTOMER_TYPES.LEGACY;
    /**
   * Hook for useValidateAddress() & useCreateAddress();
   */ var validateAddressMutation = useValidateAddress();
    var createAddressMutation = useCreateAddress();
    var addressObject = queryClient.getQueryData("coverageToolInputAddress");
    var serviceabilityAddressMutation = useServiceabilityAddressMutation(offerId, allProducts || [], alternativeProductList, alternativeProductListByTech, addressObject === null || addressObject === void 0 ? void 0 : addressObject.placeId);
    var isLocalStorageDisable = localStorage.getItem("disableReset");
    LoggerInstance.debug("this is DISABLE LOCAL STORAGE", isLocalStorageDisable);
    var validatedAddress = useGetDataFromStorage("validatedAddress", !!isLocalStorageDisable);
    var offeringServiceability = useGetDataFromStorage("offeringServiceability", !!isLocalStorageDisable);
    var createdAddress = useGetDataFromStorage("createdAddress", !!isLocalStorageDisable);
    LoggerInstance.debug("THIS IS DATA", validatedAddress, offeringServiceability, createdAddress, addressObject);
    /**
   *
   * Enabling API calls only if the addressObject retrieved from google autocomplete
   * is fully filled
   */ var flowEnabler = !!((ref2 = addressObject) === null || ref2 === void 0 ? void 0 : ref2.city) && !!((ref3 = addressObject) === null || ref3 === void 0 ? void 0 : ref3.postalCode) && !!((ref4 = addressObject) === null || ref4 === void 0 ? void 0 : ref4.stateOrProvince) && !!((ref5 = addressObject) === null || ref5 === void 0 ? void 0 : ref5.street) && !!((ref6 = addressObject) === null || ref6 === void 0 ? void 0 : ref6.streetNumber) && onConfirmAddress; // this to trigger API calls on "Conferma Indirizzo"
    // disable the coverage tool if the R2 user is logged
    var redirectShoppingCartAppMock = function() {
        var product = retrieveProduct();
        var redirectUrl = "".concat(getHome(), "/").concat((product === null || product === void 0 ? void 0 : product.redirectCoveragePath) || "", "?app=true&flowType=1&placeId=").concat(addressObject === null || addressObject === void 0 ? void 0 : addressObject.placeId, "&cmsId=").concat(product.cmsId);
        window.location.href = redirectUrl;
    };
    /**
   * Check address already validated
   * when user click on edit address this check prevent the api call if address is already validated
   * @return boolean
   */ var checkAddressAlreadyValidated = function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
        LoggerInstance.debug({
            validatedAddress: validatedAddress
        });
        var validAddress = (ref = validatedAddress) === null || ref === void 0 ? void 0 : (ref1 = ref.validAddressList) === null || ref1 === void 0 ? void 0 : ref1[0];
        if (!validAddress) return false;
        return ((ref2 = validAddress.city) === null || ref2 === void 0 ? void 0 : ref2.toLowerCase()) === (addressObject === null || addressObject === void 0 ? void 0 : (ref3 = addressObject.city) === null || ref3 === void 0 ? void 0 : ref3.toLowerCase()) && ((ref4 = validAddress.postalCode) === null || ref4 === void 0 ? void 0 : ref4.toLowerCase()) === (addressObject === null || addressObject === void 0 ? void 0 : (ref5 = addressObject.postalCode) === null || ref5 === void 0 ? void 0 : ref5.toLowerCase()) && ((ref6 = validAddress.stateOrProvince) === null || ref6 === void 0 ? void 0 : ref6.toLowerCase()) === (addressObject === null || addressObject === void 0 ? void 0 : addressObject.stateOrProvince.toLowerCase()) && ((ref7 = validAddress.street) === null || ref7 === void 0 ? void 0 : ref7.toLowerCase()) === (addressObject === null || addressObject === void 0 ? void 0 : (ref8 = addressObject.street) === null || ref8 === void 0 ? void 0 : ref8.toLowerCase());
    };
    var checkValidateAddress = function() {
        var ref, ref1, ref2, ref3, ref4;
        var showError = function() {
            var ref;
            LoggerInstance.debug("ERROR POPUP");
            var isTechFWA = checkWindow() && localStorage.getItem("isTechFWA") !== "undefined" && JSON.parse(localStorage.getItem("isTechFWA")) === true;
            setIsLoading(false);
            setIsError(true);
            setErrorType(isTechFWA ? CoverageErrorType.FWA : CoverageErrorType.KO);
            setErrorMessage(((ref = validatedAddress) === null || ref === void 0 ? void 0 : ref.errorMessage) || "");
        };
        LoggerInstance.debug("serviceabilityAddressMutation validatedAddress", validatedAddress);
        LoggerInstance.debug("serviceabilityAddressMutation.mutate PAYLOAD", (ref = validatedAddress) === null || ref === void 0 ? void 0 : (ref1 = ref.validAddressList) === null || ref1 === void 0 ? void 0 : ref1[0]);
        if (validateAddressMutation.isSuccess && !!validatedAddress && ((ref2 = validatedAddress) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.validAddressList) === null || ref3 === void 0 ? void 0 : ref3[0])) {
            var ref5;
            if (!((ref5 = validatedAddress) === null || ref5 === void 0 ? void 0 : ref5.error) && (!offeringServiceability || !!isLocalStorageDisable)) {
                if (isAppMock() && checkWindow() && getParamsInUrl("app")) {
                    redirectShoppingCartAppMock();
                } else {
                    var ref6, ref7;
                    serviceabilityAddressMutation.mutate((ref6 = validatedAddress) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.validAddressList) === null || ref7 === void 0 ? void 0 : ref7[0]);
                }
            } else {
                showError();
            }
        } else if (validateAddressMutation.isError || ((ref4 = validatedAddress) === null || ref4 === void 0 ? void 0 : ref4.error)) {
            showError();
        }
    };
    /*
    Triggering ValidateAddress Mutation if flowEnabler and addressObject
  */ useEffect(function() {
        if (!flowEnabler && !!addressObject) {
            setMutationIsNotFull(true);
        }
        if (addressObject && flowEnabler) {
            var addressAlreadyValidated = checkAddressAlreadyValidated();
            if (isLocalStorageDisable || !addressAlreadyValidated && !validatedAddress) {
                setIsLoading(true);
                validateAddressMutation.mutate(_object_spread({
                    placeObject: addressObject
                }, step ? {
                    step: step
                } : {}));
            }
        }
    }, [
        addressObject,
        flowEnabler
    ]);
    /*
    Checking ValidateAddress error and success. Triggering OfferingServiceability Mutation
  */ useEffect(function() {
        var ref;
        if (!!validatedAddress && validateAddressMutation.isSuccess) {
            checkValidateAddress();
        }
        LoggerInstance.debug("THIS IS validateAddress", validatedAddress);
        LoggerInstance.debug("THIS IS validateAddressMutation", validateAddressMutation);
        LoggerInstance.debug("THIS IS USEEFFECT ABILITATION", validatedAddress && validateAddressMutation.isSuccess && !((ref = validatedAddress) === null || ref === void 0 ? void 0 : ref.error));
    }, [
        !!validatedAddress,
        validateAddressMutation.isSuccess
    ]);
    useEffect(function() {
        var ref;
        if (validateAddressMutation.isError && ((ref = validatedAddress) === null || ref === void 0 ? void 0 : ref.error)) {
            var ref1;
            setIsError(true);
            setErrorMessage((ref1 = validatedAddress) === null || ref1 === void 0 ? void 0 : ref1.errorMessage);
        }
    }, [
        validateAddressMutation.isError,
        !!validatedAddress
    ]);
    /*
    Checking OfferingServiceability error and success. Triggering CreateAddress Mutation
  */ useEffect(function() {
        var ref, ref1, ref2, ref3;
        if ((ref = offeringServiceability) === null || ref === void 0 ? void 0 : ref.error) {
            var ref4, ref5;
            setIsLoading(false);
            setIsError(true);
            setErrorMessage((ref4 = offeringServiceability) === null || ref4 === void 0 ? void 0 : ref4.errorMessage);
            setErrorType(((ref5 = offeringServiceability) === null || ref5 === void 0 ? void 0 : ref5.errorCode) || CoverageErrorType.KO);
        }
        if (!((ref1 = offeringServiceability) === null || ref1 === void 0 ? void 0 : ref1.error) && serviceabilityAddressMutation.isSuccess && !isError && (!createdAddress || !!isLocalStorageDisable) && !createAddressMutation.isLoading && ((ref2 = validatedAddress) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.validAddressList) === null || ref3 === void 0 ? void 0 : ref3[0])) {
            var ref6, ref7;
            // TODO: check optimistic update in offering serviceability
            createAddressMutation.mutate({
                address: (ref6 = validatedAddress) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.validAddressList) === null || ref7 === void 0 ? void 0 : ref7[0]
            });
        }
    }, [
        isError,
        offeringServiceability,
        serviceabilityAddressMutation.isSuccess
    ]);
    /*
    Checking createdAddress error and success. Triggering isSuccess to true
  */ useEffect(function() {
        if (!isError && createdAddress) {
            setIsSuccess(true);
            setIsLoading(false);
        }
    }, [
        createdAddress
    ]);
    useEffect(function() {
        if (isLoggedR2) {
            LoggerInstance.debug("TEST R2 - USER NOT ABLE TO MAKE COVERAGE");
            setErrorType(CoverageErrorType.R2_LOGGED);
            setIsLoading(false);
            setIsError(true);
        }
    }, [
        isLoggedR2
    ]);
    useEffect(function() {
        if (isSuccess || isError) {
            setIsLoading(false);
            setIsSuccess(false);
            setIsError(false);
        }
    }, [
        isSuccess,
        isError
    ]);
    return [
        mutationIsNotFull,
        isSuccess,
        isLoading,
        isError,
        errorMessage,
        errorType
    ];
};
