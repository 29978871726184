import { useEffect, useState } from "react";
import { CUSTOMER_TYPES, errorMock, useCoexistenceUser, useGetCustomer, useLoggedUser, useSilentLogin } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
export var useLoggedCustomerFlow = function() {
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(""), errorMessage = ref3[0], setErrorMessage = ref3[1];
    var ref4 = useState(undefined), errorSilentLogin = ref4[0], setErrorSilentLogin = ref4[1];
    var ref5 = useState(""), customerId = ref5[0], setCustomerId = ref5[1];
    /**
   * Instantiation of .mutate() objects
   */ var ref6 = useSilentLogin(), silentLogin = ref6.data, silentLoginMutate = ref6.mutate, isSuccessSilentLogin = ref6.isSuccess, isLoadingSilentLogin = ref6.isLoading, isErrorSilentLogin = ref6.isError, silentLoginError = ref6.error;
    var silentLoginIsSuccess = isSuccessSilentLogin && silentLogin;
    var ref7 = useSilentLogin(), silentLoginR2 = ref7.data, silentLoginR2Mutate = ref7.mutate, isSuccessSilentLoginR2 = ref7.isSuccess, isLoadingSilentLoginR2 = ref7.isLoading;
    var silentLoginR2IsSuccess = isSuccessSilentLoginR2 && silentLoginR2;
    /**
   * Enabling API calls only if the addressObject retrieved from google autocomplete
   * is fully filled
   */ var ref8 = useCoexistenceUser(), coexistenceData = ref8.data, coexistenceIsSuccess = ref8.isSuccess, coexistenceIsError = ref8.isError;
    var coexistenceSuccess = coexistenceIsSuccess && coexistenceData;
    var ref9 = useLoggedUser({
        enabled: false
    }), userData = ref9.data, isErrorUser = ref9.isError, isSuccessUser = ref9.isSuccess, refetchUser = ref9.refetch;
    var ref10 = useGetCustomer(customerId, {
        enabled: !!customerId
    }), customerData = ref10.data, customerIsSuccess = ref10.isSuccess, customerIsError = ref10.isError;
    /**
   * useEffect to trigger silentLogin mutation the first time
   */ useEffect(function() {
        if (!isLoading && !silentLogin && !isLoadingSilentLogin && !isSuccessSilentLogin) {
            setIsLoading(true);
            silentLoginMutate(true);
        }
    }, []);
    /**
   * useEffect to trigger coexistence
   * or set on localstorage R2_logged
   */ useEffect(function() {
        if (isSuccessSilentLogin && silentLogin) {
            LoggerInstance.debug("TEST LOGGED - silentLogin : ", silentLogin);
            if ((silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) === "newAnonymous") {
                LoggerInstance.debug("TEST LOGGED - silentLogin : newAnonymous");
                setIsSuccess(true);
            }
            if ((silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) === "existingAnonymous") {
                LoggerInstance.debug("TEST LOGGED - silentLogin : existingAnonymous");
                setIsSuccess(true);
            }
            if ((silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) === "existingEnriched") {
                LoggerInstance.debug("TEST LOGGED - silentLogin : existingEnriched");
                setIsSuccess(true);
            }
            if ((silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) === "existingLogin" || (silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) === "newLogin") {
                LoggerInstance.debug("TEST LOGGED - silentLogin : existingLogin");
                refetchUser();
            }
        }
        if (coexistenceIsSuccess && !!coexistenceData && (coexistenceData === null || coexistenceData === void 0 ? void 0 : coexistenceData.userType) === CUSTOMER_TYPES.LEGACY) {
            if (!silentLoginR2 && !isLoadingSilentLoginR2 && !isSuccessSilentLoginR2) {
                silentLoginR2Mutate(false);
            }
        }
    }, [
        silentLoginIsSuccess,
        coexistenceSuccess
    ]);
    useEffect(function() {
        if (isSuccessSilentLoginR2 && silentLoginR2) {
            LoggerInstance.debug("TEST LOGGED - silentLogin R2: ", silentLoginR2);
            if ((silentLoginR2 === null || silentLoginR2 === void 0 ? void 0 : silentLoginR2.flowId) === "newAnonymous") {
                LoggerInstance.debug("TEST LOGGED - silentLogin R2 : newAnonymous");
                setIsSuccess(true);
            }
            if ((silentLoginR2 === null || silentLoginR2 === void 0 ? void 0 : silentLoginR2.flowId) === "existingAnonymous") {
                LoggerInstance.debug("TEST LOGGED - silentLogin R2: existingAnonymous");
                setIsSuccess(true);
            }
            if ((silentLoginR2 === null || silentLoginR2 === void 0 ? void 0 : silentLoginR2.flowId) === "existingLogin") {
                LoggerInstance.debug("TEST LOGGED - silentLogin R2: existingLogin");
            }
            if ((silentLoginR2 === null || silentLoginR2 === void 0 ? void 0 : silentLoginR2.flowId) === "newLogin") {
                // FIRING SILENT LOGIN R2 TWICE
                LoggerInstance.debug("TEST LOGGED - silentLogin R2: newLogin");
                silentLoginR2Mutate(false);
            }
        }
    }, [
        silentLoginR2IsSuccess
    ]);
    useEffect(function() {
        var ref, ref1;
        if (isSuccessUser && (userData === null || userData === void 0 ? void 0 : (ref = userData.customer) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.id)) {
            var ref2, ref3;
            setCustomerId(userData === null || userData === void 0 ? void 0 : (ref2 = userData.customer) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.id);
        }
    }, [
        isSuccessUser
    ]);
    /**
   * useEffect to manage the loader on retrieved costumer data
   */ useEffect(function() {
        if (customerIsSuccess) {
            setIsLoading(false);
            setIsSuccess(true);
        }
    }, [
        customerIsSuccess
    ]);
    useEffect(function() {
        if (isErrorSilentLogin) {
            setIsLoading(false);
            setIsError(true);
            setErrorSilentLogin(errorMock("silentLogin", silentLoginError));
        }
        if (isErrorUser) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage("ERROR - user");
        }
        if (customerIsError) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage("ERROR - customerData");
        }
        if (coexistenceIsError) {
            LoggerInstance.error("coexistance ERROR to be refactored");
        }
    }, [
        customerIsError,
        isErrorUser,
        isErrorSilentLogin,
        !!customerData,
        !!userData,
        !!silentLogin,
        !!coexistenceIsError, 
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        errorMessage: errorMessage,
        errorSilentLogin: errorSilentLogin
    };
};
