import { useEffect, useState } from "react";
import { checkWindow } from "@vfit/shared/data-access";
import { IN_ACTIVATION } from "@vfit/consumer/data-access";
export function useRedirectCoverageToolFlow(cmsId) {
    var ref = useState(false), isInActivation = ref[0], setIsInActivation = ref[1];
    var checkIsInActivation = function() {
        var ref;
        if (!checkWindow()) {
            return;
        }
        var queryString = new URLSearchParams(window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search);
        var isActivation = queryString === null || queryString === void 0 ? void 0 : queryString.get(IN_ACTIVATION);
        if (isActivation && isActivation === cmsId.toString()) {
            setIsInActivation(true);
            setTimeout(function() {
                window.history.pushState({}, document.title, window.location.pathname);
            }, 1500);
        }
    };
    useEffect(function() {
        checkIsInActivation();
    }, [
        cmsId
    ]);
    return [
        isInActivation
    ];
}
