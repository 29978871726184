import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { errorMock } from "@vfit/consumer/data-access";
/**
 * Methods to invoke the POST /release resource service
 * @param payload
 * @param customOptions
 */ var releaseResourceService = function(payload, customOptions) {
    return nextClient.post(API.RELEASE_RESOURCE, payload, _object_spread_props(_object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }), {
        searchParams: {
            salesChannel: "selfService"
        }
    }));
};
/**
 * Mutation reserve phone number
 */ export var useReleaseResource = function() {
    var queryClient = useQueryClient();
    return useMutation("releaseResource", function(param) {
        var valueList = param.valueList;
        var payload = {
            value: valueList,
            resourceType: "msisdn"
        };
        LoggerInstance.debug("reservePhoneNumber payload ", payload);
        return releaseResourceService(payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("releaseResource", JSON.stringify(data));
            queryClient.setQueryData("releaseResource", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - releaseResource: ", error);
            queryClient.setQueryData("releaseResource", errorMock("releaseResource", error));
            localStorage.setItem("releaseResource", JSON.stringify(errorMock("releaseResource", error)));
        }
    });
};
