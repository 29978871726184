import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
/**
 * This method is used retrieve the findCallerService from ApicWeb.
 * With this respose we can gat the user type and handle it accordingly.
 * Method: GET
 * SearchParams:
 * salesChannel: selfService,
 * fiscalCode,
 * createCookie: 'true',
 * filters: 'partialDataFlag==false;noFiscalCodeFlag==Italian',
 * @param payload
 * @param customOptions
 * @returns
 */ var findCallerService = function(payload, customOptions) {
    return nextClient.get(API.FIND_CALLER, _object_spread({
        searchParams: _object_spread({}, payload)
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the findCallerService
 * @param payload
 * @param customOptions
 */ export var useGetFindCaller = function(payload, customOptions) {
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "findCaller";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return findCallerService(_object_spread_props(_object_spread({}, payload), {
                createCookie: "true",
                filters: "partialDataFlag==false;noFiscalCodeFlag==Italian",
                activeSubsCheckRequired: (payload === null || payload === void 0 ? void 0 : payload.activeSubsCheckRequired) || "true"
            }), customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            onSuccess: function(data) {
                if (data) {
                    var ref, ref1;
                    localStorage.setItem("findCaller", JSON.stringify(data));
                    var recognizedUser = !!data && (data === null || data === void 0 ? void 0 : data.resultInfo) === "existingNextCustomer" && (data === null || data === void 0 ? void 0 : data.customerRef) && (data === null || data === void 0 ? void 0 : (ref = data.customerRef) === null || ref === void 0 ? void 0 : ref.id);
                    if (recognizedUser) localStorage.setItem("recognizedUser", JSON.stringify(recognizedUser));
                    customOptions === null || customOptions === void 0 ? void 0 : (ref1 = customOptions.onSuccess) === null || ref1 === void 0 ? void 0 : ref1.call(customOptions, data);
                }
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - findCaller: ", error);
                localStorage.setItem("findCaller", JSON.stringify(errorMock("findCaller", error)));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
