export var organizeEditAddressFixed = function(cmsConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    return {
        title: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.title) || "Cambia Indirizzo",
        billingSubtitle: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.billingSubtitle) || "Inserisci il nuovo indirizzo di fatturazione",
        shippingSubtitle: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.shippingSubtitle) || "<p>Dove vorresti ricevere la tua sim</p>\r\n",
        lable: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.lable) || "I tuoi dati personali",
        paragraph_manual: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.paragraph_manual) || "<p>Siamo spiacenti ma l'indirizzo inserito non \xe8 valido. Per favore, inserire manualmente l'indirizzo corretto.</p>\r\n",
        labels: {
            addressInput: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref = cmsConfig.labels) === null || ref === void 0 ? void 0 : ref.addressInput) || "Indirizzo",
            city: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref1 = cmsConfig.labels) === null || ref1 === void 0 ? void 0 : ref1.city) || "Comune",
            postalCode: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref2 = cmsConfig.labels) === null || ref2 === void 0 ? void 0 : ref2.postalCode) || "CAP",
            stateOrProvince: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref3 = cmsConfig.labels) === null || ref3 === void 0 ? void 0 : ref3.stateOrProvince) || "Provincia",
            streetNumber: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref4 = cmsConfig.labels) === null || ref4 === void 0 ? void 0 : ref4.streetNumber) || "Numero Civico",
            street: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref5 = cmsConfig.labels) === null || ref5 === void 0 ? void 0 : ref5.street) || "Indirizzo"
        },
        placeHolders: {
            addressInput: " ",
            city: " ",
            postalCode: " ",
            stateOrProvince: " ",
            streetNumber: " ",
            street: " "
        },
        submitButton: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.submitButton) || "SALVA INDIRIZZO",
        submitButton_manual: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.submitButton_manual) || "AGGIORNA MANUALMENTE",
        genericError: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.genericError) || "Tutti i campi sono obbligatori.",
        apiError: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.apiError) || "Non \xe8 stato possibile trovare l'indirizzo. La preghiamo di ricontrollare i dati inseriti."
    };
};
export var organizeEditAddressMobile = function(cmsConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    return {
        title: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.title) || "Cambia Indirizzo",
        billingSubtitle: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.billingSubtitle) || "Inserisci il nuovo indirizzo di fatturazione",
        shippingSubtitle: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.shippingSubtitle) || "Cambia indirizzo di spedizione",
        lable: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.lable) || "I tuoi dati personali",
        paragraph_manual: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.paragraph_manual) || "<p>Siamo spiacenti ma l'indirizzo inserito non \xe8 valido. Per favore, inserire manualmente l'indirizzo corretto.</p>\r\n",
        labels: {
            addressInput: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref = cmsConfig.labels) === null || ref === void 0 ? void 0 : ref.addressInput) || "Indirizzo",
            city: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref1 = cmsConfig.labels) === null || ref1 === void 0 ? void 0 : ref1.city) || "Comune",
            postalCode: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref2 = cmsConfig.labels) === null || ref2 === void 0 ? void 0 : ref2.postalCode) || "CAP",
            stateOrProvince: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref3 = cmsConfig.labels) === null || ref3 === void 0 ? void 0 : ref3.stateOrProvince) || "Provincia",
            streetNumber: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref4 = cmsConfig.labels) === null || ref4 === void 0 ? void 0 : ref4.streetNumber) || "Numero Civico",
            street: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref5 = cmsConfig.labels) === null || ref5 === void 0 ? void 0 : ref5.street) || "Indirizzo"
        },
        placeHolders: {
            addressInput: " ",
            city: " ",
            postalCode: " ",
            stateOrProvince: " ",
            streetNumber: " ",
            street: " "
        },
        submitButton: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.submitButton) || "SALVA INDIRIZZO",
        submitButton_manual: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.submitButton_manual) || "AGGIORNA MANUALMENTE",
        genericError: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.genericError) || "Tutti i campi sono obbligatori.",
        apiError: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.apiError) || "Non \xe8 stato possibile trovare l'indirizzo. La preghiamo di ricontrollare i dati inseriti."
    };
};
export var organizeMap = function(mapData) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23;
    var mapConfig = {
        titlePO: (mapData === null || mapData === void 0 ? void 0 : (ref = mapData.storemap) === null || ref === void 0 ? void 0 : (ref1 = ref.postalOffice) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        titlePP: (mapData === null || mapData === void 0 ? void 0 : (ref2 = mapData.storemap) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.pickupPoint) === null || ref3 === void 0 ? void 0 : ref3.title) || "Pickup points",
        titleP: (mapData === null || mapData === void 0 ? void 0 : (ref4 = mapData.storemap) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.vfStore) === null || ref5 === void 0 ? void 0 : ref5.title) || "",
        subTitlePO: (mapData === null || mapData === void 0 ? void 0 : (ref6 = mapData.storemap) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.postalOffice) === null || ref7 === void 0 ? void 0 : ref7.subtitle) || "",
        subTitlePP: (mapData === null || mapData === void 0 ? void 0 : (ref8 = mapData.storemap) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.pickupPoint) === null || ref9 === void 0 ? void 0 : ref9.subtitle) || "Modifica il tuo indirizzo per trovare il pickup point pi\xf9 vicino a te.",
        subTitleP: (mapData === null || mapData === void 0 ? void 0 : (ref10 = mapData.storemap) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.vfStore) === null || ref11 === void 0 ? void 0 : ref11.subtitle) || "",
        ctaModifyLable: (mapData === null || mapData === void 0 ? void 0 : (ref12 = mapData.storemap) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.common) === null || ref13 === void 0 ? void 0 : ref13.ctaModifyLable) || "",
        switchLabelSx: (mapData === null || mapData === void 0 ? void 0 : (ref14 = mapData.storemap) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.common) === null || ref15 === void 0 ? void 0 : ref15.switchLabelSX) || "",
        switchLabelDx: (mapData === null || mapData === void 0 ? void 0 : (ref16 = mapData.storemap) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.common) === null || ref17 === void 0 ? void 0 : ref17.switchLabelDX) || "",
        ctaSaveLable: (mapData === null || mapData === void 0 ? void 0 : (ref18 = mapData.storemap) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.common) === null || ref19 === void 0 ? void 0 : ref19.ctaSaveLable) || ""
    };
    var mapEditConfig = {
        title: (mapData === null || mapData === void 0 ? void 0 : (ref20 = mapData.storemap) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.common) === null || ref21 === void 0 ? void 0 : ref21.ctaSaveLable) || "",
        subtitle: (mapData === null || mapData === void 0 ? void 0 : (ref22 = mapData.storemap) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.common) === null || ref23 === void 0 ? void 0 : ref23.subtitle) || ""
    };
    return {
        mapEditConfig: mapEditConfig,
        mapConfig: mapConfig
    };
};
export var organizeEditAddressOptionFixed = function(data, isFwa) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    return {
        title: (data === null || data === void 0 ? void 0 : (ref = data.deliveryinformation) === null || ref === void 0 ? void 0 : (ref1 = ref.addressChange) === null || ref1 === void 0 ? void 0 : ref1.title) || "Cambia indirizzo",
        subtitle: (data === null || data === void 0 ? void 0 : (ref2 = data.deliveryinformation) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.addressChange) === null || ref3 === void 0 ? void 0 : ref3.subtitle) || "Indirizzo di spedizione",
        description: isFwa ? (data === null || data === void 0 ? void 0 : (ref4 = data.deliveryinformation) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.addressChange) === null || ref5 === void 0 ? void 0 : ref5.simShippingLabel) || "Dove vuoi ricevere la SIM?" : (data === null || data === void 0 ? void 0 : (ref6 = data.deliveryinformation) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.addressChange) === null || ref7 === void 0 ? void 0 : ref7.description) || "Dove vuoi ricevere il modem?",
        ctaLable: (data === null || data === void 0 ? void 0 : (ref8 = data.deliveryinformation) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.addressChange) === null || ref9 === void 0 ? void 0 : ref9.buttonLabel) || "Salva indirizzo"
    };
};
export var organizeEditAddressOptionMobile = function(data) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    return {
        title: (data === null || data === void 0 ? void 0 : (ref = data.deliveryinformationm) === null || ref === void 0 ? void 0 : (ref1 = ref.addressChange) === null || ref1 === void 0 ? void 0 : ref1.title) || "Cambia indirizzo",
        subtitle: (data === null || data === void 0 ? void 0 : (ref2 = data.deliveryinformationm) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.addressChange) === null || ref3 === void 0 ? void 0 : ref3.subtitle) || "Indirizzo di spedizione",
        description: (data === null || data === void 0 ? void 0 : (ref4 = data.deliveryinformationm) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.addressChange) === null || ref5 === void 0 ? void 0 : ref5.description) || "Dove vuoi ricevere la tua SIM?",
        ctaLable: (data === null || data === void 0 ? void 0 : (ref6 = data.deliveryinformationm) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.addressChange) === null || ref7 === void 0 ? void 0 : ref7.buttonLabel) || "Salva indirizzo"
    };
};
export var organizeEditAddressManual = function(cmsData) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13;
    return {
        addressInputCard: {
            title: (cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.editaddressconfig) === null || ref === void 0 ? void 0 : ref.title) || "Indirizzo",
            description: "Inserisci manualmente il tuo indirizzo",
            labels: {
                title: (cmsData === null || cmsData === void 0 ? void 0 : (ref1 = cmsData.editaddressconfig) === null || ref1 === void 0 ? void 0 : ref1.title) || "Cambia indirizzo",
                street: (cmsData === null || cmsData === void 0 ? void 0 : (ref2 = cmsData.editaddressconfig) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.labels) === null || ref3 === void 0 ? void 0 : ref3.street) || "Indirizzo",
                streetNumber: (cmsData === null || cmsData === void 0 ? void 0 : (ref4 = cmsData.editaddressconfig) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.labels) === null || ref5 === void 0 ? void 0 : ref5.streetNumber) || "Numero civico",
                displayStateOrProvince: (cmsData === null || cmsData === void 0 ? void 0 : (ref6 = cmsData.editaddressconfig) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.labels) === null || ref7 === void 0 ? void 0 : ref7.stateOrProvince) || "Provincia",
                city: (cmsData === null || cmsData === void 0 ? void 0 : (ref8 = cmsData.editaddressconfig) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.labels) === null || ref9 === void 0 ? void 0 : ref9.city) || "Comune",
                postalCode: (cmsData === null || cmsData === void 0 ? void 0 : (ref10 = cmsData.editaddressconfig) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.labels) === null || ref11 === void 0 ? void 0 : ref11.postalCode) || "CAP"
            },
            buttonLabel: (cmsData === null || cmsData === void 0 ? void 0 : (ref12 = cmsData.editaddressconfig) === null || ref12 === void 0 ? void 0 : ref12.submitButton_manual) || "AGGIORNA MANUALMENTE"
        },
        addressErrors: {
            city: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            },
            postalCode: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            },
            stateOrProvince: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            },
            street: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            },
            streetNumber: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            }
        },
        genericErrorAddress: (cmsData === null || cmsData === void 0 ? void 0 : (ref13 = cmsData.editaddressconfig) === null || ref13 === void 0 ? void 0 : ref13.apiError) || "Non \xe8 stato possibile trovare l'indirizzo. La preghiamo di ricontrollare i dati inseriti."
    };
};
