import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { errorMock } from "@vfit/consumer/data-access";
/**
 * Methods to invoke the POST /reserve service
 * @param payload
 * @param customOptions
 */ var reservePhoneNumberService = function(payload, customOptions) {
    return nextClient.post(API.RESERVE, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Mutation reserve phone number
 */ export var useReservePhoneNumber = function() {
    var queryClient = useQueryClient();
    return useMutation("reservePhoneNumber", function() {
        var payload = {
            type: "msisdn",
            phoneNumberCategory: "regular",
            resourceReqCount: 6
        };
        LoggerInstance.debug("reservePhoneNumber payload ", payload);
        return reservePhoneNumberService(payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("reservePhoneNumber", JSON.stringify(data));
            queryClient.setQueryData("reservePhoneNumber", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - reservePhoneNumber: ", error);
            queryClient.setQueryData("reservePhoneNumber", errorMock("reservePhoneNumber", error));
            localStorage.setItem("reservePhoneNumber", JSON.stringify(errorMock("reservePhoneNumber", error)));
        }
    });
};
