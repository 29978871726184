import { useTracking } from "@vfit/shared/providers";
import { setTrackLink } from "@vfit/shared/data-access";
import { getUserInfo, getTagging } from "../../../../checkout.utils";
export var useVoucherInsertionCardTagging = function() {
    var tagging = getTagging("voucher input", "fixed");
    var trackView = useTracking({
        event: [
            "checkout_step8.2"
        ],
        event_label: "insert voucher",
        opts: tagging.opts,
        cartProduct: tagging.trackingProduct,
        pageProduct: tagging === null || tagging === void 0 ? void 0 : tagging.pageProductsInfo,
        visitorTrackingOpts: getUserInfo()
    }).trackView;
    setTrackLink(trackView, "voucher input");
    return null;
};
