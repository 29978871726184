import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import ky from "ky";
import { LoggerInstance, handleEnv } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { errorMock } from "@vfit/consumer/data-access";
/**
 * Shopping cart DELETE service
 * The client is not used because I need to pass a custom header.
 * The delete is not always done with the header of the new cart.
 * We didn't have the time to customize nextClient
 * @param shoppingCartId
 * @param customOptions
 * @returns
 */ export var deleteShoppingCartService = function(shoppingCartId, customOptions) {
    var prefixUrl = handleEnv("NX_NEXT_ENVIRO") || "";
    return ky.delete("".concat(prefixUrl, "/").concat(API.SHOPPING_CART, "/").concat(shoppingCartId), _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    })).json();
};
/**
 * This method handle the delete cart service.
 * @returns
 */ export var useDeleteShoppingCartMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("deleteShoppingCartService", function(payload) {
        return deleteShoppingCartService(payload.shoppingCartId, payload.customOptions);
    }, {
        onSuccess: function(data) {
            LoggerInstance.debug(data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - deleteShoppingCart: ", error);
            queryClient.setQueryData("deleteShoppingCart", errorMock("deleteShoppingCart", error));
            localStorage.setItem("deleteShoppingCart", JSON.stringify(errorMock("deleteShoppingCart", error)));
        }
    });
};
