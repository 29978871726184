import { useMutation, useQueryClient } from "react-query";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
/**
 * This method calls the paymentService to update payment method for a specific cartId
 * Method: PUT
 * searchParams: {
 * salesChannel: 'selfService'}
 * @param cartId
 * @returns
 */ var payMentMethodService = function(cartId, payLoad) {
    return nextClient.put("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.PAYMENT_METHOD), payLoad, {
        searchParams: {
            salesChannel: "selfService"
        }
    });
};
/**
 * Custom mutation hook for run billDeliveryDetailsService using the createdAddress/billingCreatedAddress, the customerData and the billingAccount.
 * Call this after the customer, shoppingCart and delivery have just been created.
 * @returns
 */ export var usePaymentMethod = function() {
    var queryClient = useQueryClient();
    var keyString = "paymentMethod";
    return useMutation([
        "paymentMethodPut"
    ], function(paramsPaymentMethodPutMutation) {
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        LoggerInstance.log("payment method put shoppingCart : ", shoppingCart);
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var payLoad = {
            orderPayment: {
                orderItemPayments: [
                    {
                        orderItemOnBillPayment: {
                            selectedPaymentMethod: paramsPaymentMethodPutMutation.selectedPaymentMethod,
                            billingOfferIdForChangePaymentMethod: paramsPaymentMethodPutMutation.billingOfferIdForChangePaymentMethod
                        },
                        orderActionId: paramsPaymentMethodPutMutation.orderActionId
                    }, 
                ]
            },
            skipValidation: false,
            performSave: true
        };
        return payMentMethodService(cartId, payLoad);
    }, {
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR payment method put - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
