export var organizeCheckboxCardsPortability = function(portabilityCms, portability) {
    var ref, ref1, ref2, ref3;
    var lineInfo = portabilityCms === null || portabilityCms === void 0 ? void 0 : (ref = portabilityCms.portability) === null || ref === void 0 ? void 0 : ref.lineInfo;
    return {
        title: (lineInfo === null || lineInfo === void 0 ? void 0 : lineInfo.title) || "",
        description: (lineInfo === null || lineInfo === void 0 ? void 0 : (ref1 = lineInfo.description) === null || ref1 === void 0 ? void 0 : ref1.replace("PHONENUMBER", "".concat((portability === null || portability === void 0 ? void 0 : portability.portabilityNumber) || ""))) || "",
        items: [
            {
                title: (lineInfo === null || lineInfo === void 0 ? void 0 : (ref2 = lineInfo.choice) === null || ref2 === void 0 ? void 0 : ref2.option1) || "",
                value: "TelInt"
            },
            {
                title: (lineInfo === null || lineInfo === void 0 ? void 0 : (ref3 = lineInfo.choice) === null || ref3 === void 0 ? void 0 : ref3.option2) || "",
                value: "TelOnly"
            }
        ]
    };
};
