import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  font-weight: 700;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        "px;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n\n  .text-input {\n    width: 100%;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-7c430e18-0"
})(_templateObject());
export var Paragraph = styled.p.withConfig({
    componentId: "sc-7c430e18-1"
})(_templateObject1(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-7c430e18-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30));
export var InputWrapper = styled.div.withConfig({
    componentId: "sc-7c430e18-3"
})(_templateObject3(), function(props) {
    return props.isMultipleOperators ? 16 : 32;
});
