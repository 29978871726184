import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { errorMock, ErrorCodes } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * This method is used to retrieve the correct path to update the customerData.
 * @param index
 * @param type
 * @returns
 */ var createPath = function(index, type) {
    switch(type){
        case "email":
            return "/owningIndividual/contactMedium/".concat(index, "/emailAddress");
        case "number":
            return "/owningIndividual/contactMedium/".concat(index, "/phoneNumber");
        case "identificationNumber":
            return "/owningIndividual/identification/".concat(index, "/identificationNumber");
        case "identificationType":
            return "/owningIndividual/identification/".concat(index, "/identificationType");
        case "expirationDate":
            return "/owningIndividual/identification/".concat(index, "/expirationDate");
        case "nationality":
            return "/owningIndividual/identification/".concat(index, "/nationality");
        case "formattedAddress1":
            return "/owningIndividual/contactMedium/".concat(index, "/formattedAddress1");
        case "formattedAddress3":
            return "/owningIndividual/contactMedium/".concat(index, "/formattedAddress3");
        case "streetType":
            return "/owningIndividual/contactMedium/".concat(index, "/streetType");
        case "streetName":
            return "/owningIndividual/contactMedium/".concat(index, "/streetName");
        case "city":
            return "/owningIndividual/contactMedium/".concat(index, "/city");
        case "postalCode":
            return "/owningIndividual/contactMedium/".concat(index, "/postalCode");
        case "streetNumber":
            return "/owningIndividual/contactMedium/".concat(index, "/streetNumber");
        case "externalId":
            return "/owningIndividual/contactMedium/".concat(index, "/externalId");
        case "stateOrProvince":
            return "/owningIndividual/contactMedium/".concat(index, "/stateOrProvince");
        case "street":
            return "/owningIndividual/contactMedium/".concat(index, "/street");
        case "istatCode":
            return "/owningIndividual/contactMedium/".concat(index, "/istatCode");
        case "homeZone":
            return "/owningIndividual/contactMedium/".concat(index, "/homeZone");
        case "longitude":
            return "/owningIndividual/contactMedium/".concat(index, "/longitude");
        case "latitude":
            return "/owningIndividual/contactMedium/".concat(index, "/latitude");
        default:
            return "";
    }
};
/**
 * Organize the payload items
 * @param updatedValue
 * @param modify
 * @param operation
 * @param index
 * @returns
 */ var payloadOrganizer = function(updatedValue, modify, operation, index) {
    var payload;
    payload = {
        op: operation,
        path: createPath(index, modify)
    };
    switch(modify){
        case "email":
            {
                if (operation === "replace") {
                    payload = _object_spread_props(_object_spread({}, payload), {
                        value: updatedValue
                    });
                }
                break;
            }
        case "number":
            {
                if (operation === "replace") {
                    payload = _object_spread_props(_object_spread({}, payload), {
                        value: updatedValue
                    });
                }
                break;
            }
        case "identificationType":
            {
                if (operation === "replace") {
                    payload = _object_spread_props(_object_spread({}, payload), {
                        value: updatedValue
                    });
                }
                break;
            }
        case "identificationNumber":
            {
                if (operation === "replace") {
                    payload = _object_spread_props(_object_spread({}, payload), {
                        value: updatedValue
                    });
                }
                break;
            }
        case "expirationDate":
            {
                if (operation === "replace") {
                    payload = _object_spread_props(_object_spread({}, payload), {
                        value: updatedValue
                    });
                }
                break;
            }
        case "nationality":
            {
                if (operation === "replace") {
                    payload = _object_spread_props(_object_spread({}, payload), {
                        value: updatedValue
                    });
                }
                break;
            }
        case "formattedAddress1":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "formattedAddress3":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "streetType":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "streetName":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "city":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "postalCode":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "streetNumber":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "externalId":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "stateOrProvince":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "street":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "istatCode":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "homeZone":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "longitude":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        case "latitude":
            payload = _object_spread({}, payload, operation === "replace" && {
                value: updatedValue
            });
            break;
        default:
            break;
    }
    return payload;
};
/**
 * This method calls the service which patch the customer contacts
 * email and mobile phone
 * @param customerId
 * @param payload
 * @param customOptions
 * @returns
 */ var patchCustomerService = function(customerId, payload, customOptions) {
    return nextClient.patch("".concat(API.CUSTOMER, "/").concat(customerId), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method edit the customer data.
 * @returns
 */ export var usePatchCustomer = function() {
    var queryClient = useQueryClient();
    return useMutation([
        "patchCustomer"
    ], function(patchItems) {
        var ref, ref1;
        var findCaller = getFromLocalStorageByKey("findCaller");
        var customerData = getFromLocalStorageByKey("customerData");
        var customerId = findCaller && ((findCaller === null || findCaller === void 0 ? void 0 : findCaller.resultInfo) === "existingNextCustomer" || (findCaller === null || findCaller === void 0 ? void 0 : findCaller.customerHasActiveSubs) === false) && (findCaller === null || findCaller === void 0 ? void 0 : findCaller.customerRef) && (findCaller === null || findCaller === void 0 ? void 0 : (ref = findCaller.customerRef) === null || ref === void 0 ? void 0 : ref.id);
        if (!customerId && customerData) customerId = customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : ref1.id;
        var payload = [];
        patchItems.forEach(function(item) {
            payload.push(payloadOrganizer(item.updatedValue, item.type, item.operation, item.index));
        });
        LoggerInstance.debug("patchCustomerService payload :", payload);
        return patchCustomerService(customerId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("patchCustomer", JSON.stringify(data));
            queryClient.setQueryData("patchCustomer", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - patchCustomerService:", error);
            queryClient.setQueryData("patchCustomer", error);
            localStorage.setItem("patchCustomer", JSON.stringify(errorMock("patchCustomer", error, ErrorCodes.PATCH_CUSTOMER)));
        }
    });
};
