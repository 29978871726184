import { getTechnology, TechnologyCategories, TechTypes } from "@vfit/shared/data-access";
var recoverServiceabilityField = function(data, field) {
    var fieldRetrived = data === null || data === void 0 ? void 0 : data.filter(function(item) {
        return item.displayName === field;
    });
    if (!fieldRetrived || fieldRetrived.length === 0) return "";
    if (field === "DisplayTechnologyName") {
        var ref;
        return fieldRetrived === null || fieldRetrived === void 0 ? void 0 : (ref = fieldRetrived[0]) === null || ref === void 0 ? void 0 : ref.displayValue;
    }
    return fieldRetrived[0].displayValue;
};
var organizeTechnology = function(offeringServiceability) {
    var originalTechType = recoverServiceabilityField(offeringServiceability === null || offeringServiceability === void 0 ? void 0 : offeringServiceability.serviceabilityCharacteristic, "Technology");
    var mappedCategory = getTechnology(originalTechType);
    var displayedTecnology = recoverServiceabilityField(offeringServiceability === null || offeringServiceability === void 0 ? void 0 : offeringServiceability.serviceabilityCharacteristic, "DisplayTechnologyName") || "";
    var cmsConfigUrl = "".concat(mappedCategory.toLowerCase(), ".json");
    return {
        originalTechType: originalTechType,
        mappedCategory: mappedCategory,
        displayedTecnology: mappedCategory,
        shoppingCartTech: displayedTecnology,
        cmsConfigUrl: cmsConfigUrl
    };
};
export { TechTypes, TechnologyCategories, getTechnology, organizeTechnology, recoverServiceabilityField };
