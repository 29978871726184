import { BYPASS_CALL_API_SIA, productSpecificError, retrieveProduct } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey, getHome, openPopup } from "@vfit/shared/data-access";
import { errorManager, ErrorService } from "@vfit/shared/providers";
var checkCartValidation = function() {
    var ref;
    var product = retrieveProduct();
    var home = getHome();
    var shoppingCartUrl = product === null || product === void 0 ? void 0 : product.shoppingCartUrl;
    var ref1 = productSpecificError(product), buttonGenericError = ref1.buttonGenericError, urlGenericErrorCallMeNow = ref1.urlGenericErrorCallMeNow;
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    if ((shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartValidation) === null || ref === void 0 ? void 0 : ref.status) === "blocked") {
        errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
            title: "Attenzione",
            event_label: "cart_blocked",
            errorCode: "401",
            message: "Non \xe8 possibile concludere l'acquisto per questa tipologia di utente",
            onClose: function() {
                localStorage.removeItem(BYPASS_CALL_API_SIA);
                window.open(shoppingCartUrl || home, "_self");
            },
            actionEvent: function() {
                return openPopup(urlGenericErrorCallMeNow);
            },
            actionText: buttonGenericError,
            disableTrack: false
        });
    }
};
export { checkCartValidation };
