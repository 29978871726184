import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { nextClient, handleUseQuery, LoggerInstance } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { errorMock } from "@vfit/consumer/data-access";
/**
 * This method is used run the billing account service from the customerId.
 * Method: GET
 * SearchParams: salesChannel: selfService, checkExistingInstallment: true
 * @param customerId
 * @param customOptions
 * @returns
 */ var billingAccountService = function(customerId, customOptions, isNewMopEnabled) {
    return nextClient.get("".concat(API.CUSTOMER, "/").concat(customerId, "/").concat(API.BILLING_ACCOUNT), _object_spread({
        searchParams: _object_spread({
            salesChannel: "selfService",
            checkExistingInstallment: true
        }, isNewMopEnabled && {
            checkOnGoingTNPDevice: true
        })
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the billingAccountService
 * @param customOptions
 * @returns
 */ export var useBillingAccount = function(customOptions, cmsGlobalConfig) {
    var queryEnabler = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : false;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "billingAccount";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
            var customerData = getFromLocalStorageByKey("customerData");
            var findCaller = getFromLocalStorageByKey("findCaller");
            var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref1 = findCaller.customerRef) === null || ref1 === void 0 ? void 0 : ref1.id);
            var customerDataCF = (customerData === null || customerData === void 0 ? void 0 : (ref2 = customerData[0]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.owningIndividual) === null || ref3 === void 0 ? void 0 : ref3.fiscalCode) || (findCaller === null || findCaller === void 0 ? void 0 : (ref4 = findCaller.individualRef) === null || ref4 === void 0 ? void 0 : ref4.fiscalCode);
            // TODO NEW MOP: Remove MMT before production
            var isCustomerXBS = (customerDataCF === null || customerDataCF === void 0 ? void 0 : customerDataCF.includes("XBS")) || (customerDataCF === null || customerDataCF === void 0 ? void 0 : customerDataCF.includes("MMX"));
            var isGlobalBsNewMopFixedMobile = (cmsGlobalConfig === null || cmsGlobalConfig === void 0 ? void 0 : (ref5 = cmsGlobalConfig.globalconfig) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.newMop) === null || ref6 === void 0 ? void 0 : ref6.enableNewMopFixed) === "true" || (cmsGlobalConfig === null || cmsGlobalConfig === void 0 ? void 0 : (ref7 = cmsGlobalConfig.globalconfig) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.newMop) === null || ref8 === void 0 ? void 0 : ref8.enableNewMopMobile) === "true";
            var isNewMopEnabled = isCustomerXBS && isGlobalBsNewMopFixedMobile;
            return billingAccountService(customerId, customOptions, isNewMopEnabled);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: queryEnabler && ((customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled : true),
            onSuccess: function(data) {
                var ref;
                localStorage.setItem("billingAccount", JSON.stringify(data));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - billingAccount: ", error);
                localStorage.setItem("billingAccount", JSON.stringify(errorMock("billingAccount", error)));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
