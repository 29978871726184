import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { ActionType, DeviceAcquisitionType, FlowType, ItemType, ObjectType } from "./createCart.models";
export var createPayloadFixed = function(offerId, installationAddressId, serviceabilityId) {
    return offerId && installationAddressId && serviceabilityId ? {
        shoppingCart: {
            cartItem: [
                {
                    action: ActionType.Add,
                    flowType: FlowType.HOME_INTERNET,
                    quantity: 1,
                    type: ItemType.MULTI_PLAY,
                    object_type: ObjectType.MULTI_PLAY_V1,
                    offering: {
                        id: offerId
                    },
                    multiPlayProduct: {
                        installationAddress: {
                            id: installationAddressId
                        }
                    }
                }, 
            ],
            serviceabilityId: serviceabilityId
        }
    } : null;
};
/**
 * Payload to create cart in mobile flow
 * if is required portability send fake number and operator
 * @param offerId
 * @param planId
 * @param isUserLogged
 * @param customerId
 * @param isRequiredPortability
 * @param configPortability
 */ export var createPayloadMobile = function(offerId, planId, isUserLogged, customerId, isRequiredPortability, configPortability) {
    return offerId && planId ? {
        shoppingCart: _object_spread_props(_object_spread(_object_spread_props(_object_spread({}, isUserLogged ? {
            customerId: {
                id: customerId
            }
        } : {}), {
            cartItem: [
                {
                    action: ActionType.Add,
                    flowType: FlowType.MOBILE_PLAN,
                    quantity: 1,
                    type: ItemType.MOBILE,
                    object_type: ObjectType.MOBILE_V1,
                    offering: {
                        id: "".concat(offerId, "_").concat(planId)
                    },
                    plan: {
                        type: ItemType.MOBILE_PLAN,
                        object_type: ObjectType.MOBILE_PLAN_V1,
                        offering: {
                            id: planId
                        }
                    },
                    deviceAcquisitionType: DeviceAcquisitionType.OWNED
                }, 
            ]
        }), isRequiredPortability && {
            phoneNumber: (configPortability === null || configPortability === void 0 ? void 0 : configPortability.dummyNumber) || "39999999999",
            mobileDonatingOperator: (configPortability === null || configPortability === void 0 ? void 0 : configPortability.dummyOperator) || "TIM_GSM"
        }), {
            serviceabilityId: null
        })
    } : null;
};
