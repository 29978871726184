import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
export var useFormExtended = function(extendedProps) {
    var requiredFields = extendedProps.requiredFields, props = _object_without_properties(extendedProps, [
        "requiredFields"
    ]);
    var defaultValues = props.defaultValues;
    var hookReturn = useForm(props);
    var setValue = hookReturn.setValue, errors = hookReturn.formState.errors, setError = hookReturn.setError, clearErrors = hookReturn.clearErrors, trigger = hookReturn.trigger, register = hookReturn.register, watch = hookReturn.watch;
    var allValues = watch();
    var registerSearchSelect = useCallback(function(fieldName, options) {
        var ref;
        return _object_spread({
            value: watch(fieldName),
            setValue: function(v) {
                setValue(fieldName, v);
            },
            errorMessage: errors === null || errors === void 0 ? void 0 : (ref = errors[fieldName]) === null || ref === void 0 ? void 0 : ref.message,
            setError: function(error) {
                setError(fieldName, error);
            },
            clearErrors: function() {
                clearErrors(fieldName);
            },
            trigger: function() {
                trigger(fieldName);
            }
        }, register(fieldName, options));
    }, [
        clearErrors,
        errors,
        register,
        setError,
        setValue,
        trigger,
        watch
    ]);
    var hasErrors = useMemo(function() {
        return !(allValues && errors && Object.keys(errors).length === 0 && errors.constructor === Object);
    }, // allValues is necessary as dependency to trig update on every change
    [
        errors,
        allValues
    ]);
    var isAllRequiredDirty = useMemo(function() {
        // Check if there is at least one field undefined or with the default value
        var checkFields = function(fieldName) {
            if (!defaultValues) {
                // Inputs become all optionals and requiredFields are useless
                return false;
            }
            return allValues[fieldName] === undefined || allValues[fieldName] === defaultValues[fieldName];
        };
        // Check only the required fields
        if (requiredFields) {
            return (requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.length) === 0 ? true : !requiredFields.find(checkFields);
        }
        // Check all fields
        return !Object.keys(allValues).find(checkFields);
    }, [
        allValues,
        defaultValues,
        requiredFields
    ]);
    // use isAllRequiredValid instead isValid because
    // the isValid of react-hook-form does not watch custom errors:
    // infact setError has no effect on isValid formState,
    // isValid will always derived via the default form validation result.
    var isAllRequiredValid = useMemo(function() {
        return isAllRequiredDirty && !hasErrors;
    }, [
        hasErrors,
        isAllRequiredDirty
    ]);
    return {
        hookReturn: hookReturn,
        registerSearchSelect: registerSearchSelect,
        allValues: allValues,
        isAllRequiredValid: isAllRequiredValid
    };
};
