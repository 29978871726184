import { formatQueryParam } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
/**
 * Given a list of SKUs it returns back the DEFAUL if is SELLABLE
 *
 * If the DEFAULT is NOT SELLABLE it retrieve the defaultIdColor
 * and it returns back the first SELLABLE with DEFAULT_ID_COLOR
 *
 * @param skus
 * @returns
 */ var getDefaultSku = function(skus) {
    var ref;
    // Getting the DEFAULT if AVAILABLE
    var defaultSku = skus.find(function(sku) {
        return (sku === null || sku === void 0 ? void 0 : sku.isDefault) && (sku === null || sku === void 0 ? void 0 : sku.isSellable);
    });
    if (defaultSku) {
        return defaultSku;
    }
    // Get the default color
    var defaultIdColor = (ref = skus.find(function(sku) {
        return sku === null || sku === void 0 ? void 0 : sku.isDefault;
    })) === null || ref === void 0 ? void 0 : ref.idColor;
    // Return the firs available of the color
    return skus.find(function(sku) {
        return (sku === null || sku === void 0 ? void 0 : sku.idColor) === defaultIdColor && (sku === null || sku === void 0 ? void 0 : sku.isSellable);
    });
};
/**
 * Return the fallback Sku if there is no SIZE and COLOR parameter.
 * It handles the cases:
 * -  COLOR |   SIZE  -
 * -    0   |    0    -  --> FIRST SKU when DEFAULT:TRUE + SELLABLE:TRUE
 * -    0   |    1    -  --> FIRST COLOR when SIZE:1 + SELLABLE:TRUE
 * -    1   |    0    -  --> FIRST SIZE when COLOR:1 + SELLABLE:TRUE
 * -    1   |    1    -  --> NOT HANDLED
 * --------------------
 * @param skus
 * @param colorMapping
 * @param sizeMapping
 * @returns
 */ var getFallBackSku = function(skus, colorMapping, sizeMapping) {
    // FALLBACKS
    // 1 - NO PARAMS
    if (!colorMapping && !sizeMapping) {
        return getDefaultSku(skus);
    }
    // 2 - ONLY COLOR
    if (colorMapping && !sizeMapping) {
        LoggerInstance.debug(skus.find(function(sku) {
            return (sku === null || sku === void 0 ? void 0 : sku.idColor) == colorMapping.id;
        }));
        return skus.find(function(sku) {
            return (sku === null || sku === void 0 ? void 0 : sku.idColor) == colorMapping.id && (sku === null || sku === void 0 ? void 0 : sku.isSellable);
        });
    }
    // 3 - ONLY SIZE
    if (!colorMapping && sizeMapping) {
        return skus.find(function(sku) {
            return (sku === null || sku === void 0 ? void 0 : sku.idSize) == sizeMapping.id && (sku === null || sku === void 0 ? void 0 : sku.isSellable);
        });
    }
    return undefined;
};
/**
 * Return mapped device/characteristic
 * @param queryParams
 * @param skus
 * @param characteristics
 */ var getMappingDeviceCharacteristics = function(queryParams, skus, characteristics) {
    var ref, ref1, ref2, ref3, ref4;
    // Destructuring COLOR and SIZE from queryParams Object
    var colorQueryParam = queryParams.color, sizeQueryParam = queryParams.size;
    // Return the Ids for COLOR and SIZE from Characteristics JSON Ogject
    var colorQueryParamId = (ref = skus.find(function(sku) {
        return formatQueryParam(sku.displayNameColor) === colorQueryParam;
    })) === null || ref === void 0 ? void 0 : ref.idColor;
    // colorQueryParams characteristics skus
    var colorMapping = characteristics === null || characteristics === void 0 ? void 0 : (ref1 = characteristics.colors) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.elements) === null || ref2 === void 0 ? void 0 : ref2.find(function(color) {
        return color.id == colorQueryParamId;
    });
    var sizeMapping = characteristics === null || characteristics === void 0 ? void 0 : (ref3 = characteristics.sizes) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.elements) === null || ref4 === void 0 ? void 0 : ref4.find(function(size) {
        return formatQueryParam(size.value) == sizeQueryParam;
    });
    return {
        colorQueryParam: colorQueryParam,
        colorMapping: colorMapping,
        sizeQueryParam: sizeQueryParam,
        sizeMapping: sizeMapping
    };
};
/**
 * Return Sku initial or fallback if no initial founded
 * @param skus
 * @param mappedDeviceCharacteristic
 * @returns
 */ var getSku = function(skus, mappedDeviceCharacteristic) {
    var colorMapping = mappedDeviceCharacteristic.colorMapping, sizeMapping = mappedDeviceCharacteristic.sizeMapping;
    // Filtering the DEFAULT fallback sku
    var fallbackSku = getFallBackSku(skus, colorMapping, sizeMapping);
    LoggerInstance.debug({
        fallbackSku: fallbackSku
    });
    // FILTERING (COLOR-SIZE)
    var initialSku = skus.find(function(sku) {
        return (sku === null || sku === void 0 ? void 0 : sku.idColor) == (colorMapping === null || colorMapping === void 0 ? void 0 : colorMapping.id) && (sku === null || sku === void 0 ? void 0 : sku.idSize) == (sizeMapping === null || sizeMapping === void 0 ? void 0 : sizeMapping.id);
    });
    LoggerInstance.debug({
        initialSku: initialSku
    });
    return initialSku || fallbackSku;
};
export { getSku, getDefaultSku, getFallBackSku, getMappingDeviceCharacteristics };
