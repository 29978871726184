import { getDXLAddonProductPrice } from "@vfit/consumer/data-access";
export var getLandingPrice = function(offerProduct, product) {
    var ref, ref1, ref2;
    var allPrices = (offerProduct === null || offerProduct === void 0 ? void 0 : (ref = offerProduct.campaignDetail) === null || ref === void 0 ? void 0 : ref.price) || [];
    var priceInCampaign = allPrices === null || allPrices === void 0 ? void 0 : allPrices.find(function(e) {
        return (e === null || e === void 0 ? void 0 : e.priceType) === "recurring" && (e === null || e === void 0 ? void 0 : e.priceRef) === "campaign";
    });
    var price = getDXLAddonProductPrice((priceInCampaign === null || priceInCampaign === void 0 ? void 0 : priceInCampaign.value) || "");
    if ((product === null || product === void 0 ? void 0 : (ref1 = product.landingPagePriceConfig) === null || ref1 === void 0 ? void 0 : ref1.isTryAndBy) === "true") {
        var ref3, ref4, ref5, ref6, ref7, ref8;
        return {
            price: "Gratis",
            recurrence: (product === null || product === void 0 ? void 0 : (ref3 = product.landingPagePriceConfig) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.tryAndBy) === null || ref4 === void 0 ? void 0 : ref4.recurrence) || "",
            label: (product === null || product === void 0 ? void 0 : (ref5 = product.landingPagePriceConfig) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.tryAndBy) === null || ref6 === void 0 ? void 0 : ref6.label) || "",
            subprice: product === null || product === void 0 ? void 0 : (ref7 = product.subprice) === null || ref7 === void 0 ? void 0 : ref7.replace("$PRICE", ((ref8 = price === null || price === void 0 ? void 0 : price.toString()) === null || ref8 === void 0 ? void 0 : ref8.replace(".", ",")) || "")
        };
    }
    if (price > 0) {
        var ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22;
        return {
            price: (product === null || product === void 0 ? void 0 : (ref9 = product.landingPagePriceConfig) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.fixedPrice) === null || ref10 === void 0 ? void 0 : ref10.amount) ? product === null || product === void 0 ? void 0 : (ref11 = product.landingPagePriceConfig) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.fixedPrice) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.amount) === null || ref13 === void 0 ? void 0 : (ref15 = ref13.replace) === null || ref15 === void 0 ? void 0 : ref15.call(ref13, "$PRICE", ((ref14 = price === null || price === void 0 ? void 0 : price.toString()) === null || ref14 === void 0 ? void 0 : ref14.replace(".", ",")) || "") : (ref16 = price === null || price === void 0 ? void 0 : price.toString()) === null || ref16 === void 0 ? void 0 : ref16.replace(".", ","),
            recurrence: (product === null || product === void 0 ? void 0 : (ref17 = product.landingPagePriceConfig) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.fixedPrice) === null || ref18 === void 0 ? void 0 : ref18.recurrence) || "",
            label: (product === null || product === void 0 ? void 0 : (ref19 = product.landingPagePriceConfig) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.fixedPrice) === null || ref20 === void 0 ? void 0 : ref20.label) || "",
            subprice: product === null || product === void 0 ? void 0 : (ref21 = product.subprice) === null || ref21 === void 0 ? void 0 : ref21.replace("$PRICE", ((ref22 = price === null || price === void 0 ? void 0 : price.toString()) === null || ref22 === void 0 ? void 0 : ref22.replace(".", ",")) || "")
        };
    }
    if (price == 0) {
        var ref23, ref24, ref25, ref26, ref27, ref28;
        return {
            price: "Gratis",
            recurrence: (product === null || product === void 0 ? void 0 : (ref23 = product.landingPagePriceConfig) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.oneShot) === null || ref24 === void 0 ? void 0 : ref24.recurrence) || "",
            label: (product === null || product === void 0 ? void 0 : (ref25 = product.landingPagePriceConfig) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.oneShot) === null || ref26 === void 0 ? void 0 : ref26.label) || "",
            subprice: product === null || product === void 0 ? void 0 : (ref27 = product.subprice) === null || ref27 === void 0 ? void 0 : ref27.replace("$PRICE", ((ref28 = price === null || price === void 0 ? void 0 : price.toString()) === null || ref28 === void 0 ? void 0 : ref28.replace(".", ",")) || "")
        };
    }
    return {
        price: (ref2 = price === null || price === void 0 ? void 0 : price.toString()) === null || ref2 === void 0 ? void 0 : ref2.replace(".", ","),
        recurrence: "",
        priceVariant: "",
        label: "",
        subprice: ""
    };
};
