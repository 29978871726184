import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useGetFindCaller, ErrorCodes } from "@vfit/consumer/data-access";
import { computeInverse } from "@vfit/shared/data-access";
import { useEffect, useState } from "react";
export var useFiscalCodeFlow = function(param) {
    var _isFlowEnabled = param.isFlowEnabled, isFlowEnabled = _isFlowEnabled === void 0 ? true : _isFlowEnabled, fiscalCode = param.fiscalCode, owningDataIsValid = param.owningDataIsValid, _ageLimit = param.ageLimit, ageLimit = _ageLimit === void 0 ? 18 : _ageLimit, _isWinbackFlow = param.isWinbackFlow, isWinbackFlow = _isWinbackFlow === void 0 ? false : _isWinbackFlow;
    // const queryClient = useQueryClient();
    var ref = useState(false), isSuccess = ref[0];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var ref3 = useState(""), errorMessage = ref3[0], setErrorMessage = ref3[1];
    var ref4 = useState(""), errorCode = ref4[0], setErrorCode = ref4[1];
    var ref5 = useState(false), fiscalCodeIsValid = ref5[0], setFiscalCodeIsValid = ref5[1];
    var ref6 = useState(false), isInResetCustomer = ref6[0], setIsInResetCustomer = ref6[1];
    /*
 Getting all the query related o check the responses data
*/ // const offeringServiceability = getDataFromStorage<IOfferingServiceabilityResponse>('offeringServiceability');
    // const createdAddress = getDataFromStorage<IAddressResponse>('createdAddress');
    // const fiscalCodeResponse =
    //   getDataFromStorage<IFiscalCodeBirthPlaceResponse>('fiscalCodeBirthPlace');
    // const shoppingCart = getDataFromStorage<IShoppingCartResponse>('shoppingCart');
    // const checkout = getDataFromStorage<ICheckoutResponse>('checkout')
    var cfOld = localStorage.getItem("customerDataCf");
    /*
 Enabling API calls only if the fiscal code is a valid one
*/ var isFiscalCodeValid = function() {
        return !!(fiscalCode && fiscalCode.length === 16);
    };
    /*
 Firing useGetFiscalCodeInfo API calls only if the fiscal code is a valid and owningDataIsValid
*/ // const {
    //   data: fiscalCodeData,
    //   isSuccess: fiscalCodeIsSuccess,
    //   isError: fiscalCodeIsError,
    //   isLoading: fiscalCodeIsLoading,
    // } = useGetFiscalCodeInfo(fiscalCode, {
    //   enabled: owningDataIsValid && fiscalCodeIsValid && isFiscalCodeValid(),
    // });
    /*
 Firing useGetFindCaller API calls only if the fiscal code is a valid and owningDataIsValid and fiscalCode length equal 16
*/ var ref7 = useGetFindCaller(_object_spread_props(_object_spread({}, isWinbackFlow && {
        isWinbackFlow: isWinbackFlow
    }), {
        fiscalCode: fiscalCode === null || fiscalCode === void 0 ? void 0 : fiscalCode.toUpperCase()
    }), {
        enabled: owningDataIsValid && fiscalCodeIsValid && fiscalCode.length === 16 && isFlowEnabled
    }), getFindCallerResponse = ref7.data, isLoadingGetFindCaller = ref7.isLoading, isErrorFindCaller = ref7.isError;
    /*
 Check fiscal code error
*/ var checkFiscalCode = function() {
        var cfData = computeInverse(fiscalCode);
        var getAge = function(birthDateString) {
            var today = new Date();
            var birthDate = new Date(birthDateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || m === 0 && today.getDate() < birthDate.getDate()) {
                // eslint-disable-next-line no-plusplus
                age--;
            }
            if (age === ageLimit) {
                if (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()) {
                    return age - 1;
                }
                if (today.getMonth() < birthDate.getMonth()) {
                    return age - 1;
                }
                return age;
            }
            return age;
        };
        var day = cfData === null || cfData === void 0 ? void 0 : cfData.day;
        // eslint-disable-next-line no-unsafe-optional-chaining
        var year = +(cfData === null || cfData === void 0 ? void 0 : cfData.year);
        var month = cfData === null || cfData === void 0 ? void 0 : cfData.month;
        var age = getAge("".concat(month, "/").concat(day, "/").concat(year));
        if (!cfData) {
            setErrorCode(ErrorCodes.FISCAL_CODE_ERROR);
            setIsError(false);
            setFiscalCodeIsValid(false);
        } else if (!cfData.year || age < ageLimit) {
            setErrorCode(ErrorCodes.FISCAL_CODE_UNDERAGE);
            setIsError(true);
            setFiscalCodeIsValid(false);
        } else {
            setErrorCode("");
            setIsError(false);
            setFiscalCodeIsValid(true);
        }
    };
    /*
 Custom method to reset silentLogin authentication token due to current customer updating
*/ var resetShoppingCart = function() {
    // sessionStorage.removeItem('uxfauthorization');
    // const uxfauthorization = sessionStorage.getItem('uxfauthorization');
    // if (!uxfauthorization) {
    //   shoppingCartMutation.mutate({createdAddress, offeringServiceability} as {
    //     createdAddress: IAddressResponse;
    //     offeringServiceability: IOfferingServiceabilityResponse;
    //   });
    // }
    };
    /*
 Handle the error reset on fiscalCode/owningDataIsValid's changing
*/ useEffect(function() {
        setErrorCode("");
        setIsError(false);
        setIsLoading(false);
        setFiscalCodeIsValid(false);
        if (owningDataIsValid && isFiscalCodeValid()) {
            checkFiscalCode();
        }
    }, [
        fiscalCode,
        owningDataIsValid
    ]);
    // useEffect(() => {
    //   if (shoppingCartMutation.isSuccess && !!shoppingCartMutation?.data)
    //     deleteShoppingCartMutation.mutate(shoppingCartMutation.data);
    // }, [!!shoppingCartMutation?.data, shoppingCartMutation.isSuccess]);
    //
    // useEffect(() => {
    //   if (deleteShoppingCartMutation.isSuccess && !!deleteShoppingCartMutation?.data && !checkoutMutation.isLoading && !checkoutMutation.isSuccess) checkoutMutation.mutate(shoppingCartMutation?.data?.id);
    // }, [!!deleteShoppingCartMutation?.data, deleteShoppingCartMutation.isSuccess]);
    //
    // useEffect(() => {
    //   if (checkoutMutation.isSuccess && !!checkoutMutation?.data) {
    //     setIsLoading(false);
    //     setIsSuccess(true);
    //     setIsError(false);
    //   }
    // }, [!!checkoutMutation?.data]);
    /*
Firing the checkout mutation API call on page rerender
*/ // useEffect(() => {
    //   //setIsLoading(true); TODO RA:discuss about
    //   if (!isSiaError && !!shoppingCart && !checkoutMutation.isSuccess && !checkoutMutation.isLoading && !checkout) checkoutMutation.mutate(undefined);
    // }, [!!shoppingCart]);
    /*
Reset query and authentication token on current customer updating
 */ useEffect(function() {
        if (getFindCallerResponse && cfOld && fiscalCode && fiscalCode !== cfOld) {
            setIsInResetCustomer(true);
        // resetShoppingCart();
        }
    }, [
        getFindCallerResponse
    ]);
    /*
Error and Success handling on useFiscalCodeFlow response
*/ useEffect(function() {
        if (isLoadingGetFindCaller) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [
        isLoadingGetFindCaller
    ]);
    useEffect(function() {
        if (isErrorFindCaller) {
            setErrorCode(ErrorCodes.FISCAL_CODE_ERROR);
            setIsError(true);
            setFiscalCodeIsValid(false);
        }
    }, [
        isErrorFindCaller
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        getFindCallerResponse: getFindCallerResponse,
        errorMessage: errorMessage,
        errorCode: errorCode,
        resetShoppingCart: resetShoppingCart,
        isInResetCustomer: isInResetCustomer
    };
};
