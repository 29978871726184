import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { useMutation, useQueryClient } from "react-query";
/**
 * Recall service POST /updateProductCharacteristic/voucher
 * Update voucher product characteristic
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ export var voucherService = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.UPDATE_PRODUCT_CHARACTERISTIC, "/").concat(API.VOUCHER), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run updateVoucherService.
 * @returns
 */ export var useVoucher = function() {
    var queryClient = useQueryClient();
    var keyString = "voucher";
    return useMutation([
        "doVoucher"
    ], function(payload) {
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        LoggerInstance.debug("doVoucher before calling service : ", cartId);
        return voucherService(cartId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
