import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useQueryClient } from "react-query";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
/**
 * This method calls the paymentService looking for a specific cartId
 * Method: GET
 * searchParams: {
 * salesChannel: 'selfService',
 * levelOfData: 'amount,paymentMethod,paymentDistribution'}
 * @param cartId
 * @param customOptions
 * @returns
 */ var paymentService = function(cartId, customOptions) {
    return nextClient.get("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.PAYMENT), _object_spread({
        searchParams: {
            salesChannel: "selfService",
            levelOfData: "amount,paymentMethod,paymentDistribution"
        }
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method calls the paymentService
 * @param customOptions
 * @returns
 */ export var usePayment = function(customOptions) {
    var queryClient = useQueryClient();
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var queryEnabler = customOptions ? customOptions.enabled && !!cartId : !!cartId;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "paymentData";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return paymentService(cartId, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: queryEnabler,
            onSuccess: function(data) {
                queryClient.setQueryData("paymentData", data);
                localStorage.setItem("paymentData", JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - paymentData: ", error);
                queryClient.setQueryData("paymentData", errorMock("paymentData", error));
                localStorage.setItem("paymentData", JSON.stringify(errorMock("paymentData", error)));
            }
        })
    };
    return handleUseQuery(options);
};
