import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CART_TYPE } from "@vfit/consumer/data-access";
import { getInstallmentOptionsFWA } from "../../checkout.utils";
var getCostsFWA = function() {
    var ref, ref1, ref2, ref3, ref4, ref5;
    var installmentOptions = getInstallmentOptionsFWA();
    var amountOption = installmentOptions === null || installmentOptions === void 0 ? void 0 : (ref = installmentOptions.options) === null || ref === void 0 ? void 0 : ref.find(function(el) {
        return el.id == "0";
    });
    var recurringOption = installmentOptions === null || installmentOptions === void 0 ? void 0 : (ref1 = installmentOptions.options) === null || ref1 === void 0 ? void 0 : ref1.find(function(el) {
        return el.id == "24";
    });
    var ref6 = amountOption || {}, installmentPriceAmount = ref6.installmentPrice;
    var ref7 = recurringOption || {}, installmentPriceRecurring = ref7.installmentPrice;
    var oneTimeCost = ((ref2 = installmentPriceAmount === null || installmentPriceAmount === void 0 ? void 0 : installmentPriceAmount.find(function(el) {
        return el.type === CART_TYPE.ONE_TIME;
    })) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.originalprice) === null || ref3 === void 0 ? void 0 : ref3.taxIncludedAmount) || 0;
    var recurringMonths = 24;
    var recurringCost = ((ref4 = installmentPriceRecurring === null || installmentPriceRecurring === void 0 ? void 0 : installmentPriceRecurring.find(function(el) {
        return el.type === CART_TYPE.RECURRING;
    })) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.originalprice) === null || ref5 === void 0 ? void 0 : ref5.taxIncludedAmount) || 0;
    return {
        oneTimeCost: oneTimeCost,
        recurringMonths: recurringMonths,
        recurringCost: recurringCost
    };
};
var getCheckoutFWA = function(product) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    var costsFWA = getCostsFWA();
    return _object_spread_props(_object_spread({}, product), {
        checkoutFWA: _object_spread_props(_object_spread({}, product.checkoutFWA), {
            title: (ref4 = (ref2 = product === null || product === void 0 ? void 0 : (ref = product.checkoutFWA) === null || ref === void 0 ? void 0 : (ref1 = ref.title) === null || ref1 === void 0 ? void 0 : ref1.replace("$MONTHLY", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : costsFWA.recurringMonths) || ""))) === null || ref2 === void 0 ? void 0 : ref2.replace("$ONETIME", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : (ref3 = costsFWA.oneTimeCost) === null || ref3 === void 0 ? void 0 : ref3.toString()) || ""))) === null || ref4 === void 0 ? void 0 : ref4.replace("$PAYMENTS", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : costsFWA.recurringCost) || "")),
            description: (ref9 = (ref7 = product === null || product === void 0 ? void 0 : (ref5 = product.checkoutFWA) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.description) === null || ref6 === void 0 ? void 0 : ref6.replace("$MONTHLY", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : costsFWA.recurringMonths) || ""))) === null || ref7 === void 0 ? void 0 : ref7.replace("$ONETIME", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : (ref8 = costsFWA.oneTimeCost) === null || ref8 === void 0 ? void 0 : ref8.toString()) || ""))) === null || ref9 === void 0 ? void 0 : ref9.replace("$PAYMENTS", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : costsFWA.recurringCost) || "")),
            items: product === null || product === void 0 ? void 0 : (ref10 = product.checkoutFWA) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.items) === null || ref11 === void 0 ? void 0 : ref11.map(function(el) {
                var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
                return _object_spread_props(_object_spread({}, el), {
                    title: (ref3 = (ref1 = (ref = el.title) === null || ref === void 0 ? void 0 : ref.replace("$MONTHLY", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : costsFWA.recurringMonths) || ""))) === null || ref1 === void 0 ? void 0 : ref1.replace("$ONETIME", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : (ref2 = costsFWA.oneTimeCost) === null || ref2 === void 0 ? void 0 : ref2.toString()) || ""))) === null || ref3 === void 0 ? void 0 : ref3.replace("$PAYMENTS", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : costsFWA.recurringCost) || "")),
                    description: (ref7 = (ref5 = (ref4 = el.description) === null || ref4 === void 0 ? void 0 : ref4.replace("$MONTHLY", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : costsFWA.recurringMonths) || ""))) === null || ref5 === void 0 ? void 0 : ref5.replace("$ONETIME", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : (ref6 = costsFWA.oneTimeCost) === null || ref6 === void 0 ? void 0 : ref6.toString()) || ""))) === null || ref7 === void 0 ? void 0 : ref7.replace("$PAYMENTS", "".concat((costsFWA === null || costsFWA === void 0 ? void 0 : costsFWA.recurringCost) || ""))
                });
            })
        })
    });
};
export { getCheckoutFWA };
