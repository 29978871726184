import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { handleFirstCharacterOfSentence, organizeEditAddressFixed } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { isOutdoor, isTechFWA } from "../../checkout.utils";
var getDataOrganized = function(deliveryInformation, owningIndividual, installationAddress, shippingAddress, billingAddress, editAddressData, showTechAddress) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37, ref38, ref39, ref40, ref41, ref42, ref43, ref44;
    var prefix = deliveryInformation === null || deliveryInformation === void 0 ? void 0 : deliveryInformation.deliveryinformation;
    // this is label for fwa indoor or outdoor before editing shipping
    var shippingAddressFWA = isOutdoor() ? (prefix === null || prefix === void 0 ? void 0 : (ref = prefix.outdoor) === null || ref === void 0 ? void 0 : ref.shippingLabel) || "" : (prefix === null || prefix === void 0 ? void 0 : (ref1 = prefix.indoor) === null || ref1 === void 0 ? void 0 : ref1.shippingLabel) || "";
    // this is label for fwa indoor or outdoor after editing shipping
    var editShippingAddressFWA = isOutdoor() ? (prefix === null || prefix === void 0 ? void 0 : (ref2 = prefix.outdoor) === null || ref2 === void 0 ? void 0 : ref2.shippingLabelChange) || "" : (prefix === null || prefix === void 0 ? void 0 : (ref3 = prefix.indoor) === null || ref3 === void 0 ? void 0 : ref3.shippingLabelChange) || "";
    var deliveryData = _object_spread_props(_object_spread({
        topTitle: (prefix === null || prefix === void 0 ? void 0 : (ref4 = prefix.resume) === null || ref4 === void 0 ? void 0 : ref4.title) || ""
    }, showTechAddress && {
        description: isTechFWA() ? (prefix === null || prefix === void 0 ? void 0 : (ref5 = prefix.indoor) === null || ref5 === void 0 ? void 0 : ref5.description) || "" : (prefix === null || prefix === void 0 ? void 0 : (ref6 = prefix.resume) === null || ref6 === void 0 ? void 0 : ref6.description) || ""
    }, !showTechAddress && {
        description: isTechFWA() ? (prefix === null || prefix === void 0 ? void 0 : (ref7 = prefix.outdoor) === null || ref7 === void 0 ? void 0 : ref7.description) || "" : (prefix === null || prefix === void 0 ? void 0 : (ref8 = prefix.resume) === null || ref8 === void 0 ? void 0 : ref8.description) || ""
    }), {
        userInfoTitle: (prefix === null || prefix === void 0 ? void 0 : (ref9 = prefix.commonLabel) === null || ref9 === void 0 ? void 0 : ref9.installationAddress) || "Indirizzo di installazione",
        billingData: {
            title: (prefix === null || prefix === void 0 ? void 0 : (ref10 = prefix.addressBilling) === null || ref10 === void 0 ? void 0 : ref10.bottomTitle) || "Indirizzo di fatturazione",
            matchAddress: (prefix === null || prefix === void 0 ? void 0 : (ref11 = prefix.addressBilling) === null || ref11 === void 0 ? void 0 : ref11.title) || "L’indirizzo di fatturazione corrisponde all’indirizzo di installazione.",
            noMatch: (prefix === null || prefix === void 0 ? void 0 : (ref12 = prefix.addressBilling) === null || ref12 === void 0 ? void 0 : ref12.description) || "Non corrisponde?",
            labelEdit: (prefix === null || prefix === void 0 ? void 0 : (ref13 = prefix.addressBilling) === null || ref13 === void 0 ? void 0 : ref13.linkLabelEdit) || "Modifica Indirizzo",
            changeAddress: (prefix === null || prefix === void 0 ? void 0 : (ref14 = prefix.addressBilling) === null || ref14 === void 0 ? void 0 : ref14.linkLabel) || "Cambia l'indirizzo di fatturazione"
        },
        shippingData: {
            title: (prefix === null || prefix === void 0 ? void 0 : (ref15 = prefix.addressDelivery) === null || ref15 === void 0 ? void 0 : ref15.bottomTitle) || "Cambia l'indirizzo di spedizione",
            matchAddress: (prefix === null || prefix === void 0 ? void 0 : (ref16 = prefix.addressDelivery) === null || ref16 === void 0 ? void 0 : ref16.title) || "L'indirizzo di spedizione corrisponde all'indirizzo di installazione.",
            noMatch: (prefix === null || prefix === void 0 ? void 0 : (ref17 = prefix.addressDelivery) === null || ref17 === void 0 ? void 0 : ref17.description) || "Non corrisponde?",
            labelEdit: (prefix === null || prefix === void 0 ? void 0 : (ref18 = prefix.addressDelivery) === null || ref18 === void 0 ? void 0 : ref18.linkLabel) || "Modifica Indirizzo",
            changeAddress: (prefix === null || prefix === void 0 ? void 0 : (ref19 = prefix.addressDelivery) === null || ref19 === void 0 ? void 0 : ref19.linkLabelEdit) || "Cambia l'indirizzo di spedizione"
        },
        noteLabels: {
            noteTitle: (prefix === null || prefix === void 0 ? void 0 : (ref20 = prefix.commonLabel) === null || ref20 === void 0 ? void 0 : ref20.descriptionNote) || "Inserisci le note per la consegna",
            noteTitleAfterEdit: (prefix === null || prefix === void 0 ? void 0 : (ref21 = prefix.commonLabel) === null || ref21 === void 0 ? void 0 : ref21.linkLabelEdit) || "Modifica note per la consegna"
        },
        noteModalConfig: {
            title: (prefix === null || prefix === void 0 ? void 0 : (ref22 = prefix.commonLabel) === null || ref22 === void 0 ? void 0 : ref22.linkLabel) || "Note di consegna",
            description: (prefix === null || prefix === void 0 ? void 0 : (ref23 = prefix.commonLabel) === null || ref23 === void 0 ? void 0 : ref23.modalTextLabel) || "Inserisci le note per la consegna",
            textLabel: (prefix === null || prefix === void 0 ? void 0 : (ref24 = prefix.commonLabel) === null || ref24 === void 0 ? void 0 : ref24.placeholderNotes) || "Note per la consegna",
            ctaLabel: (prefix === null || prefix === void 0 ? void 0 : (ref25 = prefix.commonLabel) === null || ref25 === void 0 ? void 0 : ref25.modalCtaLabel) || "Salva note",
            titleMod: (prefix === null || prefix === void 0 ? void 0 : (ref26 = prefix.commonLabel) === null || ref26 === void 0 ? void 0 : ref26.linkLabelEdit) || "Modifica note per la consegna",
            descriptionMod: (prefix === null || prefix === void 0 ? void 0 : (ref27 = prefix.commonLabel) === null || ref27 === void 0 ? void 0 : ref27.linkLabel) || "Note di consegna"
        },
        labelShippingFWA: shippingAddressFWA || "",
        labelChangeAddressFWA: editShippingAddressFWA || "",
        afterShippingEdit: isTechFWA() ? editShippingAddressFWA || "" : (prefix === null || prefix === void 0 ? void 0 : (ref28 = prefix.addressChange) === null || ref28 === void 0 ? void 0 : ref28.subtitle) || ""
    });
    return {
        topTitle: (deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.topTitle) || "",
        description: (deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.description) || "",
        userInfoTitle: deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.userInfoTitle,
        userData: {
            lastName: owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName,
            firstName: owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName,
            address: handleFirstCharacterOfSentence((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.street) || "").concat(" ", (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.streetNumber) || ""),
            city: handleFirstCharacterOfSentence((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.city) || ""),
            zipCode: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postalCode) || "",
            province: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.stateOrProvince) || ""
        },
        billingData: {
            matchAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref29 = deliveryData.billingData) === null || ref29 === void 0 ? void 0 : ref29.matchAddress) || "",
            noMatch: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref30 = deliveryData.billingData) === null || ref30 === void 0 ? void 0 : ref30.noMatch) || "",
            title: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref31 = deliveryData.billingData) === null || ref31 === void 0 ? void 0 : ref31.title) || "",
            labelEdit: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref32 = deliveryData.billingData) === null || ref32 === void 0 ? void 0 : ref32.labelEdit) || "",
            changeAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref33 = deliveryData.billingData) === null || ref33 === void 0 ? void 0 : ref33.changeAddress) || "",
            ctaAction: function() {
                return LoggerInstance.debug("billing");
            },
            addressData: {
                lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
                firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
                address: handleFirstCharacterOfSentence(billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.street).concat(" ", (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.streetNumber) || ""),
                city: handleFirstCharacterOfSentence(billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.city),
                zipCode: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.postalCode) || "",
                province: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.stateOrProvince) || ""
            }
        },
        shippingData: {
            matchAddress: isTechFWA() ? deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.labelShippingFWA : deliveryData === null || deliveryData === void 0 ? void 0 : (ref34 = deliveryData.shippingData) === null || ref34 === void 0 ? void 0 : ref34.matchAddress,
            noMatch: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref35 = deliveryData.shippingData) === null || ref35 === void 0 ? void 0 : ref35.noMatch) || "",
            title: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref36 = deliveryData.shippingData) === null || ref36 === void 0 ? void 0 : ref36.title) || "",
            labelEdit: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref37 = deliveryData.shippingData) === null || ref37 === void 0 ? void 0 : ref37.labelEdit) || "",
            changeAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref38 = deliveryData.shippingData) === null || ref38 === void 0 ? void 0 : ref38.changeAddress) || "",
            ctaAction: function() {
                return LoggerInstance.debug("shipping");
            },
            addressData: {
                lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
                firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
                address: handleFirstCharacterOfSentence((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.street) || "").concat(" ", (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.streetNumber) || ""),
                city: handleFirstCharacterOfSentence(shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.city),
                zipCode: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postalCode) || "",
                province: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.stateOrProvince) || "",
                note: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.note) || "",
                postalOfficeDescription: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postalOfficeDescription) || ""
            }
        },
        installationData: {
            matchAddress: isTechFWA() ? deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.labelShippingFWA : deliveryData === null || deliveryData === void 0 ? void 0 : (ref39 = deliveryData.shippingData) === null || ref39 === void 0 ? void 0 : ref39.matchAddress,
            noMatch: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref40 = deliveryData.shippingData) === null || ref40 === void 0 ? void 0 : ref40.noMatch) || "",
            title: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref41 = deliveryData.shippingData) === null || ref41 === void 0 ? void 0 : ref41.title) || "",
            labelEdit: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref42 = deliveryData.shippingData) === null || ref42 === void 0 ? void 0 : ref42.labelEdit) || "",
            changeAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref43 = deliveryData.shippingData) === null || ref43 === void 0 ? void 0 : ref43.changeAddress) || "",
            addressData: {
                lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
                firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
                address: handleFirstCharacterOfSentence((installationAddress === null || installationAddress === void 0 ? void 0 : installationAddress.street) || "").concat(" ", (installationAddress === null || installationAddress === void 0 ? void 0 : installationAddress.streetNumber) || ""),
                city: handleFirstCharacterOfSentence(installationAddress === null || installationAddress === void 0 ? void 0 : installationAddress.city),
                zipCode: (installationAddress === null || installationAddress === void 0 ? void 0 : installationAddress.postalCode) || "",
                province: (installationAddress === null || installationAddress === void 0 ? void 0 : installationAddress.stateOrProvince) || "",
                note: (installationAddress === null || installationAddress === void 0 ? void 0 : installationAddress.note) || "",
                postalOfficeDescription: (installationAddress === null || installationAddress === void 0 ? void 0 : installationAddress.postalOfficeDescription) || ""
            }
        },
        changeAddressFwa: (deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.labelChangeAddressFWA) || "",
        afterEditAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.afterShippingEdit) || "",
        noteModalConfig: deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.noteModalConfig,
        noteLabels: deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.noteLabels,
        editAddressConfig: organizeEditAddressFixed(editAddressData.editaddressconfig),
        disableAddNotes: (deliveryInformation === null || deliveryInformation === void 0 ? void 0 : (ref44 = deliveryInformation.deliveryinformation) === null || ref44 === void 0 ? void 0 : ref44.disableAddNotes) === "true"
    };
};
var skeletonShape = {
    margins: [
        60,
        30
    ],
    tabletMargins: [
        32,
        15
    ],
    groups: [
        {
            heights: 45,
            marginBottom: 15
        },
        {
            heights: 60,
            marginBottom: 32
        },
        {
            heights: 60,
            marginBottom: 15
        },
        {
            heights: 75,
            repeat: 4,
            gap: 15
        },
        {
            heights: 45,
            marginBottom: 15
        }, 
    ]
};
export { getDataOrganized, skeletonShape };
