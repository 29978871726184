import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
export var useConsentsFlow = function() {
    var ref = useState([]), partyPrivacyItems = ref[0], setPartyPrivacyItems = ref[1];
    var pdfSource = [
        "https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/3556298.pdf",
        "https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/3556298.pdf",
        "https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/3556298.pdf",
        "https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/3556298.pdf", 
    ];
    // TOFIX: Edit  this part and move ALL on CMS @Vign. (by code VFHAPPYLIGHT...)
    var mapData = function() {
        var ref;
        var retrieveMultilines = localStorage.getItem("retrieveMultiLines");
        var retrivedItems = retrieveMultilines ? JSON.parse(retrieveMultilines) : [];
        var mappedData = retrivedItems === null || retrivedItems === void 0 ? void 0 : (ref = retrivedItems.partyPrivacyItems) === null || ref === void 0 ? void 0 : ref.map(function(item, index) {
            return _object_spread_props(_object_spread({}, item), {
                selectedValue: (item === null || item === void 0 ? void 0 : item.selectedValue) || false,
                pdf: pdfSource[index],
                name: item.title || "",
                description: "".concat(item.longDescription ? item.description : ""),
                longDescription: item.longDescription || item.description
            });
        });
        return mappedData;
    };
    useEffect(function() {
        if (localStorage.getItem("retrieveMultiLines")) {
            setPartyPrivacyItems(mapData());
        }
    }, [
        localStorage.getItem("retrieveMultiLines")
    ]);
    return {
        partyPrivacyItems: partyPrivacyItems
    };
};
