import { nextClient } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { useQuery, useQueryClient } from "react-query";
var getCampaignDetailsService = function(campaignId, audienceId) {
    return nextClient.get("".concat(API.CAMPAIGN, "/").concat(campaignId), {
        searchParams: {
            salesChannel: "selfService",
            audienceType: "SUBSCRIBER_ID",
            audienceId: audienceId
        }
    });
};
export var useGetCampaignDetails = function(campaignId, queryEnabler) {
    var queryClient = useQueryClient();
    return useQuery([
        "getCampaignDetails"
    ], function() {
        var ref;
        var getSubscriberByResource = queryClient.getQueryData("getSubscriberByResource");
        var audienceId = (getSubscriberByResource === null || getSubscriberByResource === void 0 ? void 0 : (ref = getSubscriberByResource[0]) === null || ref === void 0 ? void 0 : ref.subscriberId) || "";
        return getCampaignDetailsService(campaignId, audienceId);
    }, {
        enabled: queryEnabler || false
    });
};
