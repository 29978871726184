import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
/**
 * @param payload
 * @param customOptions
 * @returns
 */ var simTypeSelectionService = function(payload, customOptions) {
    return nextClient.post(API.SIM_TYPE, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.ignoreUpdateToken) && {
        ignoreUpdateToken: true
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * @returns
 */ export var useSimTypeSelectionService = function() {
    var queryClient = useQueryClient();
    return useMutation("simSelection", function(param) {
        var cartId = param.cartId;
        var id = cartId && cartId.substring(0, cartId.length - 1);
        var payload = {
            externalRefernce: cartId,
            id: id
        };
        return simTypeSelectionService(payload, {
            ignoreUpdateToken: true
        });
    }, {
        onSuccess: function(data) {
            localStorage.setItem("simSelection", JSON.stringify(data));
            queryClient.setQueryData("simSelection", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR on simSelection: ", error);
            localStorage.setItem("simSelection", JSON.stringify(error));
        }
    });
};
