import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
/**
 * Available Customer Types
 */ export var CUSTOMER_TYPES = {
    IN_MIGRATION: "inmigration",
    LEGACY: "legacy",
    NEXT: "next",
    ANONYMOUS: "anonymous"
};
/**
 * This method is used before the findCaller service to determine what is the user type.
 * API  commerce/v1/coexistenceuser.
 * Method : GET
 * QueryString Params: salesChannel=selfService
 * @returns
 */ var coexistenceUserService = function(customOptions) {
    return nextClient.get(API.COEXISTENCE_USER, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the coexintenceUserService
 * @param coexistenceEnabler
 * @param customOptions
 * @returns
 */ export var useCoexistenceUser = function(coexistenceEnabler, customOptions) {
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "coexistenceUser";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return coexistenceUserService();
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: coexistenceEnabler,
            onSuccess: function(data) {
                if (data.userType === CUSTOMER_TYPES.NEXT || CUSTOMER_TYPES.ANONYMOUS || CUSTOMER_TYPES.LEGACY) {
                    // todo continue calling GET findCaller
                    localStorage.setItem("coexistenceUser", JSON.stringify(data));
                }
                if (data.userType === CUSTOMER_TYPES.IN_MIGRATION) {
                // todo I do not know what to do
                }
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - coexistenceUser: ", error);
                localStorage.setItem("coexistenceUser", JSON.stringify(errorMock("coexistenceUser", error)));
            }
        })
    };
    return handleUseQuery(options);
};
