import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { useMutation, useQueryClient } from "react-query";
import { errorMock, isMobileProduct } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * reserveEquipmentService method to reserve the router modem
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ export var reserveEquipmentService = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.RESERVE_EQUIPMENT), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This is a custom hook to manage the reserveEquipmentService.
 * //TODO Rework
 * @returns
 */ export var useReserveEquipmentMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("reserveEquipmentMutation", function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var cartType = isMobileProduct() ? shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.device) === null || ref2 === void 0 ? void 0 : ref2.type : shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref3 = shoppingCart.cartItem) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.type;
        var deviceOfferingId = isMobileProduct() ? shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref5 = shoppingCart.cartItem) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.device) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.offering) === null || ref8 === void 0 ? void 0 : ref8.id : shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref9 = shoppingCart.cartItem) === null || ref9 === void 0 ? void 0 : (ref10 = ref9[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.offering) === null || ref11 === void 0 ? void 0 : ref11.id;
        var payload = {
            cartType: cartType,
            deviceOfferingId: deviceOfferingId
        };
        return reserveEquipmentService(cartId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("reserveEquipment", JSON.stringify(data));
            queryClient.setQueryData("reserveEquipment", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - reserveEquipment: ", error);
            queryClient.setQueryData("reserveEquipment", errorMock("reserveEquipment", error));
            localStorage.setItem("reserveEquipment", JSON.stringify(errorMock("reserveEquipment", error)));
        }
    });
};
