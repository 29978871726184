import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useMutation, useQueryClient } from "react-query";
import { errorMock, getCustomerCountry, getCustomerDocument, getCustomerIdentification } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
/**
 * This method calls the service that creates the customer.
 * Method: POST
 * @param req
 * @param cfData
 * @param customOptions
 */ var createCustomer = function(req, cfData, customOptions) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    var day = cfData && cfData.day > 9 ? cfData.day : "0".concat(cfData.day);
    var month = cfData && cfData.month > 9 ? cfData.month : "0".concat(cfData.month);
    var year = cfData && cfData.year;
    var gender = cfData && cfData.gender;
    var identification = getCustomerIdentification(req.owningIndividual);
    var payload = {
        owningIndividual: {
            nation: req.address.country,
            fiscalCode: req.owningIndividual.fiscalCode.toUpperCase(),
            firstName: req.owningIndividual.firstName,
            lastName: req.owningIndividual.lastName,
            identification: [
                {
                    identificationType: req === null || req === void 0 ? void 0 : (ref = req.documentObj) === null || ref === void 0 ? void 0 : ref.id,
                    nationality: req === null || req === void 0 ? void 0 : (ref1 = req.countryObj) === null || ref1 === void 0 ? void 0 : ref1.id,
                    identificationNumber: identification === null || identification === void 0 ? void 0 : identification.identificationNumber,
                    expirationDate: identification === null || identification === void 0 ? void 0 : identification.expirationDate.split("/").reverse().join("-")
                }, 
            ],
            birthDate: "".concat(year, "-").concat(month, "-").concat(day),
            birthPlace: req.owningIndividual.fiscalCode.substring(11, 15).toUpperCase(),
            gender: gender === "M" ? "male" : "female",
            contactMedium: _to_consumable_array(req.owningIndividual.contactMedium).concat([
                _object_spread({
                    type: "postalAddress",
                    preferred: false,
                    city: req.address.city,
                    country: req.address.country,
                    postalCode: req.address.postalCode,
                    streetNumber: req.address.streetNumber,
                    externalId: req.address.externalId,
                    stateOrProvince: req.address.stateOrProvince,
                    street: req.address.street,
                    istatCode: req.address.istatCode,
                    homeZone: req.address.homeZone
                }, ((ref2 = req.address) === null || ref2 === void 0 ? void 0 : ref2.latitude) && {
                    latitude: ((ref3 = req.address.latitude) === null || ref3 === void 0 ? void 0 : ref3.toString()) || ""
                }, ((ref4 = req.address) === null || ref4 === void 0 ? void 0 : ref4.longitude) && {
                    longitude: ((ref5 = req.address.longitude) === null || ref5 === void 0 ? void 0 : ref5.toString()) || ""
                }), 
            ]),
            preferredContactMethod: {
                name: "C"
            },
            preferredContactTime: {
                name: "Morning"
            },
            preferredLanguage: {
                name: "it-IT"
            }
        },
        displayBirthDate: "".concat(day, "/").concat(month, "/").concat(year),
        displayGender: gender === "M" ? "Maschio" : "Femmina",
        subtype: "I",
        type: "individual",
        marketTag: "VF"
    };
    LoggerInstance.debug(payload, "HERE CREATE CUSTOMER PAYLOAD");
    return nextClient.post(API.CUSTOMER, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run createCustomer using the createCustomer, the customerData and the shoppingCart.
 * Call this after the customer and the shoppingCart have just been created.
 * @returns
 */ export var useCustomer = function() {
    var queryClient = useQueryClient();
    return useMutation([
        "creationCustomer"
    ], function(data) {
        var ref;
        var owningData = data.owningData, cfInverse = data.cfInverse;
        var serviceability = getFromLocalStorageByKey("offeringServiceability");
        var location = getFromLocalStorageByKey("createdAddress");
        var selectedCountry = getCustomerCountry(owningData);
        var selectedDocument = getCustomerDocument(owningData);
        var req = {
            owningIndividual: _object_spread_props(_object_spread({}, owningData.owningIndividual), {
                nation: (selectedCountry === null || selectedCountry === void 0 ? void 0 : (ref = selectedCountry[0]) === null || ref === void 0 ? void 0 : ref.displayName) || ""
            }),
            address: _object_spread({}, (serviceability === null || serviceability === void 0 ? void 0 : serviceability.serviceabilityAddress) || location),
            countryObj: selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry[0],
            documentObj: selectedDocument === null || selectedDocument === void 0 ? void 0 : selectedDocument[0]
        };
        return createCustomer(req, cfInverse);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("postCustomer", JSON.stringify(data));
            queryClient.setQueryData("postCustomer", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - postCustomer: ", error);
            queryClient.setQueryData("postCustomer", error);
            localStorage.setItem("postCustomer", JSON.stringify(errorMock("postCustomer", error)));
        }
    });
};
