import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
/**
 * Method to POST campaign accept api service
 * @param payload
 * @param campaignId
 * @param customOptions
 */ var campaignAcceptService = function(payload, campaignId, customOptions) {
    return nextClient.post("".concat(API.CAMPAIGN, "/").concat(campaignId, "/").concat(API.ACCEPT), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method confirm the campaign order
 * @returns
 */ export var useCampaignAccept = function() {
    var queryClient = useQueryClient();
    return useMutation([
        "campaignAccept"
    ], function(param) {
        var campaignId = param.campaignId, sessionId = param.sessionId;
        var ref, ref1, ref2, ref3, ref4;
        var customerData = getFromLocalStorageByKey("customerData");
        var findCaller = getFromLocalStorageByKey("findCaller");
        var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref1 = findCaller.customerRef) === null || ref1 === void 0 ? void 0 : ref1.id);
        var createdAddress = getFromLocalStorageByKey("createdAddress");
        var installationAddressId = createdAddress === null || createdAddress === void 0 ? void 0 : createdAddress.id;
        var offeringServiceability = getFromLocalStorageByKey("offeringServiceability");
        var serviceabilityId = offeringServiceability === null || offeringServiceability === void 0 ? void 0 : offeringServiceability.id;
        var vtvIndicator = (ref4 = (ref2 = offeringServiceability) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.serviceabilityCharacteristic) === null || ref3 === void 0 ? void 0 : ref3.find(function(item) {
            return (item === null || item === void 0 ? void 0 : item.displayName.toLowerCase()) === "vtv";
        })) === null || ref4 === void 0 ? void 0 : ref4.displayValue;
        var payload = {
            audienceType: "CUSTOMER_ID",
            audienceId: customerId,
            campaignId: campaignId,
            sessionId: sessionId,
            vtvIndicator: vtvIndicator,
            installationAddressId: installationAddressId,
            serviceabilityId: serviceabilityId
        };
        return campaignAcceptService(payload, campaignId);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("campaignAccept", JSON.stringify(data));
            queryClient.setQueryData("campaignAccept", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - campaignAccept: ", error);
            localStorage.setItem("campaignAccept", JSON.stringify(errorMock("campaignAccept", error)));
        }
    });
};
