import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 20px ",
        "px 20px;\n\n  @media (min-width: ",
        ") {\n    padding: 60px 40px ",
        "px 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 60px 55px ",
        "px 55px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  background-color: transparent;\n  padding: 0;\n  margin: 0;\n  border: none;\n  border-bottom: 1px solid ",
        ";\n  margin-top: 16px;\n  color: ",
        ";\n  font-weight: 700;\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    margin-top: 12px;\n  }\n\n  &:hover {\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  text-align: start;\n  color: ",
        ";\n  margin-top: 20px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-top: 36px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 44px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    padding: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-top: 32px;\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  margin-top: 32px;\n\n  .button-slide-container:last-of-type {\n    margin-top: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 328px;\n    margin: 40px auto;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  font-family: 'Vodafone Rg', sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px;\n  margin-top: 8px;\n\n  @media (max-width: ",
        ") {\n    text-align: center;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin-top: 16px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 12px;\n  }\n\n  div {\n    text-align: left;\n\n    &:first-child {\n      margin-bottom: 4px;\n    }\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  font-family: 'Vodafone Rg', sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 100%;\n  margin: 20px 10px 0 0;\n\n  @media (min-width: ",
        ") and (max-width: ",
        ") {\n    margin-top: 2px;\n  }\n\n  span {\n    font-family: 'Vodafone Rg', sans-serif;\n    color: ",
        ";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 18px;\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-6d0cbdd5-0"
})(_templateObject(), function(props) {
    return props.isMultipleOperators ? 32 : 70;
}, breakpoints.tablet, function(props) {
    return props.isMultipleOperators ? 64 : 207;
}, breakpoints.desktop, function(props) {
    return props.isMultipleOperators ? 64 : 242;
});
export var Paragraph = styled.p.withConfig({
    componentId: "sc-6d0cbdd5-1"
})(_templateObject1(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-6d0cbdd5-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var MigrationCodeCta = styled.button.withConfig({
    componentId: "sc-6d0cbdd5-3"
})(_templateObject3(), colors.$262626, colors.$262626, fonts.regular, pxToCssFont(16, 20), breakpoints.tablet);
export var OtherInformation = styled(Paragraph).withConfig({
    componentId: "sc-6d0cbdd5-4"
})(_templateObject4(), fonts.regular, pxToCssFont(12, 16), colors.$7e7e7e, breakpoints.tablet, pxToCssFont(14, 18), breakpoints.tablet);
export var MigrationModalContainer = styled.div.withConfig({
    componentId: "sc-6d0cbdd5-5"
})(_templateObject5(), breakpoints.tablet);
export var ModalTitle = styled(Title).withConfig({
    componentId: "sc-6d0cbdd5-6"
})(_templateObject6(), breakpoints.tablet, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalDescription = styled(Paragraph).withConfig({
    componentId: "sc-6d0cbdd5-7"
})(_templateObject7(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(18, 24));
export var CtaContainer = styled.div.withConfig({
    componentId: "sc-6d0cbdd5-8"
})(_templateObject8(), breakpoints.tablet);
export var MigrationLabel = styled(Paragraph).withConfig({
    componentId: "sc-6d0cbdd5-9"
})(_templateObject9(), breakpoints.tablet);
export var LinkWithIconStyle = styled.div.withConfig({
    componentId: "sc-6d0cbdd5-10"
})(_templateObject10(), breakpoints.tablet);
export var findmigrationlabel = styled.div.withConfig({
    componentId: "sc-6d0cbdd5-11"
})(_templateObject11(), colors.$262626);
export var CheckboxDiv = styled.div.withConfig({
    componentId: "sc-6d0cbdd5-12"
})(_templateObject12(), breakpoints.tablet, breakpoints.desktop, colors.$262626);
