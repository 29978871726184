import { awsConsumerClient, handleEnv, LoggerInstance } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { useMutation, useQueryClient } from "react-query";
import { errorMock, MOCK_DXL_MFA_TOKEN, retrieveUserAgent } from "@vfit/consumer/data-access";
var postLoginV2 = function(username, password, deviceId) {
    return awsConsumerClient.post("".concat(API.DXL_LOGIN_BY_USER_AND_PASS_V2), {
        username: username,
        password: password
    }, {
        headers: {
            "X-Device-UserAgent": retrieveUserAgent(),
            "X-Device-Id": deviceId
        }
    });
};
export var usePostLoginV2 = function() {
    var queryClient = useQueryClient();
    return useMutation("loginByUserAndPass", function(param) {
        var username = param.username, password = param.password, deviceId = param.deviceId;
        sessionStorage.removeItem("dxlJwt");
        return postLoginV2(username, password, deviceId);
    }, {
        onSuccess: function(data) {
            queryClient.setQueryData("loginByUserAndPass", data);
        },
        onError: function(error) {
            var isDevLocal = handleEnv("NODE_ENV") === "LOCAL" || handleEnv("NODE_ENV") === "development";
            var jwtMFA = error.headers.get("vf-a-appconsumermfa");
            if (jwtMFA || isDevLocal) {
                sessionStorage.setItem("dxlJwtMfa", isDevLocal ? MOCK_DXL_MFA_TOKEN : jwtMFA);
            }
            LoggerInstance.error("ERROR - loginByUserAndPass", error);
            queryClient.setQueryData("loginByUserAndPass", errorMock("loginByUserAndPass"));
        }
    });
};
