var organizePortabilityCard = function(portabilityCardCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19;
    return {
        title: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref = portabilityCardCms.portability) === null || ref === void 0 ? void 0 : (ref1 = ref.general) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref2 = portabilityCardCms.portability) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.general) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        items: [
            {
                title: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref4 = portabilityCardCms.portability) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.general) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.choicePortability) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.option1) === null || ref7 === void 0 ? void 0 : ref7.title) || "S\xec",
                description: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref8 = portabilityCardCms.portability) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.general) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.choicePortability) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.option1) === null || ref11 === void 0 ? void 0 : ref11.description) || "Ci occuperemo noi di disattivare la linea del tuo vecchio operatore e attivare la nuova.",
                value: "yes"
            },
            {
                title: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref12 = portabilityCardCms.portability) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.general) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.choicePortability) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.option2) === null || ref15 === void 0 ? void 0 : ref15.title) || "No",
                description: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref16 = portabilityCardCms.portability) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.general) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.choicePortability) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.option1) === null || ref19 === void 0 ? void 0 : ref19.description) || "Ricordati che dovrai provvedere tu alla disattivazione della linea del tuo vecchio operatore.",
                value: "no"
            }, 
        ]
    };
};
export { organizePortabilityCard };
