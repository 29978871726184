import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
/**
 * associateCustomerService is a method to associate the customer to the created ShoppingCart
 * Method: POST
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ var associateCustomerService = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.SHOPPING_CART, "/").concat(cartId).concat(API.ASSOCIATE_CUSTOMER), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
export var useAssociateCustomer = function() {
    var queryClient = useQueryClient();
    return useMutation([
        "associateCustomer"
    ], // eslint-disable-next-line consistent-return
    function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14;
        var address = getFromLocalStorageByKey("createdAddress");
        var findCaller = getFromLocalStorageByKey("findCaller");
        var customerData = getFromLocalStorageByKey("customerData");
        var fiscalCode = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.fiscalCode;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id) || "";
        return associateCustomerService(cartId, {
            customerId: (customerData === null || customerData === void 0 ? void 0 : (ref2 = customerData[0]) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id),
            fiscalCode: fiscalCode || (findCaller === null || findCaller === void 0 ? void 0 : (ref4 = findCaller.individualRef) === null || ref4 === void 0 ? void 0 : ref4.fiscalCode),
            isLegacyCustomer: !(findCaller === null || findCaller === void 0 ? void 0 : findCaller.customerSetup),
            contactId: (customerData === null || customerData === void 0 ? void 0 : (ref5 = customerData[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.owningIndividual) === null || ref6 === void 0 ? void 0 : ref6.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref7 = findCaller.individualRef) === null || ref7 === void 0 ? void 0 : ref7.contactId),
            productId: shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref8 = shoppingCart.cartItem) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[0]) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.product) === null || ref10 === void 0 ? void 0 : ref10.id,
            isCompatibilityRulesRequired: true,
            ruleActivityName: "SetProductUser",
            orderActions: [
                {
                    orderActionId: shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref11 = shoppingCart.cartItem) === null || ref11 === void 0 ? void 0 : (ref12 = ref11[0]) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.internet) === null || ref13 === void 0 ? void 0 : (ref14 = ref13[0]) === null || ref14 === void 0 ? void 0 : ref14.id
                }
            ],
            installationAddress: {
                id: address === null || address === void 0 ? void 0 : address.id
            }
        });
    }, {
        onSuccess: function(data) {
            localStorage.setItem("associateCustomer", JSON.stringify(data));
            queryClient.setQueryData("associateCustomer", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - associateCustomer: ", error);
            queryClient.setQueryData("associateCustomer", errorMock("associateCustomer", error));
            localStorage.setItem("associateCustomer", JSON.stringify(errorMock("associateCustomer", error)));
        }
    });
};
