import { useEffect, useState } from "react";
import { checkWindow } from "@vfit/shared/data-access";
import { ENRG_QSTRING } from "@vfit/consumer/data-access";
import { errorManager, ErrorService } from "@vfit/shared/providers";
export function usePostAppOfferFlow() {
    var ref = useState(true), isLoading = ref[0], setIsLoading = ref[1];
    var ref1 = useState(false), isEligible = ref1[0], setIsEligible = ref1[1];
    var getPostData = function() {
        var ref, ref1, ref2, ref3;
        var queryString = new URLSearchParams(window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search);
        var m = (ref1 = queryString === null || queryString === void 0 ? void 0 : queryString.get(ENRG_QSTRING.MSISDN)) === null || ref1 === void 0 ? void 0 : ref1.replace(/ /g, "+");
        var a = (ref2 = queryString === null || queryString === void 0 ? void 0 : queryString.get(ENRG_QSTRING.ACCOUNT)) === null || ref2 === void 0 ? void 0 : ref2.replace(/ /g, "+");
        var c = (ref3 = queryString === null || queryString === void 0 ? void 0 : queryString.get(ENRG_QSTRING.CHECKSUM)) === null || ref3 === void 0 ? void 0 : ref3.replace(/ /g, "+");
        return {
            msisdn: m,
            account: a,
            checksum: c
        };
    };
    var manageErrorPostAppOffer = function() {
        errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
            title: "Ops",
            message: "Si \xe8 verificato un problema. Riprovare in seguito",
            actionText: "Chiudi",
            fullscreen: true,
            disableCloseModalOnClickAction: true
        });
    };
    var checkIsPostOffer = function() {
        if (!checkWindow()) {
            return;
        }
        var ref = getPostData(), msisdn = ref.msisdn, account = ref.account, checksum = ref.checksum;
        if (!msisdn || !account || !checksum) {
            manageErrorPostAppOffer();
            setIsEligible(false);
            setIsLoading(false);
        } else {
            setIsEligible(true);
            setIsLoading(false);
        }
    };
    useEffect(function() {
        checkIsPostOffer();
    }, []);
    return {
        isLoading: isLoading,
        isEligible: isEligible,
        getPostData: getPostData
    };
}
