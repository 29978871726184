import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
export function usePayMeanData() {
    var queryClient = useQueryClient();
    var ref = useState(undefined), payMean = ref[0], setPayMean = ref[1];
    var payMeanData = queryClient.getQueryData("payMean");
    useEffect(function() {
        if (payMeanData) {
            setPayMean(payMeanData);
        }
    }, [
        !!payMeanData
    ]);
    return {
        payMean: payMean
    };
}
