import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { ErrorCodes, errorMock } from "@vfit/consumer/data-access";
import { retrieveProvider } from "./portabilityNumber.utils";
/**
 * API service to validate the migration code
 * @param payload
 * @param customOptions
 */ export var checkPortInFeasibilityIIService = function(payload, customOptions) {
    return nextClient.post(API.CHECK_IN_PORT, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Mutation for saving into the key 'checkPortInFeasibilityIIQuery'
 * the response coming from the API service useCheckPortInFeasibilityII
 * to validate the migration code
 * @returns
 * @deprecated
 */ export var useCheckPortInFeasibilityII = function() {
    var queryClient = useQueryClient();
    return useMutation("checkPortInFeasibilityII", function(data) {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
        var values = data.values, providers = data.providers;
        // Get createdAddress obj from localstorage
        var createdAddressSaved = localStorage.getItem("createdAddress");
        var createdAddress = !!createdAddressSaved && JSON.parse(createdAddressSaved);
        var shoppingCartSaved = localStorage.getItem("shoppingCart");
        var shoppingCart = !!shoppingCartSaved && shoppingCartSaved !== "undefined" && JSON.parse(shoppingCartSaved);
        var fixedVoiceOrderActionId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.fixedVoice) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.id;
        var fixedVoiceEgonAddressId = createdAddress === null || createdAddress === void 0 ? void 0 : createdAddress.externalId;
        var fixedVoiceIstatCode = createdAddress === null || createdAddress === void 0 ? void 0 : createdAddress.istatCode;
        var customerSaved = localStorage.getItem("customerData");
        var customerData = !!customerSaved && customerSaved !== "undefined" && JSON.parse(customerSaved);
        var firstName;
        var lastName;
        var fiscalCode;
        if (customerData === null || customerData === void 0 ? void 0 : (ref4 = customerData[0]) === null || ref4 === void 0 ? void 0 : ref4.owningIndividual) {
            var ref11, ref12, ref13, ref14, ref15, ref16;
            firstName = customerData === null || customerData === void 0 ? void 0 : (ref11 = customerData[0]) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.owningIndividual) === null || ref12 === void 0 ? void 0 : ref12.firstName;
            lastName = customerData === null || customerData === void 0 ? void 0 : (ref13 = customerData[0]) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.owningIndividual) === null || ref14 === void 0 ? void 0 : ref14.lastName;
            fiscalCode = customerData === null || customerData === void 0 ? void 0 : (ref15 = customerData[0]) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.owningIndividual) === null || ref16 === void 0 ? void 0 : ref16.fiscalCode;
        }
        var multiPlayOfferingConfigurationSaved = localStorage.getItem("multiPlayOfferingConfiguration");
        var multiPlayOfferingConfiguration = !!multiPlayOfferingConfigurationSaved && multiPlayOfferingConfigurationSaved !== "undefined" && JSON.parse(multiPlayOfferingConfigurationSaved);
        var firstServiceProviders = !!multiPlayOfferingConfiguration && ((ref6 = multiPlayOfferingConfiguration === null || multiPlayOfferingConfiguration === void 0 ? void 0 : (ref5 = multiPlayOfferingConfiguration.fixedVoicePortInDetails) === null || ref5 === void 0 ? void 0 : ref5[0].r1MissingMigrationFixedPortInSpecification) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.r1FirstDonatingNetworkOperator) === null || ref7 === void 0 ? void 0 : ref7.availableValues);
        var secondServiceProviders = !!multiPlayOfferingConfiguration && ((ref9 = multiPlayOfferingConfiguration === null || multiPlayOfferingConfiguration === void 0 ? void 0 : (ref8 = multiPlayOfferingConfiguration.fixedVoicePortInDetails) === null || ref8 === void 0 ? void 0 : ref8[0].r1MissingMigrationFixedPortInSpecification) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.r1SecondDonatingNetworkOperator) === null || ref10 === void 0 ? void 0 : ref10.availableValues);
        var firstProvider = firstServiceProviders && retrieveProvider(firstServiceProviders, providers.firstSelectedProvider);
        var secondProvider = secondServiceProviders && retrieveProvider(secondServiceProviders, providers.secondSelectedProvider);
        var payload = {
            portInDetails: [
                {
                    portInDetailsFixed: _object_spread(_object_spread_props(_object_spread({}, values), {
                        firstDonatingServiceProviderId: firstProvider,
                        firstDonatingServiceProvider: firstProvider
                    }), (values === null || values === void 0 ? void 0 : values.hasInternetService) && (providers === null || providers === void 0 ? void 0 : providers.secondSelectedProvider) && {
                        secondDonatingServiceProviderId: secondProvider,
                        secondDonatingServiceProvider: secondProvider
                    }),
                    fixedVoiceOrderActionId: fixedVoiceOrderActionId,
                    fixedVoiceEgonAddressId: fixedVoiceEgonAddressId,
                    fixedVoiceIstatCode: fixedVoiceIstatCode,
                    flowType: "Fixed",
                    orderActionId: fixedVoiceOrderActionId,
                    customerInfo: {
                        firstName: firstName,
                        lastName: lastName,
                        fiscalCode: fiscalCode
                    }
                }, 
            ]
        };
        localStorage.setItem("checkPortInFeasibilityIIPayload", JSON.stringify(payload));
        queryClient.setQueryData("checkPortInFeasibilityIIPayload", payload);
        return checkPortInFeasibilityIIService(payload);
    }, {
        onSuccess: function(data) {
            var ref;
            // TODO Check if "resultDescription": "Failure" return error
            if ((data === null || data === void 0 ? void 0 : (ref = data.resultDescription) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "failure") {
                var ref1;
                var errorMessage;
                if (data === null || data === void 0 ? void 0 : (ref1 = data.resultMessages) === null || ref1 === void 0 ? void 0 : ref1[0]) {
                    var ref2, ref3, ref4, ref5, ref6, ref7;
                    if ((data === null || data === void 0 ? void 0 : (ref2 = data.resultMessages) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.messageCode) === "9000054") {
                        errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
                    } else if ((data === null || data === void 0 ? void 0 : (ref4 = data.resultMessages) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.messageCode) === "9000352") {
                        errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
                    } else if (data === null || data === void 0 ? void 0 : (ref6 = data.resultMessages) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : ref7.messageDescription) {
                        errorMessage = ErrorCodes.NUMBER_ERROR;
                    } else {
                        // not mapped error on checkPortInFeasibility if "resultDescription": "Failure"
                        errorMessage = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
                    }
                    var error = errorMock("checkPortInFeasibilityII", {
                        status: "200",
                        statusText: "200",
                        url: API.CHECK_IN_PORT
                    }, errorMessage);
                    queryClient.setQueryData("checkPortInFeasibilityIIQuery", error);
                    localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(error));
                }
                var error1 = errorMock("checkPortInFeasibilityIIQuery", {
                    status: "200",
                    statusText: "200",
                    url: API.CHECK_IN_PORT
                }, errorMessage);
                queryClient.setQueryData("checkPortInFeasibilityIIQuery", error1);
                localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(error1));
            } else {
                // Success save checkPortInFeasibilityIIQuery object into storage
                localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(data));
                queryClient.setQueryData("checkPortInFeasibilityIIQuery", data);
            }
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - checkPortInFeasibilityIIQuery: ", error);
            queryClient.setQueryData("checkPortInFeasibilityIIQuery", errorMock("checkPortInFeasibilityII"));
            localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(errorMock("checkPortInFeasibilityII")));
        }
    });
};
export var useCheckPortInFeasibilityIIFixed = function() {
    var queryClient = useQueryClient();
    return useMutation("checkPortInFeasibilityII", function(param) {
        var isMultipleOperator = param.isMultipleOperator, hasInternetService = param.hasInternetService, portabilityNumber = param.portabilityNumber, portabilityMigration = param.portabilityMigration;
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17;
        var createdAddress = getFromLocalStorageByKey("createdAddress");
        var customerData = getFromLocalStorageByKey("customerData");
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var multiPlayOfferingConfiguration = getFromLocalStorageByKey("multiPlayOfferingConfiguration");
        var fixedVoiceOrderActionId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.fixedVoice) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.id;
        var fixedVoiceEgonAddressId = createdAddress === null || createdAddress === void 0 ? void 0 : createdAddress.externalId;
        var fixedVoiceIstatCode = createdAddress === null || createdAddress === void 0 ? void 0 : createdAddress.istatCode;
        var firstName = (customerData === null || customerData === void 0 ? void 0 : (ref4 = customerData[0]) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.owningIndividual) === null || ref5 === void 0 ? void 0 : ref5.firstName) || "";
        var lastName = (customerData === null || customerData === void 0 ? void 0 : (ref6 = customerData[0]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.owningIndividual) === null || ref7 === void 0 ? void 0 : ref7.lastName) || "";
        var fiscalCode = (customerData === null || customerData === void 0 ? void 0 : (ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.owningIndividual) === null || ref9 === void 0 ? void 0 : ref9.fiscalCode) || "";
        var firstServiceProviders = multiPlayOfferingConfiguration === null || multiPlayOfferingConfiguration === void 0 ? void 0 : (ref10 = multiPlayOfferingConfiguration.fixedVoicePortInDetails) === null || ref10 === void 0 ? void 0 : (ref11 = ref10[0]) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.r1MissingMigrationFixedPortInSpecification) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.r1FirstDonatingNetworkOperator) === null || ref13 === void 0 ? void 0 : ref13.availableValues;
        var secondServiceProviders = multiPlayOfferingConfiguration === null || multiPlayOfferingConfiguration === void 0 ? void 0 : (ref14 = multiPlayOfferingConfiguration.fixedVoicePortInDetails) === null || ref14 === void 0 ? void 0 : (ref15 = ref14[0]) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.r1MissingMigrationFixedPortInSpecification) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.r1SecondDonatingNetworkOperator) === null || ref17 === void 0 ? void 0 : ref17.availableValues;
        // This values is not used
        // const isMultipleOperator =
        //   multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]?.r1MissingMigrationFixedPortInSpecification?.r1VoiceAndInternetDifferentProvider?.toLowerCase() ===
        //     'yes' || false;
        // const hasInternetService =
        //   multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
        //     ?.r1MissingMigrationFixedPortInSpecification?.r1IsInternetIndicator === 'Yes';
        var providers = _object_spread({
            firstSelectedProvider: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstOperator
        }, hasInternetService && isMultipleOperator ? {
            secondSelectedProvider: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.secondOperator
        } : hasInternetService && !isMultipleOperator ? {
            secondSelectedProvider: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstOperator
        } : {});
        var firstProvider = firstServiceProviders && retrieveProvider(firstServiceProviders, providers.firstSelectedProvider);
        var secondProvider = secondServiceProviders && retrieveProvider(secondServiceProviders, providers.secondSelectedProvider);
        var payload = {
            portInDetails: [
                {
                    portInDetailsFixed: _object_spread(_object_spread_props(_object_spread({
                        cli: portabilityNumber,
                        hasInternetService: hasInternetService ? "Yes" : "No",
                        firstMigrationCode: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstCode
                    }, isMultipleOperator && {
                        secondMigrationCode: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.secondCode
                    }), {
                        firstDonatingServiceProviderId: firstProvider,
                        firstDonatingServiceProvider: firstProvider
                    }), hasInternetService && (providers === null || providers === void 0 ? void 0 : providers.secondSelectedProvider) && {
                        secondDonatingServiceProviderId: secondProvider,
                        secondDonatingServiceProvider: secondProvider
                    }),
                    fixedVoiceOrderActionId: fixedVoiceOrderActionId,
                    fixedVoiceEgonAddressId: fixedVoiceEgonAddressId,
                    fixedVoiceIstatCode: fixedVoiceIstatCode,
                    flowType: "Fixed",
                    orderActionId: fixedVoiceOrderActionId,
                    customerInfo: {
                        firstName: firstName,
                        lastName: lastName,
                        fiscalCode: fiscalCode
                    }
                }, 
            ]
        };
        return checkPortInFeasibilityIIService(payload);
    }, {
        onSuccess: function(data) {
            var ref;
            if ((data === null || data === void 0 ? void 0 : (ref = data.resultDescription) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "failure") {
                var ref1;
                var errorMessage;
                if (data === null || data === void 0 ? void 0 : (ref1 = data.resultMessages) === null || ref1 === void 0 ? void 0 : ref1[0]) {
                    var ref2, ref3, ref4, ref5, ref6, ref7;
                    if ((data === null || data === void 0 ? void 0 : (ref2 = data.resultMessages) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.messageCode) === "9000054") {
                        errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
                    } else if ((data === null || data === void 0 ? void 0 : (ref4 = data.resultMessages) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.messageCode) === "9000352") {
                        errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
                    } else if (data === null || data === void 0 ? void 0 : (ref6 = data.resultMessages) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : ref7.messageDescription) {
                        errorMessage = ErrorCodes.NUMBER_ERROR;
                    } else {
                        // not mapped error on checkPortInFeasibility if "resultDescription": "Failure"
                        errorMessage = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
                    }
                    var error = errorMock("checkPortInFeasibilityII", {
                        status: "200",
                        statusText: "200",
                        url: API.CHECK_IN_PORT
                    }, errorMessage);
                    queryClient.setQueryData("checkPortInFeasibilityIIQuery", error);
                    localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(error));
                }
                var error1 = errorMock("checkPortInFeasibilityIIQuery", {
                    status: "200",
                    statusText: "200",
                    url: API.CHECK_IN_PORT
                }, errorMessage);
                queryClient.setQueryData("checkPortInFeasibilityIIQuery", error1);
                localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(error1));
            } else {
                // Success save checkPortInFeasibilityIIQuery object into storage
                localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(data));
                queryClient.setQueryData("checkPortInFeasibilityIIQuery", data);
            }
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - checkPortInFeasibilityIIQuery: ", error);
            queryClient.setQueryData("checkPortInFeasibilityIIQuery", errorMock("checkPortInFeasibilityII"));
            localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(errorMock("checkPortInFeasibilityII")));
        }
    });
};
export var useCheckPortInFeasibilityIIMobile = function() {
    var queryClient = useQueryClient();
    return useMutation("checkPortInFeasibilityII", function(param) {
        var msisdn = param.msisdn, isSimAvailable = param.isSimAvailable, simCardNumber = param.simCardNumber, currentPlanType = param.currentPlanType, currentServiceProviderId = param.currentServiceProviderId, currentServiceProviderIdName = param.currentServiceProviderIdName;
        var ref, ref1, ref2;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var customerData = getFromLocalStorageByKey("customerData");
        var firstName;
        var lastName;
        var fiscalCode;
        if (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.owningIndividual) {
            var ref3, ref4, ref5, ref6, ref7, ref8;
            firstName = customerData === null || customerData === void 0 ? void 0 : (ref3 = customerData[0]) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.owningIndividual) === null || ref4 === void 0 ? void 0 : ref4.firstName;
            lastName = customerData === null || customerData === void 0 ? void 0 : (ref5 = customerData[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.owningIndividual) === null || ref6 === void 0 ? void 0 : ref6.lastName;
            fiscalCode = customerData === null || customerData === void 0 ? void 0 : (ref7 = customerData[0]) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.owningIndividual) === null || ref8 === void 0 ? void 0 : ref8.fiscalCode;
        }
        var payload = {
            portInDetails: [
                {
                    orderActionId: shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref1 = shoppingCart.cartItem) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.id,
                    msisdn: msisdn,
                    isSimAvailable: isSimAvailable,
                    simCardNumber: simCardNumber || "",
                    currentPlanType: currentPlanType,
                    currentServiceProviderId: currentServiceProviderId,
                    currentServiceProviderIdName: currentServiceProviderIdName,
                    flowType: "Mobile",
                    customerInfo: {
                        firstName: firstName,
                        lastName: lastName,
                        fiscalCode: fiscalCode
                    }
                }, 
            ]
        };
        localStorage.setItem("checkPortInFeasibilityIIPayload", JSON.stringify(payload));
        queryClient.setQueryData("checkPortInFeasibilityIIPayload", payload);
        return checkPortInFeasibilityIIService(payload);
    }, {
        onSuccess: function(data) {
            var ref, ref1;
            // TODO Check if "resultDescription": "Failure" return error
            if (((ref = data) === null || ref === void 0 ? void 0 : (ref1 = ref.resultDescription) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) === "failure") {
                var ref2, ref3;
                var errorMessage;
                if ((ref2 = data) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.resultMessages) === null || ref3 === void 0 ? void 0 : ref3[0]) {
                    var ref4, ref5, ref6, ref7, ref8, ref9;
                    if (((ref4 = data) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.resultMessages) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : ref6.messageCode) === "9000054") {
                        errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
                    } else if ((ref7 = data) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.resultMessages) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[0]) === null || ref9 === void 0 ? void 0 : ref9.messageDescription) {
                        errorMessage = ErrorCodes.NUMBER_ERROR;
                    } else {
                        // not mapped error on checkPortInFeasibility if "resultDescription": "Failure"
                        errorMessage = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
                    }
                    var error = errorMock("checkPortInFeasibilityIIQuery", {
                        status: "200",
                        statusText: "200",
                        url: API.CHECK_IN_PORT
                    }, errorMessage);
                    queryClient.setQueryData("checkPortInFeasibilityIIQuery", error);
                    localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(error));
                }
                var error1 = errorMock("checkPortInFeasibilityIIQuery", {
                    status: "200",
                    statusText: "200",
                    url: API.CHECK_IN_PORT
                }, errorMessage);
                queryClient.setQueryData("checkPortInFeasibilityIIQuery", error1);
                localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(error1));
            } else {
                // Success save checkPortInFeasibilityIIQuery object into storage
                localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(data));
                queryClient.setQueryData("checkPortInFeasibilityIIQuery", data);
            }
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - checkPortInFeasibilityIIQuery: ", error);
            queryClient.setQueryData("checkPortInFeasibilityIIQuery", errorMock("checkPortInFeasibilityII", error));
            localStorage.setItem("checkPortInFeasibilityIIQuery", JSON.stringify(errorMock("checkPortInFeasibilityII", error)));
        }
    });
};
