import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
/**
 * Methods to invoke the POST /verify service
 * @param payload
 * @param customOptions
 * @returns
 */ var verifyOTPService = function(payload, customOptions) {
    return nextClient.post(API.VERIFY, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Mutation to verify if the OTP code is valid
 * @returns
 */ export var useVerifyOTP = function(customOptions) {
    var queryClient = useQueryClient();
    LoggerInstance.debug("useVerifyOTP");
    return useMutation("verifyOTP", function(otp) {
        LoggerInstance.debug("useVerifyOTP otp : ", otp);
        var generateOTPSaved = localStorage.getItem("generateOTP") && JSON.parse(localStorage.getItem("generateOTP"));
        var payload = {
            id: generateOTPSaved.id,
            otp: otp,
            pkatId: generateOTPSaved.pkatId
        };
        LoggerInstance.debug("useVerifyOTP payload : ", payload);
        return verifyOTPService(payload, customOptions);
    }, {
        onSuccess: function(data) {
            var ref;
            localStorage.setItem("verifyOTP", JSON.stringify(data));
            queryClient.setQueryData("verifyOTP", data);
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
        },
        onError: function(error) {
            var ref;
            LoggerInstance.error("ERROR - verifyOTP: ", error);
            queryClient.setQueryData("verifyOTP", errorMock("verifyOTP", error));
            localStorage.setItem("verifyOTP", JSON.stringify(errorMock("verifyOTP", error)));
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
        }
    });
};
