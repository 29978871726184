import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
/**
 * This method calls the service that verify the credit card submitted.
 * Method: POST
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ export var lightCreditVettingService = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.LIGHT_CREDIT_VETTING), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook to manage lightCreditVettingService.
 * @returns
 */ export var useLightCreditVettingMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("lightCreditCardVetting", function() {
        var ref;
        // Get the shoppingCart obj from storage
        var shoppingCartSaved = localStorage.getItem("shoppingCart");
        var shoppingCart = !!shoppingCartSaved && JSON.parse(shoppingCartSaved);
        LoggerInstance.debug("shoppingCart : ", shoppingCart);
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        // Get billingAccount from local storage
        var billingAccountSaved = localStorage.getItem("billingAccount");
        var billingAccount = !!billingAccountSaved && JSON.parse(billingAccountSaved);
        var physicalAddress = billingAccount === null || billingAccount === void 0 ? void 0 : (ref = billingAccount.billDeliveryDetails) === null || ref === void 0 ? void 0 : ref.physicalAddress;
        LoggerInstance.debug("physicalAddress : ", physicalAddress);
        var payload = {
            shippingAddress: {
                id: physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.id,
                street: physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.street,
                streetNumber: physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.streetNumber,
                city: physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.city,
                country: physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.country,
                stateOrProvince: physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.stateOrProvince,
                postalCode: physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.postalCode,
                formattedAddress1: (physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.formattedAddress) || (physicalAddress === null || physicalAddress === void 0 ? void 0 : physicalAddress.formattedAddress1)
            }
        };
        return lightCreditVettingService(cartId, payload);
    }, {
        onSuccess: function(data) {
            LoggerInstance.debug("SUCCESS", data);
            queryClient.setQueryData("lightCreditVetting", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - lightCreditVetting: ", error);
            queryClient.setQueryData("lightCreditVetting", errorMock("lightCreditVetting", error));
            localStorage.setItem("lightCreditVetting", JSON.stringify(errorMock("lightCreditVetting", error)));
        }
    });
};
