import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { isMobileProduct } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
// region NEW POST FLOW
var getAddressValidatedInDeliveryByCoords = function(deliveryBaseData, validatedAddress) {
    var ref, ref1;
    var compareAddress = function(address1, address2) {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
        return ((ref = address1.city) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === (address2 === null || address2 === void 0 ? void 0 : (ref1 = address2.city) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) && ((ref2 = address1.postalCode) === null || ref2 === void 0 ? void 0 : ref2.toLowerCase()) === (address2 === null || address2 === void 0 ? void 0 : (ref3 = address2.postalCode) === null || ref3 === void 0 ? void 0 : ref3.toLowerCase()) && ((ref4 = address1.stateOrProvince) === null || ref4 === void 0 ? void 0 : ref4.toLowerCase()) === (address2 === null || address2 === void 0 ? void 0 : (ref5 = address2.stateOrProvince) === null || ref5 === void 0 ? void 0 : ref5.toLowerCase()) && ((ref6 = address1.street) === null || ref6 === void 0 ? void 0 : ref6.toLowerCase()) === (address2 === null || address2 === void 0 ? void 0 : (ref7 = address2.street) === null || ref7 === void 0 ? void 0 : ref7.toLowerCase()) && ((ref8 = address1.streetNumber) === null || ref8 === void 0 ? void 0 : ref8.toLowerCase()) === (address2 === null || address2 === void 0 ? void 0 : (ref9 = address2.streetNumber) === null || ref9 === void 0 ? void 0 : ref9.toLowerCase());
    };
    var availableAddresses = (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref = deliveryBaseData.recipientDetails) === null || ref === void 0 ? void 0 : ref.availableAddresses) || [];
    var recipientAddress = (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref1 = deliveryBaseData.recipientDetails) === null || ref1 === void 0 ? void 0 : ref1.recipientAddress) || [];
    if ((availableAddresses === null || availableAddresses === void 0 ? void 0 : availableAddresses.length) > 0) {
        var haveAvailableAddress = availableAddresses === null || availableAddresses === void 0 ? void 0 : availableAddresses.find(function(el) {
            return compareAddress(el, validatedAddress);
        });
        var haveRecipientAddress = compareAddress(recipientAddress, validatedAddress);
        if (haveAvailableAddress) return haveAvailableAddress;
        if (haveRecipientAddress) return recipientAddress;
        return null;
    }
    return null;
};
// endregion
var getCustomerAddress = function() {
    var ref, ref1;
    var customer = getFromLocalStorageByKey("customerData");
    var contactMedium = (customer === null || customer === void 0 ? void 0 : (ref = customer[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.contactMedium) || [];
    return contactMedium === null || contactMedium === void 0 ? void 0 : contactMedium.find(function(contact) {
        return contact.type == "postalAddress";
    });
};
var isCustomerAddressSameRecipient = function(deliveryBaseData) {
    var ref, ref1;
    var customerPostalAddress = getCustomerAddress();
    var deliveryBaseAddressId = deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref = deliveryBaseData.recipientDetails) === null || ref === void 0 ? void 0 : (ref1 = ref.recipientAddress) === null || ref1 === void 0 ? void 0 : ref1.id;
    return (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.id) === deliveryBaseAddressId;
};
var isPresentCustomerAddressInDelivery = function(deliveryBaseData) {
    var ref, ref1, ref2;
    var availableAddresses = (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref = deliveryBaseData.recipientDetails) === null || ref === void 0 ? void 0 : ref.availableAddresses) || [];
    var deliveryBaseAddressId = deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref1 = deliveryBaseData.recipientDetails) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.recipientAddress) === null || ref2 === void 0 ? void 0 : ref2.id;
    var customerPostalAddress = getCustomerAddress();
    if (!availableAddresses || (availableAddresses === null || availableAddresses === void 0 ? void 0 : availableAddresses.length) === 0) return true;
    if ((availableAddresses === null || availableAddresses === void 0 ? void 0 : availableAddresses.length) > 0) {
        return (availableAddresses === null || availableAddresses === void 0 ? void 0 : availableAddresses.some(function(el) {
            return el.id === (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.id);
        })) || (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.id) === deliveryBaseAddressId;
    }
    return (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.id) === deliveryBaseAddressId;
};
var getCustomerAddressInDeliveryByCoords = function(deliveryBaseData) {
    var ref;
    var availableAddresses = (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref = deliveryBaseData.recipientDetails) === null || ref === void 0 ? void 0 : ref.availableAddresses) || [];
    var customerPostalAddress = getCustomerAddress();
    if ((availableAddresses === null || availableAddresses === void 0 ? void 0 : availableAddresses.length) > 0) {
        return availableAddresses === null || availableAddresses === void 0 ? void 0 : availableAddresses.find(function(el) {
            return el.city === (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.city) && el.postalCode === (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.postalCode) && el.stateOrProvince === (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.stateOrProvince) && el.street === (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.street) && el.streetNumber === (customerPostalAddress === null || customerPostalAddress === void 0 ? void 0 : customerPostalAddress.streetNumber);
        });
    }
    return null;
};
var updateCustomerAddressId = function(availableShippingAddress) {
    var ref, ref1;
    if (!availableShippingAddress) return;
    var customer = getFromLocalStorageByKey("customerData");
    var contactMedium = (customer === null || customer === void 0 ? void 0 : (ref = customer[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.contactMedium) || [];
    var postalAddress = contactMedium === null || contactMedium === void 0 ? void 0 : contactMedium.find(function(contact) {
        return contact.type == "postalAddress";
    });
    postalAddress.id = availableShippingAddress === null || availableShippingAddress === void 0 ? void 0 : availableShippingAddress.id;
    localStorage.setItem("customerData", JSON.stringify(customer));
};
// region Delivery API utils
/**
 * A getter method to format different API parameters based on the @KEY
 * @param key
 * @param customerId
 * @param advancedParams
 * @param evolvedParams
 * @returns
 */ var getParams = function(key, customerId, advancedParams, evolvedParams) {
    var parameters;
    LoggerInstance.debug(advancedParams, evolvedParams, "advanced and envolved params");
    switch(key){
        case "IsBase":
            {
                parameters = {
                    salesChannel: "selfService",
                    mode: "loadConfiguration",
                    customerId: customerId,
                    filter: "All",
                    lob: "WL",
                    identificationMethod: "NA"
                };
                break;
            }
        case "IsBaseMobile":
            {
                parameters = {
                    salesChannel: "selfService",
                    mode: "loadConfiguration",
                    customerId: customerId,
                    filter: "All",
                    lob: "WS"
                };
                break;
            }
        case "IsAdvanced":
            {
                parameters = {
                    salesChannel: "selfService",
                    identificationMethod: advancedParams === null || advancedParams === void 0 ? void 0 : advancedParams.identificationMethod,
                    addressId: advancedParams === null || advancedParams === void 0 ? void 0 : advancedParams.addressId,
                    deliveryMethod: advancedParams === null || advancedParams === void 0 ? void 0 : advancedParams.deliveryMethod,
                    filter: "All",
                    lob: "WL"
                };
                break;
            }
        case "IsAdvancedMobile":
            {
                parameters = {
                    salesChannel: "selfService",
                    mode: "loadConfiguration",
                    identificationMethod: advancedParams === null || advancedParams === void 0 ? void 0 : advancedParams.identificationMethod,
                    customerId: customerId,
                    filter: "All",
                    lob: "WS"
                };
                break;
            }
        case "IsEvolved":
            {
                parameters = {
                    salesChannel: "selfService",
                    mode: "reviewMode",
                    customerId: customerId,
                    identificationMethod: evolvedParams === null || evolvedParams === void 0 ? void 0 : evolvedParams.identificationMethod,
                    deliveryMethod: evolvedParams === null || evolvedParams === void 0 ? void 0 : evolvedParams.deliveryMethod,
                    filter: "All",
                    lob: "WL"
                };
                break;
            }
        case "IsEvolvedMobile":
            {
                parameters = {
                    salesChannel: "selfService",
                    identificationMethod: advancedParams === null || advancedParams === void 0 ? void 0 : advancedParams.identificationMethod,
                    addressId: advancedParams === null || advancedParams === void 0 ? void 0 : advancedParams.addressId,
                    deliveryMethod: advancedParams === null || advancedParams === void 0 ? void 0 : advancedParams.deliveryMethod,
                    filter: "All",
                    lob: "WS"
                };
                break;
            }
        case "IsUltraMobile":
            {
                parameters = {
                    salesChannel: "selfService",
                    mode: "reviewMode",
                    customerId: customerId,
                    identificationMethod: evolvedParams === null || evolvedParams === void 0 ? void 0 : evolvedParams.identificationMethod,
                    filter: "All",
                    lob: "WS"
                };
                break;
            }
        case "IsEditAddress":
            {
                parameters = {
                    salesChannel: "selfService",
                    mode: "loadConfiguration",
                    customerId: customerId,
                    identificationMethod: evolvedParams === null || evolvedParams === void 0 ? void 0 : evolvedParams.identificationMethod,
                    deliveryMethod: evolvedParams === null || evolvedParams === void 0 ? void 0 : evolvedParams.deliveryMethod,
                    filter: "All",
                    lob: "WL"
                };
                break;
            }
        default:
            break;
    }
    return parameters;
};
/**
 * A getter method to retrieve the delivery code based on the deliveryItems
 * @param deliveryItems
 * @returns
 */ var deliveryCode = function(deliveryItems) {
    var ref;
    if (deliveryItems && (deliveryItems === null || deliveryItems === void 0 ? void 0 : (ref = deliveryItems[0]) === null || ref === void 0 ? void 0 : ref.details)) {
        var ref1, ref2;
        var objToFilter = deliveryItems === null || deliveryItems === void 0 ? void 0 : (ref1 = deliveryItems[0]) === null || ref1 === void 0 ? void 0 : ref1.details;
        var filteredItem = objToFilter.filter(function(itemToFilter) {
            return (itemToFilter === null || itemToFilter === void 0 ? void 0 : itemToFilter.code) == "T" || (itemToFilter === null || itemToFilter === void 0 ? void 0 : itemToFilter.code) == "S";
        });
        return filteredItem === null || filteredItem === void 0 ? void 0 : (ref2 = filteredItem[0]) === null || ref2 === void 0 ? void 0 : ref2.code;
    }
    return isMobileProduct() ? "S" : "T"; // or 'S'
};
/**
 * Method to inspect if the get delivery information has the Postal Office delivery option.
 * @param items
 * @returns
 */ export var hasPostalOfficeOption = function(items) {
    return (items === null || items === void 0 ? void 0 : items.filter(function(element) {
        return (element === null || element === void 0 ? void 0 : element.code) === "PO";
    }).length) > 0;
};
/**
 * Method to inspect if the get delivery information has the Pickup Store delivery option.
 * @param items
 * @returns
 */ export var hasPickupStoreOption = function(items) {
    return (items === null || items === void 0 ? void 0 : items.filter(function(element) {
        return (element === null || element === void 0 ? void 0 : element.code) === "PP";
    }).length) > 0;
};
/**
 * This is a custome useQuery hook to handle all the delivery Get types.
 * @param queryClient
 * @param key
 * @param pickupPoint
 * @returns
 */ var getQueryStringParams = function(queryClient, key, pickupPoint) {
    var ref, ref1;
    var parameters;
    var identificationMethod;
    var addressId;
    var deliveryMethod;
    var isEvolved;
    var queryEnabler = false;
    var customerData = getFromLocalStorageByKey("customerData");
    var patchCustomer = getFromLocalStorageByKey("patchCustomer");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id) || (patchCustomer === null || patchCustomer === void 0 ? void 0 : (ref1 = patchCustomer.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.id);
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    // Retrive new address ID.
    var deliveryCreatedAddressDataSaved = getFromLocalStorageByKey("deliveryCreatedAddress");
    var deliveryCreatedAddress = queryClient.getQueryData([
        "deliveryCreatedAddress"
    ]) || deliveryCreatedAddressDataSaved;
    // Retrieve installation address
    var createdAddressDataSaved = getFromLocalStorageByKey("createdAddress");
    var createdAddress = queryClient.getQueryData([
        "createdAddress"
    ]) || createdAddressDataSaved;
    LoggerInstance.debug("GET QUERY STRING PARAMS", key);
    if (key === "IsBase") {
        LoggerInstance.debug("GET PARAM KEY IS BASE");
        parameters = getParams(key, customerId);
        queryEnabler = !!cartId && !!customerId && !!parameters;
    }
    if (key === "IsAdvanced") {
        LoggerInstance.debug("GET PARAM KEY IS ADVANCED");
        var deliveryDetailsBaseQuery = queryClient.getQueryData([
            "deliveryDetailsIsBase", 
        ]);
        var deliveryDetailsBaseSaved = localStorage.getItem("deliveryDetailsIsBase");
        var deliveryDetailsBase = deliveryDetailsBaseQuery || (deliveryDetailsBaseSaved ? JSON.parse(deliveryDetailsBaseSaved) : undefined);
        if (deliveryDetailsBase && !!(deliveryDetailsBase === null || deliveryDetailsBase === void 0 ? void 0 : deliveryDetailsBase[0])) {
            var ref2, ref3;
            // eslint-disable-next-line
            var ref4 = deliveryDetailsBase === null || deliveryDetailsBase === void 0 ? void 0 : deliveryDetailsBase[0], deliveryItems = ref4.deliveryItems, identificationDetails = ref4.identificationDetails;
            var selectedMethod = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.filter(function(item) {
                return item.isSelected === true;
            });
            identificationMethod = selectedMethod === null || selectedMethod === void 0 ? void 0 : (ref2 = selectedMethod[0]) === null || ref2 === void 0 ? void 0 : ref2.code; // 'NA'
            var addressIdMobile = (ref3 = getCustomerAddress()) === null || ref3 === void 0 ? void 0 : ref3.id;
            addressId = isMobileProduct() ? addressIdMobile : createdAddress === null || createdAddress === void 0 ? void 0 : createdAddress.id; // recipientDetails?.recipientAddress?.id;
            deliveryMethod = deliveryCode(deliveryItems);
            var advancedParams = {
                identificationMethod: identificationMethod,
                addressId: addressId,
                deliveryMethod: deliveryMethod
            };
            parameters = getParams(key, undefined, advancedParams);
            queryEnabler = !!identificationMethod && !!addressId && !!deliveryMethod && !!parameters;
        }
    }
    if (key === "IsEvolved") {
        var deliveryDetailsPostQuery = queryClient.getQueryData([
            "postDeliveryServiceDetails"
        ]);
        var deliveryDetailsPostSaved = localStorage.getItem("postDeliveryServiceDetails");
        var deliveryDetailsPost = deliveryDetailsPostQuery || (deliveryDetailsPostSaved && JSON.parse(deliveryDetailsPostSaved) ? JSON.parse(deliveryDetailsPostSaved) : undefined);
        if (deliveryDetailsPost && (deliveryDetailsPost === null || deliveryDetailsPost === void 0 ? void 0 : deliveryDetailsPost[0])) {
            var ref5;
            // eslint-disable-next-line
            var ref6 = deliveryDetailsPost === null || deliveryDetailsPost === void 0 ? void 0 : deliveryDetailsPost[0], deliveryItems1 = ref6.deliveryItems, identificationDetails1 = ref6.identificationDetails;
            var selectedMethod1 = identificationDetails1 === null || identificationDetails1 === void 0 ? void 0 : identificationDetails1.filter(function(item) {
                return item.isSelected === true;
            });
            identificationMethod = selectedMethod1 === null || selectedMethod1 === void 0 ? void 0 : (ref5 = selectedMethod1[0]) === null || ref5 === void 0 ? void 0 : ref5.code;
            deliveryMethod = deliveryCode(deliveryItems1);
            isEvolved = Boolean(!!deliveryDetailsPost && !!(deliveryDetailsPost === null || deliveryDetailsPost === void 0 ? void 0 : deliveryDetailsPost[0]));
            var evolvedParams = {
                identificationMethod: identificationMethod,
                deliveryMethod: deliveryMethod
            };
            parameters = getParams(key, customerId, null, evolvedParams);
            queryEnabler = !!cartId && !!customerId && !!deliveryMethod && !!identificationMethod && !!isEvolved;
        }
    }
    if (key === "IsPost") {
        parameters = [];
        LoggerInstance.debug("KEY IS POST ");
        var deliveryDetailsAdvancedKey = "deliveryDetailsIsAdvanced";
        var deliveryDetailsAdvancedQuery = queryClient.getQueryData([
            deliveryDetailsAdvancedKey, 
        ]);
        var deliveryDetailsAdvancedSaved = localStorage.getItem(deliveryDetailsAdvancedKey);
        var deliveryDetailsAdvanced = deliveryDetailsAdvancedQuery || (deliveryDetailsAdvancedSaved ? JSON.parse(deliveryDetailsAdvancedSaved) : undefined);
        if (deliveryDetailsAdvanced && !Array.isArray(deliveryDetailsAdvanced)) {
            deliveryDetailsAdvanced = [
                deliveryDetailsAdvanced
            ];
        }
        var isEditDeliveryAddressQuery = queryClient.getQueryData([
            "deliveryDetailsIsEditAddress", 
        ]);
        var isEditDeliveryAddressSaved = localStorage.getItem("deliveryDetailsIsEditAddress");
        var isEditDeliveryAddress = isEditDeliveryAddressQuery || (isEditDeliveryAddressSaved ? JSON.parse(isEditDeliveryAddressSaved) : undefined);
        LoggerInstance.debug("DELIVERY ADVANCED", deliveryDetailsAdvanced);
        var deliveryData = deliveryDetailsAdvanced;
        var recipientAddress;
        if (!!deliveryDetailsAdvanced && !(pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) && !isEditDeliveryAddress) {
            var customerAddress = getCustomerAddress();
            var deliveryBase = isMobileProduct() ? getFromLocalStorageByKey("deliveryDetailsIsBaseMobile") : getFromLocalStorageByKey("deliveryDetailsIsBase");
            var foundedAddressInDelivery = getCustomerAddressInDeliveryByCoords(deliveryBase);
            if (foundedAddressInDelivery) {
                recipientAddress = foundedAddressInDelivery;
                updateCustomerAddressId(foundedAddressInDelivery);
            } else {
                recipientAddress = customerAddress;
            }
        } else if (!!deliveryDetailsAdvanced && !!isEditDeliveryAddress && !(pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId)) {
            recipientAddress = deliveryCreatedAddress || createdAddress;
        } else if (!!isEditDeliveryAddress && (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId)) {
            var ref7, ref8;
            LoggerInstance.debug("Third POST");
            LoggerInstance.debug("Pickuppoint delivery", pickupPoint);
            if (isEditDeliveryAddress) deliveryData = Array.isArray(isEditDeliveryAddress) ? isEditDeliveryAddress : [
                isEditDeliveryAddress
            ];
            recipientAddress = ((ref7 = pickupPoint) === null || ref7 === void 0 ? void 0 : ref7.locationAddress) || ((ref8 = pickupPoint) === null || ref8 === void 0 ? void 0 : ref8.storeAddress);
        }
        if (deliveryData) {
            var getDeliveryItem = function(deliveryItem) {
                var ref, ref1;
                var deliveryItems = deliveryItem.deliveryItems, identificationDetails = deliveryItem.identificationDetails, recipientDetails = deliveryItem.recipientDetails, deliveryDetails = deliveryItem.deliveryDetails;
                LoggerInstance.debug("RECIPIENT DETAILS", recipientDetails);
                LoggerInstance.debug("RECIPIENT DELIVERY", deliveryItems);
                LoggerInstance.debug("RECIPIENT ADDR", recipientAddress);
                var selectedMethod = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.filter(function(item) {
                    return item.isSelected === true;
                });
                var identificationMethodCode = selectedMethod === null || selectedMethod === void 0 ? void 0 : (ref = selectedMethod[0]) === null || ref === void 0 ? void 0 : ref.code; // 'NA'
                var deliveryMethodCode = (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) ? ((ref1 = pickupPoint) === null || ref1 === void 0 ? void 0 : ref1.typePickupPoint) || "PO" : deliveryCode(deliveryItems);
                return {
                    recipientAddress: recipientAddress,
                    recipientDetails: recipientDetails,
                    identificationMethod: identificationMethodCode,
                    deliveryMethod: deliveryMethodCode,
                    deliveryItems: deliveryItems,
                    deliveryDetails: deliveryDetails
                };
            };
            if (Array.isArray(deliveryData)) {
                deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.forEach(function(delivery) {
                    parameters.push(_object_spread({}, getDeliveryItem(delivery)));
                });
            } else {
                parameters.push(_object_spread({}, getDeliveryItem(deliveryData)));
            }
        }
    }
    if (key === "IsEditAddress") {
        LoggerInstance.debug("ISEDITADDRESS", pickupPoint);
        var params;
        var deliveryDetailsPost1 = getFromLocalStorageByKey("postDeliveryServiceDetails");
        if (deliveryDetailsPost1) {
            var ref9;
            LoggerInstance.debug("ISEDITADDRESS start flow", deliveryDetailsPost1);
            var identificationDetails2 = [];
            if (Array.isArray(deliveryDetailsPost1)) {
                var ref10;
                identificationDetails2 = deliveryDetailsPost1 === null || deliveryDetailsPost1 === void 0 ? void 0 : (ref10 = deliveryDetailsPost1[0]) === null || ref10 === void 0 ? void 0 : ref10.identificationDetails;
            } else {
                identificationDetails2 = deliveryDetailsPost1 === null || deliveryDetailsPost1 === void 0 ? void 0 : deliveryDetailsPost1.identificationDetails;
            }
            // Retrive identification method.
            var selectedMethod2 = identificationDetails2 === null || identificationDetails2 === void 0 ? void 0 : identificationDetails2.filter(function(item) {
                return item.isSelected === true;
            });
            identificationMethod = selectedMethod2 === null || selectedMethod2 === void 0 ? void 0 : (ref9 = selectedMethod2[0]) === null || ref9 === void 0 ? void 0 : ref9.code;
            // TRUE ==> 'S' shipping to home
            if (!(pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId)) {
                // Retrive new address ID.
                var deliveryCreatedAddressFromQuery = queryClient.getQueryData([
                    "deliveryCreatedAddress", 
                ]);
                addressId = deliveryCreatedAddressFromQuery === null || deliveryCreatedAddressFromQuery === void 0 ? void 0 : deliveryCreatedAddressFromQuery.id;
                // Retrive delivery method.
                deliveryMethod = "S";
                // Build envolved params for to get all params.
                var evolvedParams1 = {
                    identificationMethod: identificationMethod,
                    deliveryMethod: deliveryMethod
                };
                params = getParams(key, customerId, null, evolvedParams1);
                // Check if we have all params for to start request.
                queryEnabler = !!cartId && !!customerId && !!deliveryMethod && !!identificationMethod && !!addressId;
            } else if (pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) {
                var ref11, ref12, ref13, ref14, ref15;
                addressId = ((ref11 = pickupPoint) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.locationAddress) === null || ref12 === void 0 ? void 0 : ref12.id) || ((ref13 = pickupPoint) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.storeAddress) === null || ref14 === void 0 ? void 0 : ref14.id);
                // Retrive delivery method.
                deliveryMethod = ((ref15 = pickupPoint) === null || ref15 === void 0 ? void 0 : ref15.typePickupPoint) || "PO";
                // Build envolved params for to get all params.
                var evolvedParams2 = {
                    identificationMethod: identificationMethod,
                    deliveryMethod: deliveryMethod
                };
                params = getParams(key, customerId, null, evolvedParams2);
                // Check if we have all params for to start request.
                queryEnabler = !!cartId && !!customerId && !!deliveryMethod && !!identificationMethod;
            }
            // Add to parameters new addressID retrived.
            parameters = _object_spread_props(_object_spread({}, params), {
                addressId: addressId
            });
        }
    }
    if (key === "IsBaseMobile") {
        LoggerInstance.debug("GET PARAM KEY IS BASE");
        parameters = getParams(key, customerId);
        queryEnabler = !!cartId && !!customerId && !!parameters;
    }
    if (key === "IsAdvancedMobile") {
        LoggerInstance.debug("GET PARAM KEY IS ADVANCED");
        var deliveryDetailsBaseQuery1 = queryClient.getQueryData([
            "deliveryDetailsIsBaseMobile", 
        ]);
        var deliveryDetailsBaseSaved1 = localStorage.getItem("deliveryDetailsIsBaseMobile");
        var deliveryDetailsBase1 = deliveryDetailsBaseQuery1 || (deliveryDetailsBaseSaved1 ? JSON.parse(deliveryDetailsBaseSaved1) : undefined);
        if (deliveryDetailsBase1) {
            var ref16;
            var deliveryItems2;
            var identificationDetails3;
            if (Array.isArray(deliveryDetailsBase1)) {
                var ref17, ref18;
                deliveryItems2 = deliveryDetailsBase1 === null || deliveryDetailsBase1 === void 0 ? void 0 : (ref17 = deliveryDetailsBase1[0]) === null || ref17 === void 0 ? void 0 : ref17.deliveryItems;
                identificationDetails3 = deliveryDetailsBase1 === null || deliveryDetailsBase1 === void 0 ? void 0 : (ref18 = deliveryDetailsBase1[0]) === null || ref18 === void 0 ? void 0 : ref18.identificationDetails;
            } else {
                deliveryItems2 = deliveryDetailsBase1 === null || deliveryDetailsBase1 === void 0 ? void 0 : deliveryDetailsBase1.deliveryItems;
                identificationDetails3 = deliveryDetailsBase1 === null || deliveryDetailsBase1 === void 0 ? void 0 : deliveryDetailsBase1.identificationDetails;
            }
            var selectedMethod3 = identificationDetails3 === null || identificationDetails3 === void 0 ? void 0 : identificationDetails3.filter(function(item) {
                return item.isSelected === true;
            });
            identificationMethod = selectedMethod3 === null || selectedMethod3 === void 0 ? void 0 : (ref16 = selectedMethod3[0]) === null || ref16 === void 0 ? void 0 : ref16.code; // 'NA'
            deliveryMethod = deliveryCode(deliveryItems2);
            var advancedParams1 = {
                identificationMethod: identificationMethod,
                deliveryMethod: deliveryMethod
            };
            parameters = getParams(key, customerId, advancedParams1);
            LoggerInstance.debug("KEY ISADVANCEDMOBILE - identificationMethod: ", identificationMethod);
            LoggerInstance.debug("KEY ISADVANCEDMOBILE - addressId: ", addressId);
            LoggerInstance.debug("KEY ISADVANCEDMOBILE - deliveryMethod: ", deliveryMethod);
            LoggerInstance.debug("KEY ISADVANCEDMOBILE - parameters: ", parameters);
            queryEnabler = !!identificationMethod && !!customerId && !!deliveryMethod && !!parameters;
        }
    }
    if (key === "IsEvolvedMobile") {
        LoggerInstance.debug("GET PARAM KEY IS EVOLVED");
        var deliveryDetailsBaseQuery2 = queryClient.getQueryData([
            "deliveryDetailsIsBaseMobile", 
        ]);
        var deliveryDetailsBaseSaved2 = localStorage.getItem("deliveryDetailsIsBaseMobile");
        var deliveryDetailsBase2 = deliveryDetailsBaseQuery2 || (deliveryDetailsBaseSaved2 ? JSON.parse(deliveryDetailsBaseSaved2) : undefined);
        if (deliveryDetailsBase2) {
            var ref19, ref20, ref21, ref22, ref23;
            var deliveryItems3;
            var identificationDetails4;
            if (Array.isArray(deliveryDetailsBase2)) {
                var ref24, ref25;
                deliveryItems3 = deliveryDetailsBase2 === null || deliveryDetailsBase2 === void 0 ? void 0 : (ref24 = deliveryDetailsBase2[0]) === null || ref24 === void 0 ? void 0 : ref24.deliveryItems;
                identificationDetails4 = deliveryDetailsBase2 === null || deliveryDetailsBase2 === void 0 ? void 0 : (ref25 = deliveryDetailsBase2[0]) === null || ref25 === void 0 ? void 0 : ref25.identificationDetails;
            } else {
                deliveryItems3 = deliveryDetailsBase2 === null || deliveryDetailsBase2 === void 0 ? void 0 : deliveryDetailsBase2.deliveryItems;
                identificationDetails4 = deliveryDetailsBase2 === null || deliveryDetailsBase2 === void 0 ? void 0 : deliveryDetailsBase2.identificationDetails;
            }
            var selectedMethod4 = identificationDetails4 === null || identificationDetails4 === void 0 ? void 0 : identificationDetails4.filter(function(item) {
                return item.isSelected === true;
            });
            identificationMethod = selectedMethod4 === null || selectedMethod4 === void 0 ? void 0 : (ref19 = selectedMethod4[0]) === null || ref19 === void 0 ? void 0 : ref19.code; // 'NA'
            var addressIdMobile1 = ((ref20 = getCustomerAddress()) === null || ref20 === void 0 ? void 0 : ref20.id) || ((ref23 = patchCustomer === null || patchCustomer === void 0 ? void 0 : (ref21 = patchCustomer.owningIndividual) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.contactMedium) === null || ref22 === void 0 ? void 0 : ref22.find(function(item) {
                return item.type === "postalAddress";
            })) === null || ref23 === void 0 ? void 0 : ref23.id);
            addressId = isMobileProduct() ? addressIdMobile1 : createdAddress === null || createdAddress === void 0 ? void 0 : createdAddress.id; // recipientDetails?.recipientAddress?.id;
            deliveryMethod = deliveryCode(deliveryItems3);
            var advancedParams2 = {
                identificationMethod: identificationMethod,
                addressId: addressId,
                deliveryMethod: deliveryMethod
            };
            parameters = getParams(key, undefined, advancedParams2);
            LoggerInstance.debug("identificationMethod", identificationMethod);
            LoggerInstance.debug("addressId", addressId);
            LoggerInstance.debug("deliveryMethod", deliveryMethod);
            LoggerInstance.debug("parameters", parameters);
            queryEnabler = !!identificationMethod && !!addressId && !!deliveryMethod && !!parameters;
        }
    }
    if (key === "IsUltraMobile") {
        var deliveryDetailsPostQuery1 = queryClient.getQueryData([
            "postDeliveryServiceDetails"
        ]);
        var deliveryDetailsPostSaved1 = localStorage.getItem("postDeliveryServiceDetails");
        var deliveryDetailsPost2 = deliveryDetailsPostQuery1 || (deliveryDetailsPostSaved1 && JSON.parse(deliveryDetailsPostSaved1) ? JSON.parse(deliveryDetailsPostSaved1) : undefined);
        if (deliveryDetailsPost2) {
            var ref26;
            // let deliveryItems;
            var identificationDetails5;
            if (Array.isArray(deliveryDetailsPost2)) {
                var ref27;
                // deliveryItems = deliveryDetailsPost?.[0]?.deliveryItems;
                identificationDetails5 = deliveryDetailsPost2 === null || deliveryDetailsPost2 === void 0 ? void 0 : (ref27 = deliveryDetailsPost2[0]) === null || ref27 === void 0 ? void 0 : ref27.identificationDetails;
            } else {
                // deliveryItems = deliveryDetailsPost?.deliveryItems;
                identificationDetails5 = deliveryDetailsPost2 === null || deliveryDetailsPost2 === void 0 ? void 0 : deliveryDetailsPost2.identificationDetails;
            }
            var selectedMethod5 = identificationDetails5 === null || identificationDetails5 === void 0 ? void 0 : identificationDetails5.filter(function(item) {
                return item.isSelected === true;
            });
            identificationMethod = selectedMethod5 === null || selectedMethod5 === void 0 ? void 0 : (ref26 = selectedMethod5[0]) === null || ref26 === void 0 ? void 0 : ref26.code;
            // deliveryMethod = deliveryCode(deliveryItems);
            if (isMobileProduct()) {
                isEvolved = Boolean(deliveryDetailsPost2);
            } else {
                isEvolved = Boolean(!!deliveryDetailsPost2 && !!(deliveryDetailsPost2 === null || deliveryDetailsPost2 === void 0 ? void 0 : deliveryDetailsPost2[0]));
            }
            var evolvedParams3 = {
                identificationMethod: identificationMethod,
                deliveryMethod: deliveryMethod
            };
            parameters = getParams(key, customerId, null, evolvedParams3);
            queryEnabler = !!cartId && !!customerId && !!identificationMethod && !!isEvolved;
        }
    }
    return {
        cartId: cartId,
        parameters: parameters,
        queryEnabler: queryEnabler
    };
};
// endregion
export { getCustomerAddress, isCustomerAddressSameRecipient, isPresentCustomerAddressInDelivery, getCustomerAddressInDeliveryByCoords, getAddressValidatedInDeliveryByCoords, updateCustomerAddressId, getParams, getQueryStringParams };
