import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { errorMock } from "@vfit/consumer/data-access";
/**
 * Shopping cart service
 * @param shoppingCart
 * @param customOptions
 * @returns
 */ export var shoppingCartService = function(shoppingCart, customOptions) {
    return nextClient.post(API.SHOPPING_CART, shoppingCart, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method handle the create cart service.
 * @returns
 */ export var useShoppingCartMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("shoppingCart", function(payload) {
        return shoppingCartService(payload);
    }, {
        onSuccess: function(data) {
            if (data) {
                // save shoppingCart object into storage
                localStorage.setItem("shoppingCart", JSON.stringify(data));
                queryClient.setQueryData("shoppingCart", data);
            // Cookie.set('sunrise-flow', 'true', { domain: '.vodafone.it' });
            // if (data?.id) Cookie.set('sunrise-cart-id', data.id as string);
            } else {
                queryClient.setQueryData("shoppingCart", errorMock("shoppingCart"));
            }
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - shoppingCart: ", error);
            queryClient.setQueryData("shoppingCart", errorMock("shoppingCart", error));
            localStorage.setItem("shoppingCart", JSON.stringify(errorMock("shoppingCart", error)));
        }
    });
};
