import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { checkWindow, getFromLocalStorageByKey, AppMobile } from "@vfit/shared/data-access";
import { LOGGED_USER_KEYS, OFFER_FORMATTEDADDRESS, OFFER_PLACEID, retrieveCartCookie } from "@vfit/consumer/data-access";
import crypto from "crypto-es";
export var parsePrice = function(price) {
    var ref = _sliced_to_array(price.split(","), 2), integer = ref[0], decimal = ref[1];
    return {
        integer: integer,
        decimal: ",".concat(decimal)
    };
};
export var saveAddressInfo = function(placeId, formattedAddress) {
    if (!checkWindow()) return;
    localStorage.setItem(OFFER_PLACEID, placeId);
    localStorage.setItem(OFFER_FORMATTEDADDRESS, formattedAddress);
};
export var getAddressInfo = function() {
    if (!checkWindow()) return {
        placeId: null,
        formattedAddress: null
    };
    return {
        placeId: localStorage.getItem(OFFER_PLACEID),
        formattedAddress: localStorage.getItem(OFFER_FORMATTEDADDRESS)
    };
};
export var checkAnchorApp = function() {
    var container = document.getElementById("more-info-container");
    var links = container === null || container === void 0 ? void 0 : container.getElementsByTagName("a");
    if (links) {
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            // eslint-disable-next-line no-restricted-syntax
            for(var _iterator = links[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var link = _step.value;
                link.onclick = function(e) {
                    var ref;
                    if (e === null || e === void 0 ? void 0 : (ref = e.target) === null || ref === void 0 ? void 0 : ref["href"]) {
                        e.preventDefault();
                        e.stopPropagation();
                        AppMobile.openInBrowser(e.target["href"]);
                    }
                };
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    }
};
export var getUserInfo = function() {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37, ref38, ref39;
    var SHA256 = crypto.SHA256;
    var user = getFromLocalStorageByKey("user");
    var silentLogin = getFromLocalStorageByKey("silentLogin");
    var loginEnrichement = getFromLocalStorageByKey("loginEnriched");
    var isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) || false;
    var customerData = getFromLocalStorageByKey("customerData");
    var appSessionFromStorage = checkWindow() && localStorage.getItem("appSession");
    var appSession = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
    var appMsisdn = appSession === null || appSession === void 0 ? void 0 : appSession.value;
    var visitorLoginStatus = appMsisdn || isLoggedUser ? "logged in" : "logged out";
    return _object_spread({
        visitor_login_type: "web"
    }, !!retrieveCartCookie() && {
        visitor_type: "returning visitor"
    }, (user === null || user === void 0 ? void 0 : (ref = user.customer) === null || ref === void 0 ? void 0 : ref[0].id) && {
        visitor_customer_id: user === null || user === void 0 ? void 0 : user.customer[0].id
    }, (user === null || user === void 0 ? void 0 : (ref1 = user.taggedInfoCust) === null || ref1 === void 0 ? void 0 : ref1.email) && {
        visitor_id_email: SHA256(user === null || user === void 0 ? void 0 : (ref2 = user.taggedInfoCust) === null || ref2 === void 0 ? void 0 : ref2.email).toString()
    }, (user === null || user === void 0 ? void 0 : (ref3 = user.customer) === null || ref3 === void 0 ? void 0 : ref3[0].msisdns) && {
        visitor_id_asset_list: (ref5 = user === null || user === void 0 ? void 0 : (ref4 = user.customer) === null || ref4 === void 0 ? void 0 : ref4[0].msisdns) === null || ref5 === void 0 ? void 0 : ref5.map(function(msisdn) {
            var ref;
            return ((ref = SHA256(msisdn)) === null || ref === void 0 ? void 0 : ref.toString()) || "";
        })
    }, loginEnrichement && ((ref6 = loginEnrichement.items[0]) === null || ref6 === void 0 ? void 0 : ref6.value) && {
        visitor_id_asset_active: SHA256((ref7 = loginEnrichement.items[0]) === null || ref7 === void 0 ? void 0 : ref7.value).toString()
    }, appMsisdn && {
        visitor_id_asset_active: SHA256(appMsisdn).toString()
    }, visitorLoginStatus && {
        visitor_login_status: visitorLoginStatus
    }, customerData && ((ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.owningIndividual) === null || ref9 === void 0 ? void 0 : ref9.birthDate) && {
        visitor_customer_dob: (ref10 = customerData[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.owningIndividual) === null || ref11 === void 0 ? void 0 : ref11.birthDate
    }, customerData && ((ref12 = customerData[0]) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.owningIndividual) === null || ref13 === void 0 ? void 0 : ref13.gender) && {
        visitor_customer_gender: (ref14 = customerData[0]) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.owningIndividual) === null || ref15 === void 0 ? void 0 : ref15.gender
    }, customerData && ((ref16 = customerData[0]) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.billingAddress) === null || ref17 === void 0 ? void 0 : ref17.city) && {
        visitor_customer_location_city: (ref18 = customerData[0]) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.billingAddress) === null || ref19 === void 0 ? void 0 : ref19.city
    }, customerData && ((ref20 = customerData[0]) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.owningIndividual) === null || ref21 === void 0 ? void 0 : ref21.nation) && {
        visitor_customer_location_country: (ref22 = customerData[0]) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.owningIndividual) === null || ref23 === void 0 ? void 0 : ref23.nation
    }, customerData && ((ref24 = customerData[0]) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.billingAddress) === null || ref25 === void 0 ? void 0 : ref25.postalCode) && {
        visitor_customer_location_id: (ref26 = customerData[0]) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.billingAddress) === null || ref27 === void 0 ? void 0 : ref27.postalCode
    }, customerData && ((ref28 = customerData[0]) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.owningIndividual) === null || ref29 === void 0 ? void 0 : ref29.firstName) && {
        visitor_customer_name_first: (ref30 = customerData[0]) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.owningIndividual) === null || ref31 === void 0 ? void 0 : ref31.firstName
    }, customerData && ((ref32 = customerData[0]) === null || ref32 === void 0 ? void 0 : (ref33 = ref32.owningIndividual) === null || ref33 === void 0 ? void 0 : ref33.lastName) && {
        visitor_customer_name_last: (ref34 = customerData[0]) === null || ref34 === void 0 ? void 0 : (ref35 = ref34.owningIndividual) === null || ref35 === void 0 ? void 0 : ref35.lastName
    }, customerData && ((ref36 = customerData[0]) === null || ref36 === void 0 ? void 0 : (ref37 = ref36.billingAddress) === null || ref37 === void 0 ? void 0 : ref37.stateOrProvince) && {
        visitor_customer_region: (ref38 = customerData[0]) === null || ref38 === void 0 ? void 0 : (ref39 = ref38.billingAddress) === null || ref39 === void 0 ? void 0 : ref39.stateOrProvince
    });
};
