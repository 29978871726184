var Type;
(function(Type) {
    Type["HTML"] = "html";
    Type["Image"] = "image";
})(Type || (Type = {}));
var CurrencyCode;
(function(CurrencyCode) {
    CurrencyCode["Eur"] = "EUR";
})(CurrencyCode || (CurrencyCode = {}));
export var ActionType// Todo: To be added other cases
;
(function(ActionType) {
    ActionType["Add"] = "add";
})(ActionType || (ActionType = {}));
export var FlowType;
(function(FlowType) {
    FlowType["HOME_INTERNET"] = "homeInternet_R1";
    FlowType["MOBILE_PLAN"] = "mobilePlan";
})(FlowType || (FlowType = {}));
export var ItemType;
(function(ItemType) {
    ItemType["MULTI_PLAY"] = "multiPlayCartItem";
    ItemType["MOBILE"] = "mobileCartItem";
    ItemType["MOBILE_PLAN"] = "mobilePlanCartItem";
})(ItemType || (ItemType = {}));
export var ObjectType;
(function(ObjectType) {
    ObjectType["MOBILE_PLAN_V1"] = "V1MobilePlanCartItem";
    ObjectType["MOBILE_V1"] = "V1MobileCartItem";
    ObjectType["MULTI_PLAY_V1"] = "V1MultiPlayCartItem";
})(ObjectType || (ObjectType = {}));
export var DeviceAcquisitionType;
(function(DeviceAcquisitionType) {
    DeviceAcquisitionType["OWNED"] = "Owned";
})(DeviceAcquisitionType || (DeviceAcquisitionType = {}));
