import { EMAIL_CUSTOMER, PHONE_CUSTOMER } from "../../checkout.models";
var updateContactDataLocal = function(data) {
    var ref;
    var contactMedium = data === null || data === void 0 ? void 0 : (ref = data.owningIndividual) === null || ref === void 0 ? void 0 : ref.contactMedium;
    if (contactMedium && contactMedium.length > 0) {
        var email = contactMedium.find(function(c) {
            return c.type === "email";
        });
        var phone = contactMedium.find(function(c) {
            return c.type === "phone";
        });
        if (email === null || email === void 0 ? void 0 : email.emailAddress) {
            localStorage.setItem(EMAIL_CUSTOMER, email.emailAddress);
        } else {
            localStorage.removeItem(EMAIL_CUSTOMER);
        }
        if (phone === null || phone === void 0 ? void 0 : phone.phoneNumber) {
            localStorage.setItem(PHONE_CUSTOMER, phone.phoneNumber);
        } else {
            localStorage.removeItem(PHONE_CUSTOMER);
        }
    }
};
export var formatDate = function(date) {
    var retrievedDate = date.toString();
    var year = retrievedDate.substring(0, 4);
    var month = retrievedDate.substring(5, 7);
    var day = retrievedDate.substring(8);
    return day.concat("/", month, "/", year);
};
var SKELETON_SHAPE = {
    margins: [
        60,
        30
    ],
    tabletMargins: [
        32,
        15
    ],
    groups: [
        {
            heights: 45,
            marginBottom: 15
        },
        {
            heights: 60,
            marginBottom: 32
        },
        {
            heights: 60,
            marginBottom: 15
        },
        {
            heights: 75,
            repeat: 4,
            gap: 15
        },
        {
            heights: 45,
            marginBottom: 15
        }, 
    ]
};
export { updateContactDataLocal, SKELETON_SHAPE };
