import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * Shopping cart Activation Cost Update for FWA
 * @param cartId
 * @param internetCartItemId
 * @param payload
 * @returns
 */ var updateShoppingCartActivationCostService = function(cartId, internetCartItemId, payload) {
    return nextClient.patch("".concat(API.SHOPPING_CART, "/").concat(cartId, "/shoppingCartItem/").concat(internetCartItemId), payload);
};
/**
 * This method is used to handle the updateShoppingCartActivationCostService
 * @returns
 */ export var useShoppingCartActivationCostMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("updateShoppingCartPayment", function(isOneTimePayment) {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var internetCartItemId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.internet) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.id;
        var installmentInfo = (ref9 = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref4 = shoppingCart.cartItem) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.internet) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.itemPrice) === null || ref8 === void 0 ? void 0 : ref8.find(function(item) {
            return (item === null || item === void 0 ? void 0 : item.type) === "activation";
        })) === null || ref9 === void 0 ? void 0 : ref9.installmentInfo;
        var productId = installmentInfo === null || installmentInfo === void 0 ? void 0 : (ref10 = installmentInfo.installmentExtention) === null || ref10 === void 0 ? void 0 : ref10.productID;
        var productCharacteristicId = installmentInfo === null || installmentInfo === void 0 ? void 0 : installmentInfo.installmentCharacteristicId;
        var value = isOneTimePayment ? "0" : "24";
        var payload = [
            {
                op: "replace",
                productType: "containedProduct",
                productId: productId,
                productCharecteristic: [
                    {
                        id: productCharacteristicId,
                        value: value
                    }, 
                ]
            }, 
        ];
        return updateShoppingCartActivationCostService(cartId, internetCartItemId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("shoppingCart", JSON.stringify(data));
            queryClient.setQueryData("shoppingCart", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - updateShoppingCartActivationCost: ", error);
            queryClient.setQueryData("shoppingCart", errorMock("updateShoppingCartActivationCost", error));
            localStorage.setItem("shoppingCart", JSON.stringify(errorMock("updateShoppingCartActivationCost", error)));
        }
    });
};
