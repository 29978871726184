import { CMS_CONFIG, getCurrentUserType, IUserType, useBillingAccount, useCmsConfig } from "@vfit/consumer/data-access";
import { API, PAGES } from "@vfit/shared/data-access";
import { InvoiceType } from "./chooseInvoice.models";
var checkUserType = function() {
    return getCurrentUserType() === IUserType.NEXT_USER_ACTIVE || getCurrentUserType() === IUserType.LOGGED_USER;
};
export var organizeBillingInvoiceCard = function(billingCheckboxProps, address, billingEmail, secondItemAction) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12;
    var prefix = billingCheckboxProps === null || billingCheckboxProps === void 0 ? void 0 : (ref = billingCheckboxProps.billinginformation) === null || ref === void 0 ? void 0 : ref.card;
    return {
        title: (prefix === null || prefix === void 0 ? void 0 : prefix.title) || "",
        description: (prefix === null || prefix === void 0 ? void 0 : prefix.description) || "",
        items: [
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref1 = prefix.choice) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.option1) === null || ref2 === void 0 ? void 0 : ref2.title) || "",
                description: (prefix === null || prefix === void 0 ? void 0 : (ref3 = prefix.choice) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.option1) === null || ref4 === void 0 ? void 0 : ref4.description.replace("FIRSTADDRESS", "".concat(address))) || "",
                extra: {
                    text: ""
                },
                value: InvoiceType === null || InvoiceType === void 0 ? void 0 : InvoiceType.PAPER
            },
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref5 = prefix.choice) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.option2) === null || ref6 === void 0 ? void 0 : ref6.title) || "",
                titleIcon: prefix === null || prefix === void 0 ? void 0 : (ref7 = prefix.choice) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.option2) === null || ref8 === void 0 ? void 0 : ref8.icon,
                description: (prefix === null || prefix === void 0 ? void 0 : (ref9 = prefix.choice) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.option2) === null || ref10 === void 0 ? void 0 : ref10.description.replace("EMAIL", "".concat(billingEmail || ""))) || "",
                extra: {
                    text: checkUserType() ? (prefix === null || prefix === void 0 ? void 0 : (ref11 = prefix.choice) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.option2) === null || ref12 === void 0 ? void 0 : ref12.linkAddressLabel) || "" : "",
                    action: checkUserType() ? secondItemAction : undefined,
                    variant: "action"
                },
                value: InvoiceType === null || InvoiceType === void 0 ? void 0 : InvoiceType.EBILL
            }, 
        ]
    };
};
export var useGetUpdatedBilling = function() {
    var globalConfig = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG);
    var ref = useBillingAccount({
        enabled: false
    }, globalConfig), isSuccess = ref.isSuccess, isLoading = ref.isLoading, isError = ref.isError, refecthBillingAccount = ref.refetch;
    var handleRefetch = function(enabler) {
        return enabler && refecthBillingAccount();
    };
    return {
        handleRefetch: handleRefetch,
        isError: isError,
        isLoading: isLoading,
        isSuccess: isSuccess
    };
};
