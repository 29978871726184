import { useQuery } from "react-query";
import { nextClient } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { QueryParams } from "./winback.models";
var searchSubscriberByResourceService = function(phoneNumber) {
    return nextClient.get(API.SEARCH_SUBSCRIBER_BY_RESOURCE, {
        searchParams: {
            salesChannel: QueryParams.SELF,
            levelOfData: QueryParams.SUBSCRIBER,
            limit: 1,
            sort: QueryParams.DESC,
            filters: decodeURIComponent(QueryParams.FILTER),
            subscriptionMsisdn: phoneNumber
        }
    });
};
export var useGetSubscriberByResource = function(number, queryEnabler) {
    return useQuery([
        "getSubscriberByResource"
    ], function() {
        return searchSubscriberByResourceService(number);
    }, {
        enabled: queryEnabler || false
    });
};
