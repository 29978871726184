import Bowser from "bowser";
import { APP_VERSION } from "@vfit/consumer/data-access";
var retrieveRegisterPayload = function() {
    var bowser = Bowser.getParser(navigator.userAgent);
    var os = bowser.getOS();
    var platform = bowser.getPlatform();
    var isTablet = bowser.getPlatformType() === "tablet";
    var platformType = bowser.getPlatformType() === "mobile" ? "Mobile" : "Desktop";
    var generateUUID = function() {
        var url = URL.createObjectURL(new Blob());
        return url.substring(url.lastIndexOf("/") + 1);
    };
    return {
        appVersion: APP_VERSION,
        osVersion: os.version,
        pushToken: "",
        pushProvider: "",
        pushType: "broadcast-simple",
        muid: "CxIubggh4NJYoynR",
        cid: "PtnMVOBJ",
        appKey: "ap1pmcgHqn",
        deviceUdid: generateUUID(),
        appPlatform: platformType,
        osName: os.name,
        deviceVendor: (platform === null || platform === void 0 ? void 0 : platform.vendor) || "web",
        deviceModel: (platform === null || platform === void 0 ? void 0 : platform.model) || "desktop",
        deviceIsTablet: isTablet,
        screenWidth: window.screen.width.toString(),
        screenHeight: window.screen.height.toString(),
        screenRatio: window.devicePixelRatio.toString(),
        appId: "MyVodafoneWeb",
        locale: "it"
    };
};
export { retrieveRegisterPayload };
