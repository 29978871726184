/* eslint-disable no-unsafe-optional-chaining */ import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { IActionType } from "@vfit/shared/models";
import { checkPanicMode, overrideAction, overridePromo } from "../../../utils/utils";
var checkActionType = function(actionType) {
    var type = actionType ? +actionType : -1;
    switch(type){
        case IActionType.CALL_ME_NOW:
        case IActionType.SHOW_DETAIL:
            return false;
        default:
            return true;
    }
};
var organizeProductSlider = function(product, panicModeConfiguration, globalPromoConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    var overrideFlag = checkPanicMode(panicModeConfiguration, product) && checkActionType(product === null || product === void 0 ? void 0 : (ref = product.homepageDetail) === null || ref === void 0 ? void 0 : (ref1 = ref.action) === null || ref1 === void 0 ? void 0 : ref1.type);
    var actionProduct = overrideAction(product, overrideFlag);
    return _object_spread_props(_object_spread({
        id: +product.offerId,
        category: (product === null || product === void 0 ? void 0 : product.category) || "",
        title: (product === null || product === void 0 ? void 0 : product.title) || "",
        image: (product === null || product === void 0 ? void 0 : product.image) || "",
        imageMobile: (product === null || product === void 0 ? void 0 : product.imageMobile) || "",
        video: (product === null || product === void 0 ? void 0 : (ref2 = product.homepageDetail) === null || ref2 === void 0 ? void 0 : ref2.video) || "",
        videoMobile: (product === null || product === void 0 ? void 0 : (ref3 = product.homepageDetail) === null || ref3 === void 0 ? void 0 : ref3.videoMobile) || "",
        overlayImage: (product === null || product === void 0 ? void 0 : (ref4 = product.homepageDetail) === null || ref4 === void 0 ? void 0 : ref4.overlayImage) || "",
        overlayImageMobile: (product === null || product === void 0 ? void 0 : (ref5 = product.homepageDetail) === null || ref5 === void 0 ? void 0 : ref5.overlayImageMobile) || "",
        redirectCoveragePath: product.redirectCoveragePath,
        action: _object_spread({}, actionProduct)
    }, (product === null || product === void 0 ? void 0 : product.slug) && {
        slug: product.slug
    }, (product === null || product === void 0 ? void 0 : product.offerType) && {
        // eslint-disable-next-line no-unsafe-optional-chaining
        offerType: +(product === null || product === void 0 ? void 0 : product.offerType)
    }), {
        product: _object_spread({}, overridePromo(product, globalPromoConfig), checkPanicMode(panicModeConfiguration, product) && {
            hubSecondAction: undefined
        })
    });
};
export var getProductsSlider = function(widget, widgetTitle, panicModeCOnfiguration, globalPromo) {
    var elements = widget.elements;
    var slides = elements.map(function(el) {
        if (el.product) return organizeProductSlider(el.product, panicModeCOnfiguration, globalPromo);
        return el;
    });
    return {
        slides: slides,
        text: widgetTitle
    };
};
