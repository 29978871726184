import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useQueryClient } from "react-query";
import { errorMock, LOGGED_USER_KEYS } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { nextClient, LoggerInstance, handleUseQuery } from "@vfit/shared/providers";
/**
 * This method calls che customer service looking for a specific ID
 * Method: GET
 * @param customerId
 * @param customOptions
 */ var customerService = function(customerId, customOptions) {
    return nextClient.get("".concat(API.CUSTOMER, "/").concat(customerId), _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method recalls the customer ID and use it to returns all the customer information saved
 * @param checkLogged
 * @param customOptions
 * @param customerActiveSubs
 * @returns
 */ export var useCustomerInfo = function(checkLogged, customOptions, customerActiveSubs) {
    // GET CUSTOMER BEFORE ASSOCIATE CUSTOMER
    var queryClient = useQueryClient();
    var patchCustomer = getFromLocalStorageByKey("patchCustomer");
    var customerId;
    var queryEnabler;
    if (!checkLogged && !patchCustomer) {
        var ref, ref1, ref2, ref3, ref4;
        LoggerInstance.debug("HAS AXCTIVE SUBS - 1", customerActiveSubs);
        var postCustomerData = queryClient.getQueryData("postCustomer");
        var findCallerSaved = localStorage.getItem("findCaller");
        var findCaller = !!findCallerSaved && JSON.parse(findCallerSaved);
        var recognizedUser = customerActiveSubs === true && ((ref = findCaller) === null || ref === void 0 ? void 0 : ref.resultInfo) === "existingNextCustomer" && ((ref1 = findCaller) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.customerRef) === null || ref2 === void 0 ? void 0 : ref2.id);
        LoggerInstance.debug("findCaller", findCaller);
        // TODO take from findCaller(2)
        customerId = recognizedUser ? (ref3 = findCaller) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.customerRef) === null || ref4 === void 0 ? void 0 : ref4.id : postCustomerData === null || postCustomerData === void 0 ? void 0 : postCustomerData.id;
        queryEnabler = Boolean(customerId);
        LoggerInstance.debug("queryEnabler, customerID", queryEnabler, customerId);
    } else if (customerActiveSubs === false) {
        LoggerInstance.debug("HAS AXCTIVE SUBS - 2", customerActiveSubs, patchCustomer === null || patchCustomer === void 0 ? void 0 : patchCustomer.id);
        customerId = patchCustomer === null || patchCustomer === void 0 ? void 0 : patchCustomer.id;
        queryEnabler = Boolean(patchCustomer);
    } else {
        var ref5, ref6, ref7, ref8;
        LoggerInstance.debug("HAS AXCTIVE SUBS - 3", customerActiveSubs);
        var customerData = getFromLocalStorageByKey("customerData");
        var findCaller1 = getFromLocalStorageByKey("findCaller");
        var user = queryClient.getQueryData("user");
        var silentLogin = queryClient.getQueryData("silentLogin");
        var loggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) || false;
        customerId = (user === null || user === void 0 ? void 0 : (ref5 = user.customer) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : ref6.id) || (findCaller1 === null || findCaller1 === void 0 ? void 0 : (ref7 = findCaller1.customerRef) === null || ref7 === void 0 ? void 0 : ref7.id) || (customerData === null || customerData === void 0 ? void 0 : (ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : ref8.id);
        queryEnabler = Boolean(customerId) && loggedUser;
    }
    if (!customerId) {
        queryEnabler = false;
    }
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "customerData";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return customerService(customerId, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: queryEnabler,
            onSuccess: function(data) {
                var ref, ref1, ref2;
                // save customerData object into storage
                var oldCF = (data === null || data === void 0 ? void 0 : (ref = data[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.fiscalCode) || "";
                localStorage.setItem("customerData", JSON.stringify(data));
                localStorage.setItem("customerDataCf", oldCF);
                customOptions === null || customOptions === void 0 ? void 0 : (ref2 = customOptions.onSuccess) === null || ref2 === void 0 ? void 0 : ref2.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - customerData: ", error);
                localStorage.setItem("customerData", JSON.stringify(errorMock("customerData", error)));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * This method retrieve customer
 * @param customerId
 * @param customOptions
 * @returns
 */ export var useGetCustomer = function(customerId, customOptions) {
    var enabled = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled : true;
    var keysDependency = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.keyDependency) ? customOptions.keyDependency : "customerData";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return customerService(customerId, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: enabled,
            onSuccess: function(data) {
                var ref, ref1, ref2;
                // save customerData object into storage
                var oldCF = (data === null || data === void 0 ? void 0 : (ref = data[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.fiscalCode) || "";
                localStorage.setItem("customerData", JSON.stringify(data));
                localStorage.setItem("customerDataCf", oldCF);
                customOptions === null || customOptions === void 0 ? void 0 : (ref2 = customOptions.onSuccess) === null || ref2 === void 0 ? void 0 : ref2.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - customerData: ", error);
                localStorage.setItem("customerData", JSON.stringify(errorMock("customerData", error)));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
