export var organizeTermsAndConditionConsentCard = function(consentsCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    return {
        title: (consentsCms === null || consentsCms === void 0 ? void 0 : (ref = consentsCms.consents) === null || ref === void 0 ? void 0 : (ref1 = ref.card) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (consentsCms === null || consentsCms === void 0 ? void 0 : (ref2 = consentsCms.consents) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.card) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        subtitle: (consentsCms === null || consentsCms === void 0 ? void 0 : (ref4 = consentsCms.consents) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.card) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.terms) === null || ref6 === void 0 ? void 0 : ref6.title) || "",
        actionText: (consentsCms === null || consentsCms === void 0 ? void 0 : (ref7 = consentsCms.consents) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.card) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.terms) === null || ref9 === void 0 ? void 0 : ref9.customLinkLabel) || ""
    };
};
export var organizeTermsAndConditionConsents = function(consentsCmsContent) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    return {
        title: (consentsCmsContent === null || consentsCmsContent === void 0 ? void 0 : (ref = consentsCmsContent.consents) === null || ref === void 0 ? void 0 : (ref1 = ref.card) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (consentsCmsContent === null || consentsCmsContent === void 0 ? void 0 : (ref2 = consentsCmsContent.consents) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.modal) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        buttonText: (consentsCmsContent === null || consentsCmsContent === void 0 ? void 0 : (ref4 = consentsCmsContent.consents) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.modal) === null || ref5 === void 0 ? void 0 : ref5.buttonLabel) || "",
        button: (consentsCmsContent === null || consentsCmsContent === void 0 ? void 0 : (ref6 = consentsCmsContent.consents) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.card) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.terms) === null || ref8 === void 0 ? void 0 : ref8.customLinkLabel) || "",
        acceptAll: (consentsCmsContent === null || consentsCmsContent === void 0 ? void 0 : (ref9 = consentsCmsContent.consents) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.card) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.terms) === null || ref11 === void 0 ? void 0 : ref11.acceptAll) || "Desidero fornire il mio consenso rispetto a tutti i consensi"
    };
};
