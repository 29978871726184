import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useState } from "react";
import { tracking, retrieveBasePageName } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
export var useProductListTagging = function() {
    var queryClient = useQueryClient();
    var pageDatalayer = queryClient.getQueryData("pageDatalayer");
    var initialDatalayer = datalayerAdapter(pageDatalayer) || DEFAULT_DATALAYER;
    var ref = useState(initialDatalayer), currentDatalayer = ref[0], setCurrentDatalayer = ref[1];
    var trackProductLink = function(currentProduct) {
        var linkName = "".concat(retrieveBasePageName(undefined, currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.slug), ":swipe");
        var pageName = "".concat(retrieveBasePageName(undefined, currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.slug), ":").concat(currentDatalayer.event_name);
        tracking(_object_spread_props(_object_spread({}, currentDatalayer), {
            event_name: [
                "ui_interaction"
            ],
            page_name: pageName,
            link_name: linkName,
            event_action: "swipe",
            event_category: "sales",
            product_id: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.cmsId,
            product_category: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.category,
            product_name: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.slug,
            product_price: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.price,
            product_quantity: "1",
            product_target_segment: "consumer"
        }), "link");
    };
    return {
        currentDatalayer: currentDatalayer,
        setCurrentDatalayer: setCurrentDatalayer,
        trackProductLink: trackProductLink
    };
};
var datalayerAdapter = function(trackObj) {
    var ref, ref1;
    return _object_spread({
        event_name: trackObj.event,
        product_id: (ref = trackObj.pageProduct) === null || ref === void 0 ? void 0 : ref.product_id,
        product_category: (ref1 = trackObj.pageProduct) === null || ref1 === void 0 ? void 0 : ref1.product_category
    }, trackObj.opts);
};
var DEFAULT_DATALAYER = {
    event_name: []
};
