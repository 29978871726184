import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var getAnchors = function(widget) {
    return {
        anchors: widget.elements.map(function(element) {
            var ref;
            return _object_spread_props(_object_spread({}, element), {
                id: element.id || 0,
                image: element.image || "",
                title: element.title || "",
                text: element.description || "",
                url: ((ref = element.action) === null || ref === void 0 ? void 0 : ref.url) || ""
            });
        }),
        text: widget.title || "",
        description: (widget === null || widget === void 0 ? void 0 : widget.description) || ""
    };
};
