import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useQuery } from "react-query";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { ErrorCodes, errorMock } from "@vfit/consumer/data-access";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
/**
 * PayMean Service call
 * Method: GET
 * @param customerId
 * @param owningIndividualId
 * @param searchParams
 * @param customOptions
 */ var getPayMean = function(customerId, owningIndividualId, searchParams, customOptions) {
    return nextClient.get("".concat(API.CUSTOMER, "/").concat(customerId, "/individual/").concat(owningIndividualId, "/").concat(API.PAYMEAN), _object_spread({
        searchParams: _object_spread({}, searchParams)
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Get paymean
 * This method will replace all get paymean
 * @param customOptions
 */ export var useGetPaymean = function(customOptions, cmsGlobalConfig) {
    return useQuery([
        "payMean"
    ], function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12;
        var customerData = getFromLocalStorageByKey("customerData");
        var findCaller = getFromLocalStorageByKey("findCaller");
        // TODO NEW MOP: Remove MMT before production
        var isCustomerXBS = (findCaller === null || findCaller === void 0 ? void 0 : (ref = findCaller.individualRef) === null || ref === void 0 ? void 0 : (ref1 = ref.fiscalCode) === null || ref1 === void 0 ? void 0 : ref1.includes("XBS")) || (findCaller === null || findCaller === void 0 ? void 0 : (ref2 = findCaller.individualRef) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.fiscalCode) === null || ref3 === void 0 ? void 0 : ref3.includes("MMX"));
        var isGlobalBsNewMopFixedMobile = (cmsGlobalConfig === null || cmsGlobalConfig === void 0 ? void 0 : (ref4 = cmsGlobalConfig.globalconfig) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.newMop) === null || ref5 === void 0 ? void 0 : ref5.enableNewMopFixed) === "true" || (cmsGlobalConfig === null || cmsGlobalConfig === void 0 ? void 0 : (ref6 = cmsGlobalConfig.globalconfig) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.newMop) === null || ref7 === void 0 ? void 0 : ref7.enableNewMopMobile) === "true";
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : ref8.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref9 = findCaller.customerRef) === null || ref9 === void 0 ? void 0 : ref9.id);
        var owningIndividualId = (customerData === null || customerData === void 0 ? void 0 : (ref10 = customerData[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.owningIndividual) === null || ref11 === void 0 ? void 0 : ref11.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref12 = findCaller.individualRef) === null || ref12 === void 0 ? void 0 : ref12.contactId);
        // default AS-IS
        var defaultSearchParams = _object_spread({
            salesChannel: "selfService",
            checkExistingInstallment: "true",
            autoPay: "false",
            filters: "type==bankAccount,creditCard",
            usePaymeanFiltering: "false"
        }, (billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id) && {
            billingArrangementId: billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id
        });
        // new MOP
        var newMopSearchParams = _object_spread({
            salesChannel: "selfService",
            checkExistingInstallment: "true",
            checkOnGoingTNPDevice: "true",
            autoPay: "true",
            filters: "type==bankAccount,creditCard,debitCard,payPal;availableNewPaymeanTypes==filtered"
        }, (billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id) && {
            billingArrangementId: billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id
        });
        var isNewMopEnabled = isCustomerXBS && isGlobalBsNewMopFixedMobile;
        return getPayMean(customerId, owningIndividualId, isNewMopEnabled ? newMopSearchParams : defaultSearchParams);
    }, {
        enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) || false,
        onSuccess: function(data) {
            localStorage.setItem("payMean", JSON.stringify(data));
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - payMean: ", error, ErrorCodes.PAYMEAN);
            localStorage.setItem("payMean", JSON.stringify(errorMock("payMean", error, ErrorCodes.PAYMEAN)));
        }
    });
};
