export var DEFAULT_CUSTOMER_REFERENCE = {
    nameTitle: [
        {
            rel: "self",
            href: "/care/v1/nameTitle",
            id: "EMPTY",
            name: "EMPTY",
            displayName: " ",
            default: true
        },
        {
            rel: "self",
            href: "/care/v1/nameTitle",
            id: "Mr.",
            name: "Mr.",
            displayName: "Sig.",
            default: false
        },
        {
            rel: "self",
            href: "/care/v1/nameTitle",
            id: "Mrs.",
            name: "Mrs.",
            displayName: "Sig.ra",
            default: false
        },
        {
            rel: "self",
            href: "/care/v1/nameTitle",
            id: "Ms.",
            name: "Ms.",
            displayName: "Sig.na",
            default: false
        },
        {
            rel: "self",
            href: "/care/v1/nameTitle",
            id: "Dr.",
            name: "Dr.",
            displayName: "Dr.",
            default: false
        }, 
    ],
    identificationType: [
        {
            rel: "self",
            href: "/care/v1/identificationType",
            id: "1",
            name: "1",
            displayName: "Carta d'identit\xe0",
            default: false
        },
        {
            rel: "self",
            href: "/care/v1/identificationType",
            id: "2",
            name: "2",
            displayName: "Passaporto",
            default: false
        },
        {
            rel: "self",
            href: "/care/v1/identificationType",
            id: "3",
            name: "3",
            displayName: "Patente di guida",
            default: false
        }, 
    ]
};
export var organizeDocument = function(documentIdCardCMS, isRecognized) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22;
    return {
        title: isRecognized ? (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref = documentIdCardCMS.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.clientLogged) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.documentPreFilled) === null || ref2 === void 0 ? void 0 : ref2.title) || "Documento" : (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref3 = documentIdCardCMS.personalinfo) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.newDocument) === null || ref4 === void 0 ? void 0 : ref4.title) || "Documento",
        subtitle: isRecognized ? (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref5 = documentIdCardCMS.personalinfo) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.clientLogged) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.documentPreFilled) === null || ref7 === void 0 ? void 0 : ref7.description) || "Conferma o modifica i dati del tuo documento. Per verificare la tua identit\xe0, dovrai mostrare lo stesso documento durante la consegna o l'attivazione online della tua nuova SIM." : (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref8 = documentIdCardCMS.personalinfo) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.newDocument) === null || ref9 === void 0 ? void 0 : ref9.description) || "Inserisci i dati di un tuo documento. Per verificare la tua identit\xe0, dovrai mostrare lo stesso documento durante la consegna o l'attivazione online della tua nuova SIM.",
        docLable: (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref10 = documentIdCardCMS.personalinfo) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.selectInput) === null || ref11 === void 0 ? void 0 : ref11.docType) || "",
        docNumberLable: (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref12 = documentIdCardCMS.personalinfo) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.selectInput) === null || ref13 === void 0 ? void 0 : ref13.docNumber) || "",
        docExpirationDateLable: (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref14 = documentIdCardCMS.personalinfo) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.selectInput) === null || ref15 === void 0 ? void 0 : ref15.docExpirationDate) || "",
        countryLable: (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref16 = documentIdCardCMS.personalinfo) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.selectInput) === null || ref17 === void 0 ? void 0 : ref17.nation) || "",
        errorMessage: isRecognized ? (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref18 = documentIdCardCMS.personalinfo) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.clientLogged) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.documentPreFilled) === null || ref20 === void 0 ? void 0 : ref20.allFieldsAreRequired) || "Tutti i campi sono obbligatori" : (documentIdCardCMS === null || documentIdCardCMS === void 0 ? void 0 : (ref21 = documentIdCardCMS.personalinfo) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.newDocument) === null || ref22 === void 0 ? void 0 : ref22.allFieldsAreRequired) || "Tutti i campi sono obbligatori"
    };
};
export var SKELETON_SHAPE = {
    margins: [
        60,
        30
    ],
    tabletMargins: [
        32,
        15
    ],
    groups: [
        {
            heights: 45,
            marginBottom: 15
        },
        {
            heights: 60,
            marginBottom: 32
        },
        {
            heights: 60,
            marginBottom: 15
        },
        {
            heights: 75,
            repeat: 4,
            gap: 15
        },
        {
            heights: 45,
            marginBottom: 15
        }, 
    ]
};
