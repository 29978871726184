export var organizePortabilityCard = function(portabilityCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13;
    var dataToReturn = {
        chooseOperator: [
            {
                title: (portabilityCms === null || portabilityCms === void 0 ? void 0 : (ref = portabilityCms.portability) === null || ref === void 0 ? void 0 : (ref1 = ref.provider) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.providerTitles) === null || ref2 === void 0 ? void 0 : ref2.providerOne) || "",
                textInput: (portabilityCms === null || portabilityCms === void 0 ? void 0 : (ref3 = portabilityCms.portability) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.selectInput) === null || ref4 === void 0 ? void 0 : ref4.migrationCode) || "",
                dropDown: (portabilityCms === null || portabilityCms === void 0 ? void 0 : (ref5 = portabilityCms.portability) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.provider) === null || ref6 === void 0 ? void 0 : ref6.title) || ""
            },
            {
                title: (portabilityCms === null || portabilityCms === void 0 ? void 0 : (ref7 = portabilityCms.portability) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.provider) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.providerTitles) === null || ref9 === void 0 ? void 0 : ref9.providerTwo) || "",
                textInput: (portabilityCms === null || portabilityCms === void 0 ? void 0 : (ref10 = portabilityCms.portability) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.selectInput) === null || ref11 === void 0 ? void 0 : ref11.migrationCode) || "",
                dropDown: (portabilityCms === null || portabilityCms === void 0 ? void 0 : (ref12 = portabilityCms.portability) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.provider) === null || ref13 === void 0 ? void 0 : ref13.title) || ""
            }, 
        ]
    };
    return dataToReturn;
};
