import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { CART_TYPE, errorMock, getCostFromShoppingCartByType, getLastPaymentMethod, getOneTimeAmountFromShoppingCart, isMobileProduct, retrieveProduct } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { ISIAFlowType } from "@vfit/shared/models";
/**
 * This method calls the Authorize Service
 * Method: POST
 * SearchParams: salesChannel: selfService, isIBANValidationRequired: 'false'
 * @param payload
 * @param cartId
 * @param customOptions
 * @returns
 */ export var authorizeService = function(payload, cartId, customOptions) {
    return nextClient.post("".concat(API.PAYMENT_MANAGEMENT_ORDER, "/").concat(cartId, "/").concat(API.AUTHORIZE), payload, _object_spread({
        searchParams: _object_spread_props(_object_spread({}, nextClient.searchParams), {
            isIBANValidationRequired: "false"
        })
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This is a custom hook to manage the authorizeService.
 * @returns
 */ export var useAuthorizeMutation = function() {
    LoggerInstance.debug("HANDLE SELECT IN AUTHORIZE");
    var queryClient = useQueryClient();
    return useMutation("paymentAuth", function(param) {
        var payloadPaymeanType = param.payMeanType, siaFlowType = param.siaFlowType, authorizationOperationMethod = param.authorizationOperation;
        var ref, ref1;
        var product = retrieveProduct();
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        LoggerInstance.debug("HANDLE SELECT IN AUTHORIZE ENTER MUTATION CALL");
        var payMean = getFromLocalStorageByKey("payMean");
        var paymentData = getFromLocalStorageByKey("paymentData");
        var recurrence = (ref = retrieveProduct()) === null || ref === void 0 ? void 0 : ref.recurrence;
        var offerId = product === null || product === void 0 ? void 0 : (ref1 = product.offerId) === null || ref1 === void 0 ? void 0 : ref1.toString();
        var lastPayment = getLastPaymentMethod(payMean);
        // use'verify' only for already existing payment
        var authorizationOperation = authorizationOperationMethod;
        if (!authorizationOperation) authorizationOperation = payloadPaymeanType ? "capture" : "verify";
        // region Different Payload Fixed/mobile
        var price;
        var flowType;
        var payMeanPurpose;
        var ordDescr;
        var formattedValue;
        var payMeanType;
        var payMeanId;
        var IS_SUNRISE_COP_FLOW = isMobileProduct() || (product === null || product === void 0 ? void 0 : product.enableRecurringPayment);
        if (!IS_SUNRISE_COP_FLOW) {
            var ref2, ref3, ref4, ref5, ref6, ref7, ref8;
            var recurringAmount = ((ref2 = getCostFromShoppingCartByType(CART_TYPE.RECURRING)) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.formattedPrice) === null || ref3 === void 0 ? void 0 : ref3.toString()) || 0;
            price = product.isLockInProduct || product.isLockInMMProduct ? "".concat(recurringAmount, "€") : (ref4 = retrieveProduct()) === null || ref4 === void 0 ? void 0 : ref4.price;
            formattedValue = price.toString().replace(",", ".").replace("/", ",").replace("€", "&euro;"); // "27.90&euro;mese,3893198"
            flowType = "cop";
            payMeanPurpose = (paymentData === null || paymentData === void 0 ? void 0 : (ref5 = paymentData.orderPayment) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.orderItemPayments) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.orderItemOnBillPayment) === null || ref8 === void 0 ? void 0 : ref8.payMeanPurpose) || "onBill";
            ordDescr = formattedValue.concat(",".concat(recurrence, ",").concat(offerId, ","));
            payMeanType = payloadPaymeanType || (lastPayment === null || lastPayment === void 0 ? void 0 : lastPayment.type) || undefined;
            if (authorizationOperation === "verify" && payMean) payMeanId = lastPayment === null || lastPayment === void 0 ? void 0 : lastPayment.id;
        } else {
            var ref9;
            price = (ref9 = retrieveProduct()) === null || ref9 === void 0 ? void 0 : ref9.price;
            formattedValue = price.trim().toString().replace(",", ".").replace("/", ",").replace("€", "&euro;"); // "27.90&euro;mese,3893198"
            flowType = "Sunrise_Mobile_Cop";
            payMeanPurpose = siaFlowType === ISIAFlowType.RECURRENT ? "onBill" : "immediate";
            payMeanType = payloadPaymeanType || (lastPayment === null || lastPayment === void 0 ? void 0 : lastPayment.type) || undefined;
            LoggerInstance.debug("Authorize", payMean, payloadPaymeanType, lastPayment, authorizationOperation);
            if (payMean && authorizationOperation === "verify") payMeanId = lastPayment === null || lastPayment === void 0 ? void 0 : lastPayment.id;
            if (siaFlowType === ISIAFlowType.RECURRENT) {
                ordDescr = formattedValue.concat(",".concat(recurrence, ",").concat(offerId, ","));
            } else {
                var activationCost = getOneTimeAmountFromShoppingCart();
                var shippingCost = getCostFromShoppingCartByType(CART_TYPE.SHIPPING);
                var totalPrice = activationCost.price || 0;
                if (shippingCost.price) totalPrice += shippingCost.price;
                // totalPrice Es 6.99
                if (totalPrice) {
                    ordDescr = formattedValue.concat(",".concat(recurrence, ",").concat(offerId, ",").concat(totalPrice, "&euro;"));
                } else {
                    ordDescr = formattedValue.concat(",".concat(recurrence, ",").concat(offerId));
                }
            }
        }
        // endregion
        LoggerInstance.debug("NEW PAYMENT CARD ordDescr : ", ordDescr);
        var payload = {
            payMeanType: payMeanType,
            payMeanPurpose: payMeanPurpose,
            authorizationOperation: authorizationOperation,
            flowType: flowType,
            orddescr: ordDescr,
            payMeanId: payMeanId
        };
        LoggerInstance.debug("NEW PAYMENT CARD PAYLOAD : ", payload);
        return authorizeService(payload, cartId);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("authorize", JSON.stringify(data));
            LoggerInstance.debug("authorize SUCCESS", data);
            queryClient.setQueryData("authorize", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - authorize: ", error);
            queryClient.setQueryData("authorize", errorMock("authorize", error));
            localStorage.setItem("authorize", JSON.stringify(errorMock("authorize", error)));
        }
    });
};
/**
 * Authorize Query saves the complete authorize response
 */ export var useGetAuthorize = function() {
    return useQuery("authorize");
};
