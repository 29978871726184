import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { START_FROM_APP_LOCKIN, DXL_NBO_CAMPAIGNS } from "@vfit/consumer/data-access";
import { dxlClientJourneyApp, handleUseQuery, LoggerInstance, awsConsumerClient } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * This method calls che campaigns service
 * 'Recommendation/v2/queryProductRecommendation' from DXL Client
 * @param searchParams
 * @param customHeaders
 * @param isAwsClient
 */ var getActivableProduct = function(searchParams, customHeaders, isAwsClient) {
    if (isAwsClient) {
        return awsConsumerClient.get("api/".concat(API.ACTIVABLE_PRODUCTS), _object_spread({}, customHeaders && {
            headers: customHeaders
        }, searchParams && {
            searchParams: searchParams
        }));
    }
    return dxlClientJourneyApp.get(API.ACTIVABLE_PRODUCTS, _object_spread({}, customHeaders && {
        headers: customHeaders
    }, searchParams && {
        searchParams: searchParams
    }));
};
export var setDxlCampaigns = function(data) {
    var dxlCampaignsProducts = (data === null || data === void 0 ? void 0 : data.products) || [];
    var dxlNboCampaignsFixed = dxlCampaignsProducts === null || dxlCampaignsProducts === void 0 ? void 0 : dxlCampaignsProducts.filter(function(campaign) {
        var ref;
        return campaign === null || campaign === void 0 ? void 0 : (ref = campaign.type) === null || ref === void 0 ? void 0 : ref.includes("fixed");
    });
    if (dxlNboCampaignsFixed && (dxlNboCampaignsFixed === null || dxlNboCampaignsFixed === void 0 ? void 0 : dxlNboCampaignsFixed.length) > 0) {
        localStorage.setItem(START_FROM_APP_LOCKIN, "true");
        localStorage.setItem(DXL_NBO_CAMPAIGNS, JSON.stringify(dxlNboCampaignsFixed));
        return dxlNboCampaignsFixed;
    }
    return [];
};
/**
 * This method recalls the customer ID and use it to returns all the customer information saved
 *
 */ export var useDXLCampaigns = function(customOptions) {
    LoggerInstance.debug("********* useDXLCampaigns");
    var queryEnabler = false;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : [
        "dxlCampaigns"
    ];
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var ref;
            var appSession = getFromLocalStorageByKey("appSession");
            var appUserPhoneNumber = (appSession === null || appSession === void 0 ? void 0 : appSession.value) || "";
            var appVersion = (appSession === null || appSession === void 0 ? void 0 : (ref = appSession.appVersion) === null || ref === void 0 ? void 0 : ref.split(".")) || "0.0.0";
            var appVersionMajor = (appVersion === null || appVersion === void 0 ? void 0 : appVersion[0]) || "0";
            var appVersionMinor = (appVersion === null || appVersion === void 0 ? void 0 : appVersion[1]) || "0";
            var isNewAppVersion = appVersionMajor && appVersionMinor && +appVersionMajor > 12 && +appVersionMinor > 2;
            var customerUserAgent = (appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent) || "";
            var installationId = (appSession === null || appSession === void 0 ? void 0 : appSession.installationId) || "";
            var token = (appSession === null || appSession === void 0 ? void 0 : appSession.token) || "";
            var customHeaders = isNewAppVersion ? {
                "X-Device-UserAgent": customerUserAgent,
                "X-Device-Id": installationId,
                "vf-a-appconsumerauth": token
            } : {
                "X-Device-UserAgent": customerUserAgent,
                "X-Device-Id": installationId,
                clientId: "4002"
            };
            return getActivableProduct({
                msisdn: appUserPhoneNumber,
                productType: "NBA"
            }, customHeaders, isNewAppVersion);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: queryEnabler,
            onSuccess: function(data) {
                setDxlCampaigns(data);
                LoggerInstance.debug("useDXLCampaigns onSuccess : ", data);
            },
            onError: function(error) {
                LoggerInstance.debug("UseDxlCampaigns", error);
            }
        })
    };
    return handleUseQuery(options);
};
