import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
/**
 * This method is used retrieve the customer References from ApicWeb.
 * Method: GET
 * SearchParams: salesChannel: selfService
 * @returns
 */ var customerReferenceService = function(customOptions) {
    return nextClient.get(API.CUSTOMER_REFERENCE_DATA, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the customerReferenceService
 * @param customOptions
 * @returns
 */ export var useGetCustomerReference = function(customOptions) {
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "customerReference";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return customerReferenceService(customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            onSuccess: function(data) {
                localStorage.setItem("customerReference", JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - customerReference: ", error);
                localStorage.setItem("customerReference", JSON.stringify(errorMock("customerReference", error)));
            }
        })
    };
    return handleUseQuery(options);
};
