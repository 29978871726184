import { useGetDataFromStorage, organizeTechnology } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
export var useTechnologyCardsFlow = function() {
    var ref = useState(), tecnology = ref[0], setTecnology = ref[1];
    var ref1 = useState(false), isSuccess = ref1[0], setIsSuccess = ref1[1];
    var ref2 = useState(true), isLoading = ref2[0], setIsLoading = ref2[1];
    var ref3 = useState(false), isError = ref3[0], setIsError = ref3[1];
    var ref4 = useState(undefined), serviceability = ref4[0], setServiceability = ref4[1];
    /*
   Getting all the query related o check the responses data
   */ var offeringServiceability = useGetDataFromStorage("offeringServiceability");
    /*
   Setting technology and serviceabili
  */ useEffect(function() {
        var ref;
        if (offeringServiceability) {
            setIsLoading(false);
            var organizedTechnology = organizeTechnology(offeringServiceability);
            setTecnology(organizedTechnology);
            setIsSuccess(true);
            setServiceability(offeringServiceability);
        } else if ((ref = offeringServiceability) === null || ref === void 0 ? void 0 : ref.error) {
            setIsLoading(false);
            setIsError(true);
        }
    }, [
        !!offeringServiceability
    ]);
    return {
        serviceability: serviceability,
        tecnology: tecnology,
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError
    };
};
