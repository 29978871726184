import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * This method is used to edit the billing type from the customer choice.
 * Method: PUT
 * @param customerId
 * @param billingId
 * @param payload
 * @param customOptions
 * @returns
 */ var billDeliveryDetailsService = function(customerId, billingId, payload, customOptions) {
    return nextClient.put("".concat(API.CUSTOMER, "/").concat(customerId, "/").concat(API.BILLING_ACCOUNT, "/").concat(billingId, "/").concat(API.BILLING_DELIVERY_DETAILS), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run billDeliveryDetailsService using the createdAddress/billingCreatedAddress, the customerData and the billingAccount.
 * Call this after the customer, shoppingCart and delivery have just been created.
 * @returns
 * @deprecated
 */ export var useBillDeliveryDetails = function() {
    var ref, ref1, ref2, ref3;
    var queryClient = useQueryClient();
    var keyString = "billDeliveryDetails";
    var customerData = getFromLocalStorageByKey("customerData");
    var billingAccount = getFromLocalStorageByKey("billingAccount");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref1 = findCaller.customerRef) === null || ref1 === void 0 ? void 0 : ref1.id);
    var billingId = billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id;
    var customerEmail = customerData === null || customerData === void 0 ? void 0 : (ref2 = customerData[0]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.owningIndividual) === null || ref3 === void 0 ? void 0 : ref3.contactMedium.filter(function(item) {
        return item.type === "email";
    });
    var createdBillingAddress = queryClient.getQueryData([
        "billingCreatedAddress", 
    ]);
    var createdAddress = getFromLocalStorageByKey("createdAddress");
    return useMutation([
        "billDelivery"
    ], function(data) {
        var ref;
        var payload;
        var physicalAddress = createdBillingAddress || createdAddress || (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.billingAddress);
        switch(data){
            case "eBill":
                var ref1;
                payload = {
                    billFormat: data,
                    emailAddress: customerEmail === null || customerEmail === void 0 ? void 0 : (ref1 = customerEmail[0]) === null || ref1 === void 0 ? void 0 : ref1.emailAddress
                };
                break;
            case "paperBill":
                payload = {
                    billFormat: data,
                    physicalAddress: physicalAddress
                };
                break;
            default:
                break;
        }
        LoggerInstance.log("billDeliveryDetails payload : ", payload);
        return billDeliveryDetailsService(customerId, billingId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
export var useBillDelivery = function() {
    var queryClient = useQueryClient();
    var keyString = "billDeliveryDetails";
    return useMutation([
        "billDelivery"
    ], function(data) {
        var ref, ref1, ref2, ref3;
        var payload;
        var customerData = getFromLocalStorageByKey("customerData");
        var findCaller = getFromLocalStorageByKey("findCaller");
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var customerId = (findCaller === null || findCaller === void 0 ? void 0 : (ref = findCaller.customerRef) === null || ref === void 0 ? void 0 : ref.id) || (customerData === null || customerData === void 0 ? void 0 : (ref1 = customerData[0]) === null || ref1 === void 0 ? void 0 : ref1.id);
        var billingId = billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id;
        var customerEmail = customerData === null || customerData === void 0 ? void 0 : (ref2 = customerData[0]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.owningIndividual) === null || ref3 === void 0 ? void 0 : ref3.contactMedium.filter(function(item) {
            return item.type === "email";
        });
        var billFormat = data.billFormat, physicalAddress = data.physicalAddress, emailAddress = data.emailAddress;
        switch(billFormat){
            case "eBill":
                var ref4;
                payload = {
                    billFormat: billFormat,
                    emailAddress: emailAddress || (customerEmail === null || customerEmail === void 0 ? void 0 : (ref4 = customerEmail[0]) === null || ref4 === void 0 ? void 0 : ref4.emailAddress)
                };
                break;
            case "paperBill":
                payload = _object_spread({
                    billFormat: billFormat
                }, physicalAddress && {
                    physicalAddress: physicalAddress
                });
                break;
            default:
                break;
        }
        return billDeliveryDetailsService(customerId, billingId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
