var organizePortabilityData = function(portabilityCMSData, portability) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21;
    return {
        title: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref = portabilityCMSData.portability) === null || ref === void 0 ? void 0 : (ref1 = ref.provider) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        migrationCodeCtaLabel: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref2 = portabilityCMSData.portability) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.provider) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.operatorSelect) === null || ref4 === void 0 ? void 0 : ref4.findMigrationLabel) || "",
        skipMigrationCodeCheckboxText: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref5 = portabilityCMSData.portability) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.provider) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.operatorSelect) === null || ref7 === void 0 ? void 0 : ref7.skipMigrationCodeCheckboxText) || "",
        otherInformationText: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref8 = portabilityCMSData.portability) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.provider) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.operatorSelect) === null || ref10 === void 0 ? void 0 : ref10.smallInfo) || "",
        operatorsList: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref11 = portabilityCMSData.portability) === null || ref11 === void 0 ? void 0 : ref11.operatorList) || null,
        modalMigrationCode: {
            title: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref12 = portabilityCMSData.portability) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.modal) === null || ref13 === void 0 ? void 0 : ref13.title) || "",
            description: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref14 = portabilityCMSData.portability) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.modal) === null || ref15 === void 0 ? void 0 : ref15.description) || "",
            addMigrationCodeLabel: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref16 = portabilityCMSData.portability) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.modal) === null || ref17 === void 0 ? void 0 : ref17.buttonLabel) || "",
            noCode: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref18 = portabilityCMSData.portability) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.modal) === null || ref19 === void 0 ? void 0 : ref19.noCode) || "",
            migrationCodeLabel: (portabilityCMSData === null || portabilityCMSData === void 0 ? void 0 : (ref20 = portabilityCMSData.portability) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.modal) === null || ref21 === void 0 ? void 0 : ref21.codeNotFound) || ""
        },
        isMultipleOperators: (portability === null || portability === void 0 ? void 0 : portability.portabilityOperators) === "double" || false,
        findmigrationlabel: ""
    };
};
export { organizePortabilityData };
