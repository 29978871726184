import { useMemo } from "react";
import { useLocation } from "react-use";
export var useAutoDesc = function(product, cmsConfig) {
    var ref = cmsConfig || {}, enableAutoDesc = ref.enableAutoDesc, url = ref.url, _customString = ref.customString, customString = _customString === void 0 ? "" : _customString;
    var location = useLocation();
    var ctcAutoUrl = useMemo(function() {
        if (!(enableAutoDesc === "true") && url) {
            var ref, ref1;
            if (!customString) return url;
            // Calculating Url fallback + customString
            var urlParams = new URLSearchParams(url);
            var desc = urlParams.get("desc") || urlParams.get("DESC");
            var customDesc = "".concat(desc, "_").concat(customString);
            if (urlParams.has("DESC")) {
                urlParams.set("DESC", customDesc);
            } else {
                urlParams.set("desc", customDesc);
            }
            var queryParams = "";
            urlParams === null || urlParams === void 0 ? void 0 : urlParams.forEach(function(value, key) {
                queryParams += "".concat(key, "=").concat(value, "&");
            });
            var customUrl = "".concat(url.split("?")[0], "?").concat((ref = queryParams.split("?")) === null || ref === void 0 ? void 0 : (ref1 = ref[1]) === null || ref1 === void 0 ? void 0 : ref1.slice(0, -1));
            return customUrl;
        }
        // Auto mode calc
        var env = "App"; // Web -> to be added
        var icmp = new URLSearchParams(location.search).get("icmp") || "landing-page";
        var userType = "NEXT"; // 'LEGACY' -> to be added
        var origin = icmp ? "ORG" : "ORG"; // // 'ADV' -> to be added if no icmp
        var productLocation = location.pathname.replace(/^\//g, "").replace(/\//g, "-");
        var productName = product === null || product === void 0 ? void 0 : product.title.replace(/\s+/g, "-");
        var productId = product === null || product === void 0 ? void 0 : product.offerId;
        var productPrice = product === null || product === void 0 ? void 0 : product.price.replace(/[^0-9,]/g, "").replace(",", "");
        var autoUrl = "".concat(env, "_").concat(icmp, "_").concat(userType, "_").concat(origin, "_").concat(productLocation, "_").concat(productName, "-").concat(productId, "-").concat(productPrice);
        if (url) {
            var urlParams1 = new URLSearchParams(url);
            if (urlParams1.has("DESC")) {
                urlParams1.set("DESC", customString ? "".concat(autoUrl, "_").concat(customString) : autoUrl);
            } else {
                urlParams1.set("desc", customString ? "".concat(autoUrl, "_").concat(customString) : autoUrl);
            }
            var queryParams1 = "";
            urlParams1 === null || urlParams1 === void 0 ? void 0 : urlParams1.forEach(function(value, key) {
                queryParams1 += "".concat(key, "=").concat(value, "&");
            });
            var customUrl1 = "".concat(url.split("?")[0], "?").concat(queryParams1.split("?")[1].slice(0, -1));
            return customUrl1;
        } else {
            // returning empty if no desc in CMS config or no url -> fallback
            return "";
        }
    }, [
        product,
        cmsConfig,
        location.search,
        location.pathname
    ]);
    return {
        ctcAutoUrl: ctcAutoUrl
    };
};
