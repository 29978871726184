import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { useQuery } from "react-query";
import { API } from "@vfit/shared/data-access";
import { overrideOperatorsListMobile, reorderOperators, filterOperatorsMobile } from "./portabilityNumber.utils";
import { errorMock } from "../../utils/utils";
export var portabilityInfoService = function(cartId) {
    return nextClient.get("".concat(API.GET_PORTABILITY_INFO, "/").concat(cartId), {
        searchParams: {
            salesChannel: "selfService",
            portInInfoType: "ALL"
        }
    });
};
export var useGetPortabilityInfoMobile = function(CMSOperatorsList, CMSOrderList, CMSFilterList) {
    var shoppingCartSaved = localStorage.getItem("shoppingCart");
    var shoppingCart = !!shoppingCartSaved && shoppingCartSaved !== "undefined" && JSON.parse(shoppingCartSaved);
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var queryEnabler = !!cartId;
    return useQuery([
        "getPortabilityInfo"
    ], function() {
        return portabilityInfoService(cartId);
    }, {
        enabled: queryEnabler,
        onSuccess: function(data) {
            var filteredList = filterOperatorsMobile(data, CMSFilterList);
            var reorderedList = reorderOperators(filteredList, CMSOrderList);
            var overriddenData = overrideOperatorsListMobile(reorderedList, CMSOperatorsList);
            localStorage.setItem("portabilityInfo", JSON.stringify(overriddenData));
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - getPortabilityInfo", error);
            localStorage.setItem("portabilityInfo", JSON.stringify(errorMock("getPortabilityInfo")));
        }
    });
};
