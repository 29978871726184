import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-consumer-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { Loader } from "@googlemaps/js-api-loader";
import { loaderObj, useAddressGeocoder } from "@vfit/shared/hooks";
import { LoggerInstance } from "@vfit/shared/providers";
var GetAddressObjectFromString = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(text) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.t0 = Promise;
                    _ctx.next = 3;
                    return useAddressGeocoder(text);
                case 3:
                    _ctx.t1 = _ctx.sent;
                    return _ctx.abrupt("return", _ctx.t0.resolve.call(_ctx.t0, _ctx.t1));
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function GetAddressObjectFromString(text) {
        return _ref.apply(this, arguments);
    };
}();
export var retrieveAddress = function(add) {
    var addressString = "";
    Object.values(add).map(function(el) {
        if (el) addressString = addressString.concat(el, ", ");
        return null;
    });
    return addressString;
};
export var checkAddressFromValue = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(text) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.t0 = Promise;
                    _ctx.next = 3;
                    return GetAddressObjectFromString(text);
                case 3:
                    _ctx.t1 = _ctx.sent;
                    return _ctx.abrupt("return", _ctx.t0.resolve.call(_ctx.t0, _ctx.t1));
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function checkAddressFromValue(text) {
        return _ref.apply(this, arguments);
    };
}();
export function getAddressFromLatLng(lat, lng) {
    return _getAddressFromLatLng.apply(this, arguments);
}
function _getAddressFromLatLng() {
    _getAddressFromLatLng = _async_to_generator(regeneratorRuntime.mark(function _callee(lat, lng) {
        var mapsLoader, google, geocoder, latlng;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    mapsLoader = new Loader(loaderObj);
                    _ctx.next = 3;
                    return mapsLoader.load();
                case 3:
                    google = _ctx.sent;
                    if (!(google !== undefined)) {
                        _ctx.next = 8;
                        break;
                    }
                    geocoder = new google.maps.Geocoder();
                    latlng = new google.maps.LatLng(lat, lng);
                    return _ctx.abrupt("return", new Promise(function(resolve, reject) {
                        geocoder.geocode({
                            location: latlng
                        }, function(results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                if (results !== null) {
                                    var filteredResults = results.filter(function(result) {
                                        return result.address_components.length === 8;
                                    });
                                    if (filteredResults.length !== 0) {
                                        resolve(filteredResults[0]);
                                    } else resolve(results[0]);
                                } else {
                                    LoggerInstance.debug("No results found");
                                }
                            } else {
                                LoggerInstance.debug("Geocoder failed due to: ", status);
                            }
                            // eslint-disable-next-line prefer-promise-reject-errors
                            reject("Geolocalization error");
                        });
                    }));
                case 8:
                    return _ctx.abrupt("return", null);
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return _getAddressFromLatLng.apply(this, arguments);
}
export var composeAddress = function(value) {
    var ref, ref1;
    var address_components = value.address_components;
    var address = {
        street: "",
        streetNumber: "",
        city: "",
        province: "",
        postalCode: "",
        latitude: "",
        longitude: "",
        region: ""
    };
    address_components.forEach(function(v) {
        if (v.types.includes("route")) address.street = v.long_name;
        else if (v.types.includes("administrative_area_level_3")) address.city = v.long_name;
        else if (v.types.includes("administrative_area_level_2")) address.province = v.short_name;
        else if (v.types.includes("administrative_area_level_1")) address.region = v.short_name;
        else if (v.types.includes("postal_code")) address.postalCode = v.long_name;
        else if (v.types.includes("street_number")) address.streetNumber = v.long_name;
    });
    address.placeId = value.place_id;
    address.latitude = value === null || value === void 0 ? void 0 : (ref = value.geometry) === null || ref === void 0 ? void 0 : ref.location.lat();
    address.longitude = value === null || value === void 0 ? void 0 : (ref1 = value.geometry) === null || ref1 === void 0 ? void 0 : ref1.location.lng();
    return address;
};
