import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { checkWindow, useCustomerEligibility } from "@vfit/shared/data-access";
import { useEffect, useState } from "react";
import { TPO_TKN } from "@vfit/consumer/data-access";
export var useThirdPartyEligibilityCustomer = function(data) {
    var ref = useState({
        isLoading: true,
        isEligible: false,
        token: ""
    }), thirdPartyEligibilityCustomer = ref[0], setThirdPartyEligibilityCustomer = ref[1];
    var querystringParams = checkWindow() && window.location.search;
    var urlParams = new URLSearchParams(querystringParams);
    var token = (data === null || data === void 0 ? void 0 : data.customerToken) || (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("t"));
    var ref1 = useCustomerEligibility(), customerEligibilityData = ref1.data, isLoadingCustomerEligibility = ref1.isLoading, isSuccessCustomerEligibility = ref1.isSuccess, isErrorCustomerEligibility = ref1.isError, mutateCustomerEligibility = ref1.mutate;
    useEffect(function() {
        var ref, ref1;
        if (isLoadingCustomerEligibility) {
            setThirdPartyEligibilityCustomer(_object_spread_props(_object_spread({}, thirdPartyEligibilityCustomer), {
                isLoading: true,
                isEligible: false,
                token: token
            }));
        } else if (isErrorCustomerEligibility || isSuccessCustomerEligibility && (customerEligibilityData === null || customerEligibilityData === void 0 ? void 0 : (ref = customerEligibilityData.returnMsg) === null || ref === void 0 ? void 0 : ref.toLowerCase()) !== "success") {
            setThirdPartyEligibilityCustomer(_object_spread_props(_object_spread({}, thirdPartyEligibilityCustomer), {
                isLoading: false,
                isEligible: false,
                token: token
            }));
        } else if (isSuccessCustomerEligibility && (customerEligibilityData === null || customerEligibilityData === void 0 ? void 0 : (ref1 = customerEligibilityData.returnMsg) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) === "success") {
            setThirdPartyEligibilityCustomer(_object_spread_props(_object_spread({}, thirdPartyEligibilityCustomer), {
                isEligible: true,
                isLoading: false,
                token: token
            }));
            localStorage.setItem(TPO_TKN, token);
        }
    }, [
        isSuccessCustomerEligibility,
        isLoadingCustomerEligibility,
        isErrorCustomerEligibility
    ]);
    useEffect(function() {
        if (data.isStartFlow) {
            if (token) {
                mutateCustomerEligibility(_object_spread({
                    token: token
                }, (data === null || data === void 0 ? void 0 : data.startFlow) && {
                    startFlow: data.startFlow
                }));
            } else {
                setThirdPartyEligibilityCustomer({
                    isLoading: false,
                    isEligible: false,
                    token: undefined
                });
            }
        }
    }, [
        data.isStartFlow
    ]);
    return thirdPartyEligibilityCustomer;
};
