export var KEY;
(function(KEY) {
    KEY["isBase"] = "IsBase";
    KEY["isBaseMobile"] = "IsBaseMobile";
    KEY["isAdvanced"] = "IsAdvanced";
    KEY["IsAdvancedMobile"] = "IsAdvancedMobile";
    KEY["isPost"] = "IsPost";
    KEY["isEvolved"] = "IsEvolved";
    KEY["IsEvolvedMobile"] = "IsEvolvedMobile";
    KEY["isEditAddress"] = "IsEditAddress";
    KEY["IsUltraMobile"] = "IsUltraMobile";
    KEY["ShippingMethodCode"] = "deliveryShippingMethodCode";
})(KEY || (KEY = {}));
