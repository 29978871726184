import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { API } from "@vfit/shared/data-access";
import { nextClient, handleUseQuery } from "@vfit/shared/providers";
/**
 * Decoding token from Amdocs
 * I didn't use search params because they were automatically converted
 * preventing the sending of special characters
 * @param token
 * @param customOptions
 */ var partnerOfferValidateService = function(token, customOptions) {
    return nextClient.get("".concat(API.PARTNER_OFFER_VALIDATE, "?token=").concat(encodeURIComponent(token), "&salesChannel=selfService"), _object_spread({
        searchParams: undefined
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }));
};
export var usePartnerOfferValidate = function(token, customOptions) {
    var keysDependency = "partnerOfferValidate";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return partnerOfferValidateService(token, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            onSuccess: function(data) {
                var ref;
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
