import { useState } from "react";
export var UseGetLandingClub = function() {
    var ref = useState(false), showActivationModal = ref[0], setShowActivationModal = ref[1];
    var handleCloseModal = function() {
        setShowActivationModal(false);
    };
    var handleOpenActivation = function() {
        setShowActivationModal(true);
    };
    return {
        showActivationModal: showActivationModal,
        handleCloseModal: handleCloseModal,
        handleOpenActivation: handleOpenActivation
    };
};
