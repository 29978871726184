import { useMutation, useQueryClient } from "react-query";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
export var deleteShoppingCartItemService = function(cartId, cartItemId) {
    return nextClient.delete("".concat(API.SHOPPING_CART, "/").concat(cartId, "/").concat(API.CART_ITEM, "/").concat(cartItemId));
};
export var useDeleteItemShoppingCart = function() {
    var queryClient = useQueryClient();
    return useMutation("deleteShoppingCart", function(cartItemId) {
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        return deleteShoppingCartItemService(cartId, cartItemId);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("deleteShoppingCart", JSON.stringify(data));
            queryClient.setQueryData("deleteShoppingCart", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - deleteShoppingCart: ", error);
            queryClient.setQueryData("deleteShoppingCart", errorMock("deleteShoppingCart", error));
            localStorage.setItem("deleteShoppingCart", JSON.stringify(errorMock("deleteShoppingCart", error)));
        }
    });
};
