import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  font-size: ",
        ";\n  ",
        ";\n  color: ",
        ";\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    font-size: ",
        ";\n    ",
        ";\n    margin: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  font-family: ",
        ";\n  font-weight: 400;\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  border: 1px solid ",
        ";\n  border-radius: ",
        ";\n  min-height: ",
        ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: ",
        ";\n\n  @media (min-width: ",
        ") {\n    text-align: left;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  margin: 0;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  align-items: flex-end;\n  justify-content: center;\n  display: flex;\n\n  @media (min-width: ",
        ") {\n    justify-content: flex-start;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    font-size: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    font-size: ",
        ";\n  }\n\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var CardFrame = styled.div.withConfig({
    componentId: "sc-9b3f0334-0"
})(_templateObject(), pxToRem(82, 20), breakpoints.tablet, pxToRem(196, 40), breakpoints.desktop, pxToRem(202, 54));
export var Title = styled.h2.withConfig({
    componentId: "sc-9b3f0334-1"
})(_templateObject1(), fonts.exbold, pxToRem(35), pxToCssFont(35, 38), colors.$e60000, pxToRem(0, 0, 16), breakpoints.tablet, pxToRem(42), pxToCssFont(42, 52), pxToRem(0, 0, 24), breakpoints.desktop, pxToRem(0, 0, 20));
export var Subtitle = styled.h3.withConfig({
    componentId: "sc-9b3f0334-2"
})(_templateObject2(), pxToCssFont(24, 28), fonts.exbold, pxToRem(0, 0, 16), breakpoints.tablet, pxToCssFont(24, 30));
export var DisplayPriceBox = styled.section.withConfig({
    componentId: "sc-9b3f0334-3"
})(_templateObject3(), colors.$bebebe, pxToRem(5), pxToRem(100), pxToRem(20), breakpoints.tablet);
export var TitlePriceDisplay = styled.h6.withConfig({
    componentId: "sc-9b3f0334-4"
})(_templateObject4(), pxToCssFont(18, 24));
export var PricesBox = styled.div.withConfig({
    componentId: "sc-9b3f0334-5"
})(_templateObject5(), breakpoints.tablet);
export var Message = styled.div.withConfig({
    componentId: "sc-9b3f0334-6"
})(_templateObject6(), pxToRem(16, 0, 0), breakpoints.tablet, pxToRem(14), breakpoints.desktop, pxToRem(16));
