export var organizePortabilityLineCard = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    var prefix = dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.portability) === null || ref === void 0 ? void 0 : ref.activationFixedLine;
    var cardData = {
        title: (prefix === null || prefix === void 0 ? void 0 : prefix.title) || "",
        items: [
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref1 = prefix.choice) === null || ref1 === void 0 ? void 0 : ref1.option1) || "",
                value: "yes"
            },
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref2 = prefix.choice) === null || ref2 === void 0 ? void 0 : ref2.option2) || "",
                value: "internetOnly"
            },
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref3 = prefix.choice) === null || ref3 === void 0 ? void 0 : ref3.option3) || "",
                value: "no"
            }, 
        ]
    };
    var errorOnPortability = {
        erroMessage: {
            genericError: {
                title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref4 = dataFromCms.portability) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.errorOnPortability) === null || ref5 === void 0 ? void 0 : ref5.message) || ""
            }
        }
    };
    return {
        cardData: cardData,
        errorOnPortability: errorOnPortability
    };
};
