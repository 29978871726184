import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * @param cartId
 * @param payload
 * @returns
 */ var modifyMobileSimDeviceService = function(cartId, payload) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.MODIFY_MOBILE_SIM_DEVICE), payload, {
        notJsonParsing: true
    });
};
/**
 * @returns
 */ export var useModifyMobileSimDeviceService = function() {
    var queryClient = useQueryClient();
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    return useMutation("modifyMobileSimDevice", function(param) {
        var simOption = param.simOption;
        var ref, ref1, ref2, ref3, ref4, ref5;
        var simInformation = getFromLocalStorageByKey("simSelection");
        var productCharacteristicId = simInformation === null || simInformation === void 0 ? void 0 : (ref = simInformation.orderItems) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.product) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.childProduct) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.id;
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var cartItemId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref5 = shoppingCart.cartItem) === null || ref5 === void 0 ? void 0 : ref5[0].id;
        var payload = {
            productsInOrders: [
                {
                    orderItemId: cartItemId,
                    mobileSimProduct: {
                        type: "mobileSimProduct",
                        serialNumber: null,
                        productCharacteristic: [
                            {
                                id: productCharacteristicId,
                                name: "formato SIM",
                                value: simOption.id
                            }, 
                        ]
                    }
                }, 
            ],
            actualTechnology: ""
        };
        localStorage.setItem("modifyMobileSimDeviceId", JSON.stringify({
            simOptionId: (simOption === null || simOption === void 0 ? void 0 : simOption.id) || ""
        }));
        return modifyMobileSimDeviceService(cartId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("modifyMobileSimDevice", JSON.stringify(data));
            queryClient.setQueryData("modifyMobileSimDevice", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR on modifyMobileSimDevice: ", error);
            localStorage.setItem("modifyMobileSimDevice", JSON.stringify(error));
        }
    });
};
