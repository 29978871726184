import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * This method calls the checkoutService
 * @param cartId
 * @param customOptions
 * @returns
 */ var checkoutService = function(cartId, customOptions) {
    return nextClient.post("".concat(API.SHOPPING_CART, "/").concat(cartId, "/").concat(API.CHECKOUT), undefined, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to manage the checkoutService response.
 * @returns
 */ export var useCheckout = function() {
    var queryClient = useQueryClient();
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    return useMutation([
        "useCheckout"
    ], function(mutationCartId) {
        var ref;
        var cartId = mutationCartId || ((ref = shoppingCart) === null || ref === void 0 ? void 0 : ref.id);
        return checkoutService(cartId);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("checkout", JSON.stringify(data));
            queryClient.setQueryData("checkout", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - checkout: ", error);
            queryClient.setQueryData("checkout", errorMock("checkout", error));
            localStorage.setItem("checkout", JSON.stringify(errorMock("checkout", error)));
        }
    });
};
