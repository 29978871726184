export var MOCK_DXL_MFA_TOKEN = "eyJhbGciOiJSUzI1NiJ9.eyJzZXNzaW9uX2lkIjoiWkFzRmU3d1o3U2hQM2NhaVMyMzluemtBdHZHN3o5WjEiLCJzdWIiOiJ2b2RhZm9uZS1keGwiLCJpc3MiOiJqcy1sb2dpbiIsImF1ZCI6ImF1ZGllbmNlX3ZhbHVlIiwiZXhwIjoxNjc4NDQ3NDk5fQ.H3aD5b8E8sjK8ZZ4AwZJU0zjbHzRLhtwozy4XkmmeUSutIfr7Q5ulu23YKlyBk_hia41r7MTL13WfiMYBOBxYuGGW7OEFzG2xvUmbKD9nyf6phjD-aXSpdxMdcw2quX8PJhtnjOM9UUKL3Ghnv-QKPZpEUNVx_I8ck5_092_K2_u0FXhcL6YgTEQJlDIoQsPQ6uCMowgnMBlZ0ZBNJizIjmx6B8jG0RxStRyEvuNWAR3rc3wfGnUcnBVzLRfZY9GPWuwtnjnBACtkGFwQKETelnKRfjf_bp_oMyughznrqjpDbzs3yM0tHQAMz3ElWZGMOHRft4wRHDJjy23tNIBDA";
export var LoginErrorType;
(function(LoginErrorType) {
    LoginErrorType["MFA"] = "MFA";
    LoginErrorType["Enrollment"] = "ENROLLMENT";
    LoginErrorType["ErrorGeneric"] = "ERROR_GENERIC";
    LoginErrorType["ErrorDevice"] = "ERROR_DEVICE";
    LoginErrorType["Default"] = "";
    LoginErrorType["ErrorCredentials"] = "ERROR_CREDENTIALS";
})(LoginErrorType || (LoginErrorType = {}));
