export var organizePortabilityOperatorsCard = function(portabilityOperatorsCardCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    var prefix = portabilityOperatorsCardCMS === null || portabilityOperatorsCardCMS === void 0 ? void 0 : (ref = portabilityOperatorsCardCMS.portability) === null || ref === void 0 ? void 0 : ref.provider;
    return {
        title: (prefix === null || prefix === void 0 ? void 0 : (ref1 = prefix.operatorInfo) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (prefix === null || prefix === void 0 ? void 0 : (ref2 = prefix.operatorInfo) === null || ref2 === void 0 ? void 0 : ref2.description) || "",
        items: [
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref3 = prefix.operatorInfo) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.choice) === null || ref4 === void 0 ? void 0 : ref4.option1) || "",
                value: "single"
            },
            {
                title: (prefix === null || prefix === void 0 ? void 0 : (ref5 = prefix.operatorInfo) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.choice) === null || ref6 === void 0 ? void 0 : ref6.option2) || "",
                value: "double"
            }, 
        ]
    };
};
export var skeletonShape = {
    margins: [
        60,
        30
    ],
    tabletMargins: [
        32,
        15
    ],
    groups: [
        {
            heights: 45,
            marginBottom: 15
        },
        {
            heights: 60,
            marginBottom: 32
        },
        {
            heights: 60,
            marginBottom: 15
        },
        {
            heights: 75,
            repeat: 4,
            gap: 15
        },
        {
            heights: 45,
            marginBottom: 15
        }, 
    ]
};
