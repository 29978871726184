import { IAddressType } from "@vfit/shared/models";
var getShippingAddressDescription = function(shippingAddress, shippingData, owningIndividual) {
    var ref, ref1, ref2, ref3, ref4;
    var typePickupPoint = (shippingAddress || {}).typePickupPoint;
    var officialOfficePlaceholder = "Filiale";
    if (!typePickupPoint || !(shippingData === null || shippingData === void 0 ? void 0 : (ref = shippingData.addressData) === null || ref === void 0 ? void 0 : ref.postalOfficeDescription)) return "".concat((owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "", " ").concat((owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "");
    if (typePickupPoint === IAddressType.PO && (shippingData === null || shippingData === void 0 ? void 0 : (ref1 = shippingData.addressData) === null || ref1 === void 0 ? void 0 : ref1.postalOfficeDescription)) return "".concat(officialOfficePlaceholder, " ").concat(shippingData === null || shippingData === void 0 ? void 0 : (ref2 = shippingData.addressData) === null || ref2 === void 0 ? void 0 : ref2.postalOfficeDescription.toLowerCase());
    if (shippingData === null || shippingData === void 0 ? void 0 : (ref3 = shippingData.addressData) === null || ref3 === void 0 ? void 0 : ref3.postalOfficeDescription) return "".concat(shippingData === null || shippingData === void 0 ? void 0 : (ref4 = shippingData.addressData) === null || ref4 === void 0 ? void 0 : ref4.postalOfficeDescription.toLowerCase());
    return "".concat((owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "", " ").concat((owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "");
};
export { getShippingAddressDescription };
