import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useCampaignsInfo } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { useEffect, useState } from "react";
export var ErrorCode = {
    EMPTY_CAMPAIGNS_LIST: "EMPTY_CAMPAIGNS_LIST",
    NO_USEFUL_CAMPAIGNS_FOUND: "NO_USEFUL_CAMPAIGNS_FOUND",
    CAMPAIGNS_GENERIC_ERROR: "CAMPAIGNS_GENERIC_ERROR"
};
var checkCampaignEligibility = function(offeringServiceability, campaignsData) {
    var ref;
    if (campaignsData && campaignsData.length <= 0) {
        return {
            eligible: false,
            errorCode: ErrorCode.EMPTY_CAMPAIGNS_LIST
        };
    }
    LoggerInstance.debug("LockIn (checkCampaignEligibility) - offeringServiceability", offeringServiceability);
    LoggerInstance.debug("LockIn (checkCampaignEligibility) - campaignsData", campaignsData);
    LoggerInstance.debug("LockIn (checkCampaignEligibility) - WE HAVE BOTH SERVICEABILITY AND CAMPAIGNS' ITEMS");
    // get all the serviceability offeringId available
    var serviceabilityIds = offeringServiceability === null || offeringServiceability === void 0 ? void 0 : (ref = offeringServiceability.offeringServiceabilityItem) === null || ref === void 0 ? void 0 : ref.map(function(item) {
        var ref;
        return item === null || item === void 0 ? void 0 : (ref = item.offering) === null || ref === void 0 ? void 0 : ref.id;
    });
    LoggerInstance.debug("LockIn (checkCampaignEligibility) - serviceabilityIds", serviceabilityIds);
    // Technology Filter
    // const serviceabilityTech = localStorage.getItem('techOffer') && (JSON.parse(localStorage.getItem('techOffer') as string) as TechTypes);
    // FixedLine type filter campaignsOffer
    var fixedCampaignsList = (campaignsData === null || campaignsData === void 0 ? void 0 : campaignsData.filter(function(item) {
        var ref;
        return (item === null || item === void 0 ? void 0 : (ref = item.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "fixed";
    })) || [];
    if ((fixedCampaignsList === null || fixedCampaignsList === void 0 ? void 0 : fixedCampaignsList.length) === 0) {
        return {
            eligible: false,
            errorCode: ErrorCode.EMPTY_CAMPAIGNS_LIST
        };
    }
    LoggerInstance.debug("LockIn (checkCampaignEligibility) - fixed CAMPAIGNS LIST", fixedCampaignsList);
    var usefulFixedCampaignsList = (fixedCampaignsList === null || fixedCampaignsList === void 0 ? void 0 : fixedCampaignsList.filter(function(item) {
        var ref;
        return serviceabilityIds.includes(item === null || item === void 0 ? void 0 : (ref = item.fixedBundle) === null || ref === void 0 ? void 0 : ref.productOfferId);
    })) || [];
    LoggerInstance.debug("LockIn (checkCampaignEligibility) - usefulFixedCampaignsList", usefulFixedCampaignsList);
    if ((usefulFixedCampaignsList === null || usefulFixedCampaignsList === void 0 ? void 0 : usefulFixedCampaignsList.length) === 0) {
        return {
            eligible: false,
            errorCode: ErrorCode.EMPTY_CAMPAIGNS_LIST
        };
    }
    //  the fixed campaigns available
    var lockInOffersFound = usefulFixedCampaignsList === null || usefulFixedCampaignsList === void 0 ? void 0 : usefulFixedCampaignsList.sort(function(item1, item2) {
        return parseInt(item2.score, 10) - parseInt(item1.score, 10);
    });
    LoggerInstance.debug("LockIn (checkCampaignEligibility) - lockInOffersFound", lockInOffersFound);
    if (Object.keys(lockInOffersFound).length !== 0) {
        LoggerInstance.debug("LockIn (checkCampaignEligibility) - lockInOfferFound", lockInOffersFound[0]);
        localStorage.setItem("lockinOfferFound", JSON.stringify(lockInOffersFound[0]));
        return {
            eligible: true,
            lockInOfferFound: lockInOffersFound[0],
            errorCode: ""
        };
    }
    return {
        eligible: false,
        errorCode: ErrorCode.NO_USEFUL_CAMPAIGNS_FOUND
    };
};
/**
 * Lock-In Campaign Hook Flow
 */ export var useLockInFlow = function() {
    var ref = useState({
        eligible: null,
        errorCode: ""
    }), checkResults = ref[0], setCheckResults = ref[1];
    var offeringServiceability = localStorage.getItem("offeringServiceability") && JSON.parse(localStorage.getItem("offeringServiceability"));
    var ref1 = useCampaignsInfo(), campaignsData = ref1.data, campaignsIsSuccess = ref1.isSuccess, campaignsIsError = ref1.isError, startLockInFlow = ref1.refetch;
    // LOCKIN FLAG
    useEffect(function() {
        if (checkResults.eligible == null) {
            if (!!offeringServiceability && campaignsData && campaignsIsSuccess) {
                setCheckResults(_object_spread({}, checkCampaignEligibility(offeringServiceability, campaignsData)));
            }
            if (campaignsIsError) {
                setCheckResults({
                    eligible: false,
                    errorCode: ErrorCode.CAMPAIGNS_GENERIC_ERROR
                });
            }
        }
    }, [
        offeringServiceability,
        campaignsData,
        campaignsIsSuccess,
        campaignsIsError
    ]);
    return {
        startLockInFlow: startLockInFlow,
        isSuccess: !!(campaignsData && campaignsIsSuccess),
        checkResults: checkResults,
        campaignsData: campaignsData
    };
};
