import { formatQueryParam } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { checkWindow } from "@vfit/shared/data-access";
import { useEffect, useState } from "react";
import { getMappingDeviceCharacteristics, getSku } from "./useDevice.utils";
/**
 * Test window location: Next router slow
 */ var useQueryStateWindow = function() {
    var querystringParams = checkWindow() && window.location.search;
    var urlParams = checkWindow() && new URLSearchParams(querystringParams);
    var ref = useState(checkWindow() && urlParams.get("color")), color = ref[0], setColor = ref[1];
    var ref1 = useState(checkWindow() && urlParams.get("size")), size = ref1[0], setSize = ref1[1];
    var setQueryParams = function(colorQueryParam, sizeQueryParam) {
        var url = new URL(window.location);
        url.searchParams.set("color", formatQueryParam(colorQueryParam));
        url.searchParams.set("size", formatQueryParam(sizeQueryParam));
        setColor(colorQueryParam);
        setSize(sizeQueryParam);
        window.history.pushState(null, "", url.toString());
    };
    return {
        setQueryParams: setQueryParams,
        color: color,
        size: size
    };
};
/**
 * Returns the selectedSku from a list of Skus, Characteristics JSON Object, an the QUERY STRING PARAMS.
 * @param skus
 * @param characteristics
 * @returns
 */ export var useSkuWindowLocation = function(skus, characteristics) {
    var ref = useQueryStateWindow(), color = ref.color, size = ref.size, setQueryParams = ref.setQueryParams;
    var ref1 = useState(undefined), returnedSku = ref1[0], setReturnedSku = ref1[1];
    var setQueryStringParams = function(sku) {
        var ref, ref1;
        var foundedSize = characteristics === null || characteristics === void 0 ? void 0 : (ref = characteristics.sizes) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.find(function(el) {
            return (el === null || el === void 0 ? void 0 : el.id) == (sku === null || sku === void 0 ? void 0 : sku.idSize);
        });
        if (formatQueryParam(foundedSize === null || foundedSize === void 0 ? void 0 : foundedSize.value) !== size || formatQueryParam(sku === null || sku === void 0 ? void 0 : sku.displayNameColor) !== color) {
            setQueryParams(sku === null || sku === void 0 ? void 0 : sku.displayNameColor, foundedSize === null || foundedSize === void 0 ? void 0 : foundedSize.value);
        }
    };
    // region FILTER
    var changeSku = function(sku) {
        var ref, ref1;
        var foundedSize = characteristics === null || characteristics === void 0 ? void 0 : (ref = characteristics.sizes) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.find(function(el) {
            return (el === null || el === void 0 ? void 0 : el.id) == (sku === null || sku === void 0 ? void 0 : sku.idSize);
        });
        filter(formatQueryParam(sku.displayNameColor), formatQueryParam(foundedSize.value));
    };
    var filter = function(colorFilter, sizeFilter) {
        var mappedDeviceCharacteristics = getMappingDeviceCharacteristics({
            color: colorFilter,
            size: sizeFilter
        }, skus, characteristics);
        var returnedSkuLocal = getSku(skus, mappedDeviceCharacteristics);
        if (returnedSkuLocal) {
            LoggerInstance.debug("UPDATING RETURN AND QUERY");
            setQueryStringParams(returnedSkuLocal);
            setReturnedSku(returnedSkuLocal);
        }
    };
    // endregion
    useEffect(function() {
        filter(color, size);
    }, []);
    return {
        selectedSku: returnedSku,
        changeSku: changeSku
    };
};
