import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { errorMock, getLastPaymentMethod } from "@vfit/consumer/data-access";
/**
 * API service to validate user's payment with IBAN
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ export var validateIbansService = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.VALIDATE_IBANS), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Method to add objectItems to the payload array
 * @param array
 * @param ids
 * @param payMean
 */ var addPayloadObject = function(array, ids, payMean) {
    // eslint-disable-next-line array-callback-return
    ids.map(function(el, index) {
        if (!el) return;
        if (index === 0) array.push({
            orderActionId: el,
            orderItemOnBillPayment: {
                payMeanPurpose: "onBill",
                selectedPayMean: payMean
            },
            lob: "WL"
        });
        if (index > 0) {
            array.push(_object_spread_props(_object_spread({}, array[index - 1]), {
                orderActionId: "".concat(array[index - 1].orderActionId, ",").concat(el)
            }));
        }
    });
};
/**
 * This method is a custom Mutation hook to handle the validateIbans Service.
 * @returns
 */ export var useValidateIbansFixed = function() {
    var queryClient = useQueryClient();
    return useMutation("validateIbans", function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6;
        var payload;
        // Get the shoppingCart obj from storage
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var payMeanData = getFromLocalStorageByKey("payMean");
        var authorization = getFromLocalStorageByKey("authorizationRecurring");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var cartItems = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : ref[0];
        var payMeans = getLastPaymentMethod(payMeanData);
        var dataSimId = cartItems === null || cartItems === void 0 ? void 0 : (ref1 = cartItems.dataSim) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.id;
        var fixedVoiceId = cartItems === null || cartItems === void 0 ? void 0 : (ref3 = cartItems.fixedVoice) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.id;
        var internetId = cartItems === null || cartItems === void 0 ? void 0 : (ref5 = cartItems.internet) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : ref6.id;
        if (authorization && payMeans) {
            var ref7, ref8, ref9, ref10, ref11, ref12;
            var selectedPayMean = {
                id: payMeans === null || payMeans === void 0 ? void 0 : payMeans.id,
                bankCode: payMeans === null || payMeans === void 0 ? void 0 : payMeans.bankCode,
                branchCode: payMeans === null || payMeans === void 0 ? void 0 : payMeans.branchCode,
                holderName: payMeans === null || payMeans === void 0 ? void 0 : payMeans.holderName,
                ibanNumber: payMeans === null || payMeans === void 0 ? void 0 : payMeans.ibanNumber,
                accountNumber: payMeans === null || payMeans === void 0 ? void 0 : payMeans.accountNumber,
                isVerified: false,
                ppbId: (ref7 = authorization.authorization) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.payMean) === null || ref8 === void 0 ? void 0 : ref8.ppbId,
                object_type: (ref9 = authorization.authorization) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.payMean) === null || ref10 === void 0 ? void 0 : ref10.object_type,
                type: (ref11 = authorization.authorization) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.payMean) === null || ref12 === void 0 ? void 0 : ref12.type
            };
            var orderItemPayments = [];
            addPayloadObject(orderItemPayments, [
                dataSimId,
                fixedVoiceId,
                internetId
            ], selectedPayMean);
            payload = {
                orderPayment: {
                    barId: billingAccount.id,
                    orderItemPayments: orderItemPayments
                }
            };
        }
        return !!payload && validateIbansService(cartId, payload);
    }, {
        onSuccess: function(data) {
            LoggerInstance.debug("SUCCESS", data);
            queryClient.setQueryData("validateIbansQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - validateIbansQuery: ", error);
            queryClient.setQueryData("validateIbansQuery", errorMock("validateIbansQuery"));
            localStorage.setItem("validateIbansQuery", JSON.stringify(errorMock("validateIbansQuery")));
        }
    });
};
/**
 * This method is a custom Mutation hook to handle the validateIbans Service.
 * @returns
 */ export var useValidateIbansMobile = function() {
    var queryClient = useQueryClient();
    return useMutation("validateIbans", function() {
        var ref;
        var payload;
        // Get the shoppingCart obj from storage
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var payMeanData = getFromLocalStorageByKey("payMean");
        var authorization = getFromLocalStorageByKey("authorizationRecurring");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var cartItems = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : ref[0];
        var cartItemId = cartItems === null || cartItems === void 0 ? void 0 : cartItems.id;
        var payMeans = getLastPaymentMethod(payMeanData);
        if (authorization && payMeans) {
            var ref1, ref2, ref3, ref4, ref5, ref6;
            var selectedPayMean = {
                id: payMeans === null || payMeans === void 0 ? void 0 : payMeans.id,
                bankCode: payMeans === null || payMeans === void 0 ? void 0 : payMeans.bankCode,
                branchCode: payMeans === null || payMeans === void 0 ? void 0 : payMeans.branchCode,
                holderName: payMeans === null || payMeans === void 0 ? void 0 : payMeans.holderName,
                ibanNumber: payMeans === null || payMeans === void 0 ? void 0 : payMeans.ibanNumber,
                accountNumber: payMeans === null || payMeans === void 0 ? void 0 : payMeans.accountNumber,
                isVerified: false,
                ppbId: (ref1 = authorization.authorization) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.payMean) === null || ref2 === void 0 ? void 0 : ref2.ppbId,
                object_type: (ref3 = authorization.authorization) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.payMean) === null || ref4 === void 0 ? void 0 : ref4.object_type,
                type: (ref5 = authorization.authorization) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.payMean) === null || ref6 === void 0 ? void 0 : ref6.type
            };
            var orderItemPayments = [];
            addPayloadObject(orderItemPayments, [
                cartItemId
            ], selectedPayMean);
            payload = {
                orderPayment: {
                    barId: billingAccount.id,
                    orderItemPayments: [
                        {
                            orderActionId: cartItemId,
                            orderItemOnBillPayment: {
                                payMeanPurpose: "onBill",
                                selectedPayMean: selectedPayMean
                            }
                        }, 
                    ]
                }
            };
        }
        return !!payload && validateIbansService(cartId, payload);
    }, {
        onSuccess: function(data) {
            queryClient.setQueryData("validateIbansQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - validateIbansQuery: ", error);
            queryClient.setQueryData("validateIbansQuery", errorMock("validateIbansQuery", error));
            localStorage.setItem("validateIbansQuery", JSON.stringify(errorMock("validateIbansQuery")));
        }
    });
};
