import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CMS_CONFIG, ErrorCodes, errorMock, getCurrentUserType, isLockInIdServiceable, IUserType, useCmsConfig, useGetCustomerReference } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { API, getFromLocalStorageByKey, openPopup, PAGES } from "@vfit/shared/data-access";
import { resetData } from "@vfit/shared/providers";
import { useEffect } from "react";
import { formatDate } from "./contactLockInCard.utils";
import { retrieveOwningIndividual } from "../../checkout.utils";
import { useCheckout } from "../../../iBuyFixed.context";
export var useContactLockInCard = function(props) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    var handleOnClose = props.handleOnClose;
    var ref10 = useCheckout(), product = ref10.product, customerFlow = ref10.customerFlow, customerAsyncInfo = ref10.customerAsyncInfo, owningData = ref10.owningData, checkoutErrors = ref10.checkoutErrors, isModalButtonSticky = ref10.isModalButtonSticky, lockInFlow = ref10.lockInFlow, setIsStartCustomerFlow = ref10.setIsStartCustomerFlow, setIsStartLockInFlow = ref10.setIsStartLockInFlow, setCustomerId = ref10.setCustomerId, setOwningData = ref10.setOwningData, setFindCaller = ref10.setFindCaller;
    var findCallerPostOtp = getFromLocalStorageByKey("findCaller");
    var queryClient = useQueryClient();
    var countries = getFromLocalStorageByKey("getCountry");
    var ref11 = useGetCustomerReference(), isReferenceSuccess = ref11.isSuccess, isReferenceLoading = ref11.isLoading, isReferenceError = ref11.isError, errorCustomerReference = ref11.error;
    var associateCustomer = customerFlow.associateCustomer;
    var genericError = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GENERIC_ERROR);
    var ref12 = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_ORDER_ERROR), errors = ref12.errorcomponent.errors;
    var blockedCustomer = errors === null || errors === void 0 ? void 0 : errors.find(function(item) {
        return (item === null || item === void 0 ? void 0 : item.error) === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED;
    });
    var isLoadingLockin = (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref = lockInFlow.campaigns) === null || ref === void 0 ? void 0 : ref.isLoading) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref1 = lockInFlow.accept) === null || ref1 === void 0 ? void 0 : ref1.isLoading) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref2 = lockInFlow.shoppingCart) === null || ref2 === void 0 ? void 0 : ref2.isLoading) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref3 = lockInFlow.associateCustomer) === null || ref3 === void 0 ? void 0 : ref3.isLoading);
    var isLoadingCustomerInfo = isReferenceLoading || !isReferenceSuccess || !isLockInIdServiceable() && !associateCustomer.isSuccess;
    var isErrorLockin = (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref4 = lockInFlow.campaigns) === null || ref4 === void 0 ? void 0 : ref4.isError) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref5 = lockInFlow.accept) === null || ref5 === void 0 ? void 0 : ref5.isError) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref6 = lockInFlow.shoppingCart) === null || ref6 === void 0 ? void 0 : ref6.isError) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref7 = lockInFlow.associateCustomer) === null || ref7 === void 0 ? void 0 : ref7.isError);
    var isErrorCustomer = (customerFlow === null || customerFlow === void 0 ? void 0 : (ref8 = customerFlow.findcaller) === null || ref8 === void 0 ? void 0 : ref8.isError) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref9 = customerFlow.associateCustomer) === null || ref9 === void 0 ? void 0 : ref9.isError);
    var preconditionCustomer = function() {
        if (associateCustomer.isSuccess || associateCustomer.isError || isReferenceError) {
            var ref, ref1, ref2, ref3, ref4, ref5;
            setFindCaller(_object_spread_props(_object_spread({}, findCallerPostOtp, (customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : ref1.paymean) && {
                paymean: customerFlow === null || customerFlow === void 0 ? void 0 : (ref2 = customerFlow.findcaller) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.data) === null || ref3 === void 0 ? void 0 : ref3.paymean
            }), {
                showOtp: false,
                verifyOtp: "",
                isVerified: true
            }));
            if (associateCustomer.isError && ((ref4 = associateCustomer.error) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.error) === null || ref5 === void 0 ? void 0 : ref5.statusText) === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED) {
                var ref6, ref7, ref8;
                var handleClose = function() {
                    if (handleOnClose) {
                        resetData(queryClient);
                        handleOnClose();
                    }
                };
                var goBackAndReset = function() {
                    var ref;
                    if (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref = blockedCustomer.button) === null || ref === void 0 ? void 0 : ref.urlForRedirect) {
                        var ref1;
                        openPopup((blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref1 = blockedCustomer.button) === null || ref1 === void 0 ? void 0 : ref1.urlForRedirect) || "", function() {
                            if (handleOnClose) {
                                resetData(queryClient);
                                handleOnClose();
                            }
                        });
                    } else if (handleOnClose) {
                        resetData(queryClient);
                        handleOnClose();
                    }
                };
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref8 = checkoutErrors.showAndTrackError) === null || ref8 === void 0 ? void 0 : ref8.call(checkoutErrors, genericError, function() {
                    return handleClose();
                }, associateCustomer.error, (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : blockedCustomer.title) || "Attenzione", (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : blockedCustomer.message) || "I seguenti articoli non sono attivabili dal cliente selezionato", function() {
                    return goBackAndReset();
                }, (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref6 = blockedCustomer.button) === null || ref6 === void 0 ? void 0 : ref6.title) || "Ti richiamiamo", function() {
                    var ref;
                    return openPopup((product === null || product === void 0 ? void 0 : (ref = product.genericErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.url) || "", handleClose);
                }, product === null || product === void 0 ? void 0 : (ref7 = product.genericErrorCallMeNow) === null || ref7 === void 0 ? void 0 : ref7.label, isModalButtonSticky);
            } else if (associateCustomer.isError || isReferenceError) {
                var ref9, ref10, ref11, ref12;
                var error;
                if (associateCustomer.isError && (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref9 = customerAsyncInfo.customerInfo) === null || ref9 === void 0 ? void 0 : ref9.isError)) {
                    var ref13;
                    error = customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref13 = customerAsyncInfo.customerInfo) === null || ref13 === void 0 ? void 0 : ref13.error;
                }
                if (isReferenceError && errorCustomerReference) {
                    error = errorMock(ErrorCodes.REFERENCE_CUSTOMER, errorCustomerReference);
                }
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref12 = checkoutErrors.showAndTrackError) === null || ref12 === void 0 ? void 0 : ref12.call(checkoutErrors, genericError, handleOnClose, _object_spread_props(_object_spread({}, error), {
                    isBlocking: true
                }), "", "", handleOnClose, (genericError === null || genericError === void 0 ? void 0 : (ref10 = genericError.genericerror) === null || ref10 === void 0 ? void 0 : ref10.buttonLabel) || "", function() {
                    var ref;
                    return openPopup((product === null || product === void 0 ? void 0 : (ref = product.genericErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.url) || "", function() {
                        return handleOnClose;
                    });
                }, product === null || product === void 0 ? void 0 : (ref11 = product.genericErrorCallMeNow) === null || ref11 === void 0 ? void 0 : ref11.label, isModalButtonSticky);
            }
        }
    };
    var checkStartLockInFlow = function() {
        var userType = getCurrentUserType();
        return isLockInIdServiceable() && (userType === IUserType.NEXT_USER_ACTIVE || userType === IUserType.LOGGED_USER);
    };
    var showErrorLockIn = function() {
        var ref, ref1, ref2, ref3, ref4, ref5;
        var error = (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref = lockInFlow.campaigns) === null || ref === void 0 ? void 0 : ref.error) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref1 = lockInFlow.accept) === null || ref1 === void 0 ? void 0 : ref1.error) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref2 = lockInFlow.shoppingCart) === null || ref2 === void 0 ? void 0 : ref2.error) || (lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref3 = lockInFlow.associateCustomer) === null || ref3 === void 0 ? void 0 : ref3.error);
        checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref5 = checkoutErrors.showAndTrackError) === null || ref5 === void 0 ? void 0 : ref5.call(checkoutErrors, genericError, function() {
            var ref;
            lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref = lockInFlow.resetLockInFlow) === null || ref === void 0 ? void 0 : ref.call(lockInFlow);
            handleOnClose === null || handleOnClose === void 0 ? void 0 : handleOnClose();
        }, _object_spread_props(_object_spread({}, error), {
            isBlocking: true
        }), undefined, undefined, function() {
            var ref;
            lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref = lockInFlow.resetLockInFlow) === null || ref === void 0 ? void 0 : ref.call(lockInFlow);
            handleOnClose === null || handleOnClose === void 0 ? void 0 : handleOnClose();
        }, (genericError === null || genericError === void 0 ? void 0 : (ref4 = genericError.genericerror) === null || ref4 === void 0 ? void 0 : ref4.buttonLabel) || "Chiudi");
    };
    var showError = function() {
        var ref, ref1, ref2, ref3;
        var error = (customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : ref.error) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref1 = customerFlow.associateCustomer) === null || ref1 === void 0 ? void 0 : ref1.error);
        checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref3 = checkoutErrors.showAndTrackError) === null || ref3 === void 0 ? void 0 : ref3.call(checkoutErrors, genericError, function() {
            var ref;
            lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref = lockInFlow.resetLockInFlow) === null || ref === void 0 ? void 0 : ref.call(lockInFlow);
            handleOnClose === null || handleOnClose === void 0 ? void 0 : handleOnClose();
        }, _object_spread_props(_object_spread({}, error), {
            isBlocking: true
        }), undefined, undefined, function() {
            var ref;
            lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref = lockInFlow.resetLockInFlow) === null || ref === void 0 ? void 0 : ref.call(lockInFlow);
            handleOnClose === null || handleOnClose === void 0 ? void 0 : handleOnClose();
        }, (genericError === null || genericError === void 0 ? void 0 : (ref2 = genericError.genericerror) === null || ref2 === void 0 ? void 0 : ref2.buttonLabel) || "Chiudi");
    };
    useEffect(function() {
        preconditionCustomer();
        if (associateCustomer.isSuccess) {
            var ref;
            var customer = (ref = customerAsyncInfo.customerInfo.data) === null || ref === void 0 ? void 0 : ref[0];
            if (customer) {
                var retrivedIndividual = retrieveOwningIndividual(customer, countries);
                setOwningData(_object_spread_props(_object_spread({}, owningData), {
                    owningIndividual: _object_spread({}, owningData.owningIndividual, retrivedIndividual)
                }));
                setCustomerId(customer.id);
            }
        }
    }, [
        associateCustomer,
        isReferenceError,
        customerAsyncInfo
    ]);
    useEffect(function() {
        if (isErrorLockin) {
            showErrorLockIn();
        }
    }, [
        isErrorLockin
    ]);
    useEffect(function() {
        if (isErrorCustomer) {
            showError();
        }
    }, [
        isErrorCustomer
    ]);
    /**
   * IF is a lockin product start campaign, accept ecc (LockinFlow)
   * else continue asis with mm fallback product
   */ var initContactLockIn = function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12;
        var customerData = getFromLocalStorageByKey("customerData");
        setFindCaller(_object_spread_props(_object_spread({}, findCallerPostOtp), {
            showOtp: false
        }));
        setOwningData(_object_spread_props(_object_spread({}, owningData), {
            isValid: true,
            owningIndividual: {
                firstName: customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.firstName,
                lastName: customerData === null || customerData === void 0 ? void 0 : (ref2 = customerData[0]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.owningIndividual) === null || ref3 === void 0 ? void 0 : ref3.lastName,
                contactMedium: (customerData === null || customerData === void 0 ? void 0 : (ref4 = customerData[0]) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.owningIndividual) === null || ref5 === void 0 ? void 0 : ref5.contactMedium) || [],
                fiscalCode: (customerData === null || customerData === void 0 ? void 0 : (ref6 = customerData[0]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.owningIndividual) === null || ref7 === void 0 ? void 0 : ref7.fiscalCode) || "",
                nation: (customerData === null || customerData === void 0 ? void 0 : (ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.owningIndividual) === null || ref9 === void 0 ? void 0 : ref9.nation) || "",
                identification: customerData === null || customerData === void 0 ? void 0 : (ref10 = customerData[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.owningIndividual) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.identification) === null || ref12 === void 0 ? void 0 : ref12.map(function(identification) {
                    var nationality = countries === null || countries === void 0 ? void 0 : countries.filter(function(element) {
                        return element.id === (identification === null || identification === void 0 ? void 0 : identification.nationality);
                    });
                    var identificationType = "";
                    if ((identification === null || identification === void 0 ? void 0 : identification.identificationType) === "1") identificationType = "Carta d'identit\xe0";
                    else if ((identification === null || identification === void 0 ? void 0 : identification.identificationType) === "2") identificationType = "Passaporto";
                    else if ((identification === null || identification === void 0 ? void 0 : identification.identificationType) === "3") identificationType = "Patente di guida";
                    return _object_spread_props(_object_spread({}, identification), {
                        identificationType: identificationType,
                        nationality: (nationality === null || nationality === void 0 ? void 0 : nationality[0].displayName) || "",
                        expirationDate: (identification === null || identification === void 0 ? void 0 : identification.expirationDate) ? formatDate(identification.expirationDate) : ""
                    });
                })
            }
        }));
        if (checkStartLockInFlow()) {
            setIsStartLockInFlow(true);
        } else {
            var ref13, ref14;
            if (customerData === null || customerData === void 0 ? void 0 : (ref13 = customerData[0]) === null || ref13 === void 0 ? void 0 : ref13.id) setCustomerId(customerData === null || customerData === void 0 ? void 0 : (ref14 = customerData[0]) === null || ref14 === void 0 ? void 0 : ref14.id);
            setTimeout(function() {
                setIsStartCustomerFlow(true);
            }, 200);
        }
    };
    useEffect(function() {
        if (isReferenceSuccess) initContactLockIn();
    }, [
        isReferenceSuccess
    ]);
    return {
        isLoading: isLoadingLockin || isLoadingCustomerInfo
    };
};
