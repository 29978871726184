import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { checkCartValidation } from "../../utils/shoppingCart";
/**
 * API service retrieve the shopping cart.
 * Method: GET
 * searchParams: {
 * salesChannel: 'selfService',
 * filterSystemChanges: 'TRUE',},
 * @param cartId
 * @param customOptions
 * @returns
 */ export var getShoppingCartService = function(cartId, customOptions) {
    return nextClient.get("".concat(API.SHOPPING_CART, "/").concat(cartId), _object_spread({
        searchParams: {
            salesChannel: "selfService",
            filterSystemChanges: "TRUE"
        }
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Mutation for saving into the key 'getShoppingCartQuery'
 * the shoppingCart response coming from the API service getShoppingCartService
 * @param cartId
 * @param customOptions
 * @returns
 */ export var useGetShoppingCart = function(cartId, customOptions) {
    LoggerInstance.debug("useGetShoppingCart ^^^ ");
    LoggerInstance.debug("useGetShoppingCart cartId : ", cartId);
    var queryEnabler = customOptions ? customOptions.enabled && !!cartId : !!cartId;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "getShoppingCart";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return getShoppingCartService(cartId, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: queryEnabler && ((customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled : true),
            onSuccess: function(data) {
                var ref;
                // save customerData object into storage
                localStorage.setItem("shoppingCart", JSON.stringify(data));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - getShoppingCart: ", error);
                localStorage.setItem("shoppingCart", JSON.stringify(errorMock("getShoppingCart")));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
/**
 * Mutation for saving into the key 'getShoppingCartQuery'
 * Is the silent update. In error not update Scart response
 * the shoppingCart response coming from the API service getShoppingCartService
 * @param cartId
 * @param customOptions
 * @returns
 */ export var useGetShoppingCartSilentUpdate = function(cartId, customOptions) {
    var queryEnabler = !!cartId;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "getShoppingCart";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var cartIdService = cartId;
            if (!cartIdService) {
                var shoppingCart = getFromLocalStorageByKey("shoppingCart");
                cartIdService = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
            }
            return getShoppingCartService(cartIdService, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : queryEnabler,
            onSuccess: function(data) {
                if (data) {
                    localStorage.setItem("shoppingCart", JSON.stringify(data));
                    checkCartValidation();
                }
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - getShoppingCart: ", error);
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
