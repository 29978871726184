import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import DocumentIdCard from "./components/DocumentIdCard/documentIdCard";
import NameFiscalCodeCard from "./components/NameFiscalCodeCard/nameFiscalCodeCard";
import ContactCard from "./components/ContactCard/contactCard";
import OtpCard from "./components/OtpCard/otpCard";
import ConfirmIdentityCard from "./components/ConfirmIdentityCard/confirmIdentityCard";
import PortabilityLineCard from "./components/PortabilityLineCard/portabilityLineCard";
import PortabilityInternetCard from "./components/PortabilityInternetCard/portabilityInternetCard";
import PortabilityInfoCard from "./components/PortabilityInfoCard/portabilityInfoCard";
import PortabilityMigrationCard from "./components/PortabilityMigrationCard/portabilityMigrationCard";
import PortabilityOperatorsCard from "./components/PortabilityOperatorsCard/portabilityOperatorsCard";
import PortabilityCard from "./components/PortabilityCard/portabilityCard";
import PortabilityNumberCard from "./components/PortabilityNumberCard/portabilityNumberCard";
import YourAddressCard from "./components/YourAddressCard/yourAddressCard";
import TermsCard from "./components/TermsCard/termsCard";
import ConsentCard from "./components/ConsentCard/consentCard";
import RecurrentPaymentCard from "./components/RecurrentPaymentCard/recurrentPaymentCard";
import UpfrontPaymentCard from "./components/UpfrontPaymentCard/upfrontPaymentCard";
import InvoiceCard from "./components/InvoiceCard/invoiceCard";
import Voucher from "./components/VoucherCard/voucherCard";
import UpfrontSelectionCard from "./components/UpfrontSelectionCard/upfrontSelectionCard";
import PaymentMethodWithoutRecurringCard from "./components/PaymentMethodWithoutRecurringCard/paymentMethodWithoutRecurringCard";
import ConfirmIdentityAppCard from "./components/ConfirmIdentityAppCard/confirmIdentityAppCard";
import PortabilityLineAppCard from "./components/PortabilityLineAppCard/portabilityLineAppCard";
import ContactLockInCard from "./components/ContactLockinCard/contactLockInCard";
import ContactLockinProspectCard from "./components/ContactLockinProspectCard/contactLockinProspectCard";
import { ID_FLOWS } from "./checkout.constants";
export var COMPONENTS_FLOW = [
    {
        title: ID_FLOWS.PERSONAL_INFO,
        component: NameFiscalCodeCard
    },
    {
        title: ID_FLOWS.DOCUMENT_ID,
        component: DocumentIdCard
    },
    {
        title: ID_FLOWS.CONTACT_CARD,
        component: ContactCard
    },
    {
        title: ID_FLOWS.CONTACT_CARD_LOCKIN,
        component: ContactLockInCard
    },
    {
        title: ID_FLOWS.CONTACT_CARD_LOCKIN_PROSPECT,
        component: ContactLockinProspectCard
    },
    {
        title: ID_FLOWS.CONFIRM_IDENDITY,
        component: ConfirmIdentityCard
    },
    {
        title: ID_FLOWS.CONFIRM_IDENDITY_APP,
        component: ConfirmIdentityAppCard
    },
    {
        title: ID_FLOWS.OTP,
        component: OtpCard
    },
    {
        title: ID_FLOWS.PORTABILITY_NUMBER,
        component: PortabilityNumberCard
    },
    {
        title: ID_FLOWS.INVOICE,
        component: InvoiceCard
    },
    {
        title: ID_FLOWS.TERMS_AND_CONDITIONS,
        component: TermsCard
    },
    {
        title: ID_FLOWS.CONSENTS,
        component: ConsentCard
    },
    {
        title: ID_FLOWS.UPFRONT_SELECTION,
        component: UpfrontSelectionCard
    },
    {
        title: ID_FLOWS.DELIVERY_ADDRESS,
        component: YourAddressCard
    },
    {
        title: ID_FLOWS.RECURRENT_PAYMENT_CARD,
        component: RecurrentPaymentCard
    },
    {
        title: ID_FLOWS.UPFRONT_PAYMENT,
        component: UpfrontPaymentCard
    },
    {
        title: ID_FLOWS.VOUCHER,
        component: Voucher
    },
    {
        title: ID_FLOWS.PORTABILITY_LINE,
        component: PortabilityLineCard
    },
    {
        title: ID_FLOWS.PORTABILITY_LINE_APP,
        component: PortabilityLineAppCard
    },
    {
        title: ID_FLOWS.PORTABILITY_INTERNET,
        component: PortabilityInternetCard
    },
    {
        title: ID_FLOWS.PORTABILITY,
        component: PortabilityCard
    },
    {
        title: ID_FLOWS.PORTABILITY_NUMBER,
        component: PortabilityNumberCard
    },
    {
        title: ID_FLOWS.PORTABILITY_INFORMATIONS,
        component: PortabilityInfoCard
    },
    {
        title: ID_FLOWS.PORTABILITY_MIGRATIONS,
        component: PortabilityMigrationCard
    },
    {
        title: ID_FLOWS.OPERATORS_INFORMATIONS,
        component: PortabilityOperatorsCard
    },
    {
        title: ID_FLOWS.PAYMENT_METHOD_WITHOUT_RECURRING,
        component: PaymentMethodWithoutRecurringCard
    }, 
];
export var getFlowComponentByTitle = function(title) {
    return COMPONENTS_FLOW.find(function(el) {
        return el.title === title;
    }) || {};
};
export var VOUCHER_FLOW = {
    VOUCHER: [
        getFlowComponentByTitle(ID_FLOWS.VOUCHER)
    ]
};
var PERSONAL_INFO_FLOW = {
    PERSONAL_INFO: [
        getFlowComponentByTitle(ID_FLOWS.PERSONAL_INFO),
        getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
        getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID), 
    ]
};
export var PERSONAL_INFO_LOCKIN_MM_FLOW = {
    PERSONAL_INFO_LOCKIN_MM: [
        getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
        getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID), 
    ]
};
export var PERSONAL_INFO_LOCKIN_FLOW = {
    PERSONAL_INFO_LOCKIN: [
        getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD_LOCKIN),
        getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID), 
    ]
};
var PERSONAL_INFO_LOCKIN_PROSPECT_FLOW = {
    PERSONAL_INFO_LOCKIN_PROSPECT: [
        getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD_LOCKIN_PROSPECT),
        getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID), 
    ]
};
var OTP_FLOW = {
    OTP: [
        getFlowComponentByTitle(ID_FLOWS.CONFIRM_IDENDITY),
        getFlowComponentByTitle(ID_FLOWS.OTP)
    ]
};
export var OTP_FLOW_APP = {
    OTP: [
        getFlowComponentByTitle(ID_FLOWS.CONFIRM_IDENDITY_APP),
        getFlowComponentByTitle(ID_FLOWS.OTP), 
    ]
};
var PORTABILITY_FLOW = {
    PORTABILITY_LINE: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_LINE)
    ],
    PORTABILITY_LINE_APP: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_LINE_APP)
    ],
    PORTABILITY: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY)
    ],
    PORTABILITY_INTERNET: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_INTERNET)
    ],
    PORTABILITY_INFO: [
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_NUMBER),
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_INFORMATIONS),
        getFlowComponentByTitle(ID_FLOWS.PORTABILITY_MIGRATIONS), 
    ],
    PORTABILITY_OPERATORS: [
        getFlowComponentByTitle(ID_FLOWS.OPERATORS_INFORMATIONS)
    ]
};
export var TERMS_FLOW = {
    TERMS: [
        getFlowComponentByTitle(ID_FLOWS.INVOICE),
        getFlowComponentByTitle(ID_FLOWS.TERMS_AND_CONDITIONS),
        getFlowComponentByTitle(ID_FLOWS.CONSENTS), 
    ]
};
export var DELIVERY_FLOW = {
    DELIVERY: [
        getFlowComponentByTitle(ID_FLOWS.DELIVERY_ADDRESS)
    ]
};
export var UPFRONT_SELECTION_FLOW = {
    UPFRONT_SELECTION: [
        getFlowComponentByTitle(ID_FLOWS.UPFRONT_SELECTION)
    ]
};
export var UPFRONT_PAYMENT_FLOW = {
    UPFRONT_PAYMENT: [
        getFlowComponentByTitle(ID_FLOWS.UPFRONT_PAYMENT)
    ]
};
export var PAYMENT_METHOD_WITHOUT_RECURRING_FLOW = {
    PAYMENT_METHOD: [
        getFlowComponentByTitle(ID_FLOWS.PAYMENT_METHOD_WITHOUT_RECURRING)
    ]
};
export var EMPTY_FLOWS = {
    EMPTY: [
        {
            title: "",
            component: undefined
        },
        {
            title: "",
            component: undefined
        }, 
    ]
};
var PAYMENT_FLOW = {
    RECURRENT_PAYMENT_CARD: [
        getFlowComponentByTitle(ID_FLOWS.RECURRENT_PAYMENT_CARD)
    ]
};
var FIXED_LINE_FLOW = {
    DEFAULT: _to_consumable_array(PERSONAL_INFO_FLOW.PERSONAL_INFO).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    LOCKIN: _to_consumable_array(PERSONAL_INFO_LOCKIN_FLOW.PERSONAL_INFO_LOCKIN).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    LOCKIN_MM: _to_consumable_array(PERSONAL_INFO_LOCKIN_MM_FLOW.PERSONAL_INFO_LOCKIN_MM).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    LOCKIN_PROSPECT: _to_consumable_array(PERSONAL_INFO_LOCKIN_PROSPECT_FLOW.PERSONAL_INFO_LOCKIN_PROSPECT).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    DEFAULT_WITH_RECURRING_PAYMENT: _to_consumable_array(PERSONAL_INFO_FLOW.PERSONAL_INFO).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    LOCKIN_WITH_RECURRING_PAYMENT: _to_consumable_array(PERSONAL_INFO_LOCKIN_FLOW.PERSONAL_INFO_LOCKIN).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    LOCKIN_MM_WITH_RECURRING_PAYMENT: _to_consumable_array(PERSONAL_INFO_LOCKIN_MM_FLOW.PERSONAL_INFO_LOCKIN_MM).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    LOCKIN_PROSPECT_WITH_RECURRING_PAYMENT: _to_consumable_array(PERSONAL_INFO_LOCKIN_PROSPECT_FLOW.PERSONAL_INFO_LOCKIN_PROSPECT).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    APP: _to_consumable_array(OTP_FLOW_APP.OTP).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE_APP), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    APP_WITH_RECURRING_PAYMENT: _to_consumable_array(OTP_FLOW_APP.OTP).concat(_to_consumable_array(PORTABILITY_FLOW.PORTABILITY_LINE_APP), _to_consumable_array(DELIVERY_FLOW.DELIVERY), _to_consumable_array(TERMS_FLOW.TERMS), _to_consumable_array(UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION), _to_consumable_array(VOUCHER_FLOW.VOUCHER), _to_consumable_array(PAYMENT_FLOW.RECURRENT_PAYMENT_CARD), _to_consumable_array(UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT), _to_consumable_array(EMPTY_FLOWS.EMPTY))
};
export { FIXED_LINE_FLOW, PERSONAL_INFO_FLOW, PERSONAL_INFO_LOCKIN_PROSPECT_FLOW, OTP_FLOW, PORTABILITY_FLOW, PAYMENT_FLOW };
