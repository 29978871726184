import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
/**
 * Methods to invoke the POST /generate service
 * @param payload
 * @param customOptions
 * @returns
 */ var generateOTPService = function(payload, customOptions) {
    return nextClient.post(API.GENERATE, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Mutation to generate the OTP code
 * @returns
 */ export var useGenerateOTP = function() {
    var moduleName = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "customer-SelfService", customOptions = arguments.length > 1 ? arguments[1] : void 0;
    var queryClient = useQueryClient();
    return useMutation("generateOTP", function(data) {
        LoggerInstance.debug("useGenerateOTP data ", data);
        var payload = _object_spread({
            moduleName: moduleName
        }, data);
        LoggerInstance.debug("useGenerateOTP payload ", payload);
        return generateOTPService(payload, customOptions);
    }, {
        onSuccess: function(data) {
            var ref;
            localStorage.setItem("generateOTP", JSON.stringify(data));
            queryClient.setQueryData("generateOTP", data);
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
        },
        onError: function(error) {
            var ref;
            LoggerInstance.error("ERROR - generateOTP: ", error);
            queryClient.setQueryData("generateOTP", errorMock("generateOTP", error));
            localStorage.setItem("generateOTP", JSON.stringify(errorMock("generateOTP", error)));
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
        }
    });
};
