import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var getLargeSmallSlider = function(widget) {
    var ref;
    return {
        slides: widget === null || widget === void 0 ? void 0 : (ref = widget.elements) === null || ref === void 0 ? void 0 : ref.map(function(s, indexSlide) {
            if (indexSlide === 0) {
                var ref, ref1, ref2;
                return _object_spread_props(_object_spread({}, s), {
                    action: _object_spread_props(_object_spread({}, s.action), {
                        callMeNow: ((ref = s.action) === null || ref === void 0 ? void 0 : ref.callMeNow) || ((ref1 = s.action) === null || ref1 === void 0 ? void 0 : ref1.url) || "",
                        // @ts-ignore
                        small: ((ref2 = s.action) === null || ref2 === void 0 ? void 0 : ref2.small) ? s.action.small === "true" : true
                    })
                });
            }
            return _object_spread(_object_spread_props(_object_spread({}, s), {
                withMask: true
            }), s.maskIcon && {
                maskIcon: s.maskIcon
            }, s.maskIconSize && {
                maskIconSize: s.maskIconSize
            });
        }),
        topText: widget.title || ""
    };
};
