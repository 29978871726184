import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _type_of from "@swc/helpers/src/_type_of.mjs";
import { useQueryClient } from "react-query";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
/**
 * This method is used retrieve the authorizationOrderService from ApicWeb.
 * With this response we can verify the payment submitted in SIA.
 * Method: GET
 * @param customOptions
 * @param orderId
 * @param siaOrderId
 * @returns
 */ var authorizationOrderService = function(customOptions, orderId, siaOrderId) {
    LoggerInstance.debug("authorizationOrderService  orderId: ", orderId);
    LoggerInstance.debug("authorizationOrderService  siaOrderId: ", siaOrderId);
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "authorization";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return nextClient.get("".concat(API.PAYMENT_MANAGEMENT_ORDER, "/").concat(orderId, "/").concat(API.AUTHORIZATION), {
                searchParams: _object_spread_props(_object_spread({}, nextClient.searchParams), {
                    siaOrderId: siaOrderId
                })
            });
        },
        options: _object_spread({}, customOptions && _object_spread({}, customOptions))
    };
    return handleUseQuery(options);
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the authorizationOrderService.
 * @returns
 */ export var useAuthorizationOrder = function(customOptions) {
    var ref;
    var queryClient = useQueryClient();
    var customOptionsEnabled = (typeof customOptions === "undefined" ? "undefined" : _type_of(customOptions)) === undefined ? true : customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled;
    LoggerInstance.debug("useAuthorizationOrder : ", useAuthorizationOrder);
    var authorizeSaved = localStorage.getItem("authorize");
    var authorize = !!authorizeSaved && JSON.parse(authorizeSaved);
    var siaOrderId = authorize === null || authorize === void 0 ? void 0 : (ref = authorize.authorizationParameters) === null || ref === void 0 ? void 0 : ref.siaOrderId;
    LoggerInstance.debug("useAuthorizationOrder siaOrderId : ", siaOrderId);
    var shoppingCartSaved = localStorage.getItem("shoppingCart");
    var shoppingCart = !!shoppingCartSaved && JSON.parse(shoppingCartSaved);
    LoggerInstance.debug("useAuthorizationOrder shoppingCart : ", shoppingCart);
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    LoggerInstance.debug("useAuthorizationOrder cartId : ", cartId);
    return authorizationOrderService({
        enabled: Boolean(cartId) && Boolean(siaOrderId) && customOptionsEnabled,
        onSuccess: function(data) {
            var ref, ref1;
            var status = data === null || data === void 0 ? void 0 : (ref = data.authorization) === null || ref === void 0 ? void 0 : (ref1 = ref.status) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase();
            if (data && data.authorization && status && status !== "ok" && status !== "timeoutok") {
                var ref2;
                var error = errorMock("authorization", {
                    status: "200",
                    url: API.AUTHORIZATION,
                    statusText: "200"
                }, status, data === null || data === void 0 ? void 0 : (ref2 = data.authorization) === null || ref2 === void 0 ? void 0 : ref2.errorCode);
                // save authorization ERROR into storage and queryClient
                queryClient.setQueryData("authorizationError", error);
                localStorage.setItem("authorizationError", JSON.stringify(error));
            } else {
                // save authorization object into storage
                localStorage.setItem("authorization", JSON.stringify(data));
                LoggerInstance.debug("useAuthorizationOrder data : ", data);
            }
        },
        onError: function(error) {
            var errorObject = errorMock("authorization", error);
            LoggerInstance.error("ERROR - authorization: ", error);
            LoggerInstance.debug("useAuthorizationOrder error : ", errorObject);
            queryClient.setQueryData("authorizationError", errorObject);
            localStorage.setItem("authorizationError", JSON.stringify(errorObject));
        }
    }, cartId, siaOrderId);
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the authorizationOrderService.
 * @returns
 */ export var useGetAuthorization = function(customOptions) {
    var ref;
    var queryClient = useQueryClient();
    var customOptionsEnabled = (typeof customOptions === "undefined" ? "undefined" : _type_of(customOptions)) === undefined ? true : customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled;
    LoggerInstance.debug("useAuthorizationOrder : ", useAuthorizationOrder);
    var authorize = getFromLocalStorageByKey("authorize");
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var siaOrderId = authorize === null || authorize === void 0 ? void 0 : (ref = authorize.authorizationParameters) === null || ref === void 0 ? void 0 : ref.siaOrderId;
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    return authorizationOrderService({
        keyDependency: customOptions === null || customOptions === void 0 ? void 0 : customOptions.keyDependency,
        enabled: Boolean(cartId) && Boolean(siaOrderId) && customOptionsEnabled,
        onSuccess: function(data) {
            var ref, ref1;
            var status = data === null || data === void 0 ? void 0 : (ref = data.authorization) === null || ref === void 0 ? void 0 : (ref1 = ref.status) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase();
            if (data && data.authorization && status && status !== "ok" && status !== "timeoutok") {
                var error = errorMock("authorization", {
                    status: "200",
                    url: API.AUTHORIZATION,
                    statusText: "200"
                }, status);
                queryClient.setQueryData("authorizationError", error);
                localStorage.setItem("authorizationError", JSON.stringify(error));
            } else {
                localStorage.setItem("authorizationRecurring", JSON.stringify(data));
                LoggerInstance.debug("useAuthorizationOrder data : ", data);
            }
        },
        onError: function(error) {
            var errorObject = errorMock("authorization", error);
            LoggerInstance.error("ERROR - authorization: ", error);
            LoggerInstance.debug("useAuthorizationOrder error : ", errorObject);
            queryClient.setQueryData("authorizationError", errorObject);
            localStorage.setItem("authorizationError", JSON.stringify(errorObject));
        }
    }, cartId, siaOrderId);
};
