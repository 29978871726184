import { ErrorCodes } from "@vfit/consumer/data-access";
export var CMS_LABELS = {
    roSubtitle: "Ecco un riepilogo dei tuoi contatti personali per essere ricontattato al telefono e per ricevere via mail le comunicazioni sull’attivazione. Verifica che siano corretti o modificali prima di proseguire.",
    roTelephoneLable: "Il tuo numero di telefono",
    roEmailLable: "Il tuo indirizzo email",
    roCtaTelephone: "Modifica numero",
    roCtaEmail: "Modifica email"
};
export var organizeContact = function(ContactCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    return {
        title: (ContactCMS === null || ContactCMS === void 0 ? void 0 : (ref = ContactCMS.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.clientRegistered) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        subtitle: (ContactCMS === null || ContactCMS === void 0 ? void 0 : (ref2 = ContactCMS.personalinfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.generalInfo) === null || ref3 === void 0 ? void 0 : ref3.subtitle) || "",
        telephoneLable: (ContactCMS === null || ContactCMS === void 0 ? void 0 : (ref4 = ContactCMS.personalinfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selectInput) === null || ref5 === void 0 ? void 0 : ref5.telephone) || "",
        emailLable: (ContactCMS === null || ContactCMS === void 0 ? void 0 : (ref6 = ContactCMS.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.selectInput) === null || ref7 === void 0 ? void 0 : ref7.email) || "",
        footerInfo: (ContactCMS === null || ContactCMS === void 0 ? void 0 : (ref8 = ContactCMS.personalinfo) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.clientRegistered) === null || ref9 === void 0 ? void 0 : ref9.smallInfo) || ""
    };
};
export var handleCRMCodes = function(crmCode) {
    switch(crmCode){
        case "CRM-0020":
        case "CRM-0024":
            return ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT;
        case "CRM-0021":
        case "CRM-0023":
            return ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT;
        case "CRM-0022":
        case "CRM-0025":
            return ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT;
        default:
            return "";
    }
};
export var handleErrorCustomer = function(errorCode, errorList, genericError) {
    var ref, ref1;
    var errorDetails = errorList.find(function(item) {
        return item.error === errorCode;
    });
    var errorGeneric = (genericError === null || genericError === void 0 ? void 0 : genericError.genericerror) || null;
    return {
        title: (errorDetails === null || errorDetails === void 0 ? void 0 : errorDetails.title) || (errorGeneric === null || errorGeneric === void 0 ? void 0 : errorGeneric.title) || "",
        message: (errorDetails === null || errorDetails === void 0 ? void 0 : errorDetails.message) || (errorGeneric === null || errorGeneric === void 0 ? void 0 : errorGeneric.description) || "",
        actionText: (errorDetails === null || errorDetails === void 0 ? void 0 : (ref = errorDetails.button) === null || ref === void 0 ? void 0 : ref.title) || (errorGeneric === null || errorGeneric === void 0 ? void 0 : errorGeneric.buttonLabel) || "",
        url: (errorDetails === null || errorDetails === void 0 ? void 0 : (ref1 = errorDetails.button) === null || ref1 === void 0 ? void 0 : ref1.urlForRedirect) || ""
    };
};
