import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
/**
 * This method is used to retrieve the provider.
 * @param array
 * @param filteringProvider
 * @returns
 */ export var retrieveProvider = function(array, filteringProvider) {
    if (!array) return undefined;
    var value = array === null || array === void 0 ? void 0 : array.map(function(item) {
        if ((item === null || item === void 0 ? void 0 : item.displayValue) === filteringProvider) {
            return item === null || item === void 0 ? void 0 : item.value;
        }
        return undefined;
    }).filter(function(item) {
        return item !== undefined;
    });
    return value[0];
};
/**
 * This Method is used to retrieve the MSDIN.
 * @param array
 * @param filteringString
 * @returns
 */ export var retrieveMSDIN = function(array) {
    var filteringString = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "RLC_TelephoneNumber";
    if (!array) return undefined;
    var value = array === null || array === void 0 ? void 0 : array.map(function(item) {
        if ((item === null || item === void 0 ? void 0 : item.name) === filteringString) {
            return item === null || item === void 0 ? void 0 : item.displayValue;
        }
        return undefined;
    }).filter(function(item) {
        return item !== undefined;
    });
    return value[0];
};
export var adaptSelectedValue = function(selectedValue) {
    var selectedValueBoolean = JSON.parse(selectedValue);
    return selectedValueBoolean ? "Yes" : "No";
};
export var filterOperatorsMobile = function(data, valuesToFilter) {
    var ref, ref1;
    var operatorData = (ref = data.portInDetails.mobilePortInDetails[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.portInProviderCust) === null || ref1 === void 0 ? void 0 : ref1.availableValues;
    if (operatorData && valuesToFilter && (valuesToFilter === null || valuesToFilter === void 0 ? void 0 : valuesToFilter.length) > 0) {
        var ref2;
        var cmsValueList = valuesToFilter.filter(function(element) {
            return element.id && element.id !== "";
        }).map(function(element) {
            return element.id.toLowerCase();
        });
        var filteredOperators = operatorData.filter(function(operator) {
            return !cmsValueList.includes(operator.value.toLowerCase());
        });
        return _object_spread_props(_object_spread({}, data), {
            portInDetails: _object_spread_props(_object_spread({}, data.portInDetails), {
                mobilePortInDetails: [
                    _object_spread_props(_object_spread({}, data.portInDetails.mobilePortInDetails[0]), {
                        portInProviderCust: _object_spread_props(_object_spread({}, (ref2 = data.portInDetails.mobilePortInDetails[0]) === null || ref2 === void 0 ? void 0 : ref2.portInProviderCust), {
                            availableValues: filteredOperators
                        })
                    }), 
                ]
            })
        });
    }
    return data;
};
export var overrideOperatorsListMobile = function(data, retrievedList) {
    var ref, ref1, ref2;
    var operatorData = (ref = data.portInDetails.mobilePortInDetails[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.portInProviderCust) === null || ref1 === void 0 ? void 0 : ref1.availableValues;
    if (operatorData && retrievedList && ((ref2 = retrievedList.elements) === null || ref2 === void 0 ? void 0 : ref2.length) > 0) {
        var ref3;
        retrievedList.elements.forEach(function(entry) {
            var ref;
            var foundIndex = operatorData.findIndex(function(operator) {
                var ref;
                return ((ref = entry.value) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === operator.value.toLowerCase();
            });
            if (foundIndex !== -1 && ((ref = entry.displayValue) === null || ref === void 0 ? void 0 : ref.trim().length) > 0) {
                operatorData[foundIndex].displayValue = entry.displayValue;
            }
        });
        return _object_spread_props(_object_spread({}, data), {
            portInDetails: _object_spread_props(_object_spread({}, data.portInDetails), {
                mobilePortInDetails: [
                    _object_spread_props(_object_spread({}, data.portInDetails.mobilePortInDetails[0]), {
                        portInProviderCust: _object_spread_props(_object_spread({}, (ref3 = data.portInDetails.mobilePortInDetails[0]) === null || ref3 === void 0 ? void 0 : ref3.portInProviderCust), {
                            availableValues: operatorData
                        })
                    }), 
                ]
            })
        });
    }
    return data;
};
export var reorderOperators = function(data, newOrderList) {
    var ref, ref1, ref2;
    var operatorData = (ref = data.portInDetails.mobilePortInDetails[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.portInProviderCust) === null || ref1 === void 0 ? void 0 : ref1.availableValues;
    if (operatorData && newOrderList && ((ref2 = newOrderList.elements) === null || ref2 === void 0 ? void 0 : ref2.length) > 0) {
        var ref3;
        var amdocsValueList = operatorData.map(function(element) {
            return element.value.toLowerCase();
        });
        var validRetrievedOperators = newOrderList.elements.filter(function(element) {
            var ref;
            return element.value && element.value !== "" && element.displayValue && element.displayValue !== "" && amdocsValueList.includes((ref = element.value) === null || ref === void 0 ? void 0 : ref.toLowerCase());
        });
        var cmsValueList = validRetrievedOperators.map(function(element) {
            var ref;
            return (ref = element.value) === null || ref === void 0 ? void 0 : ref.toLowerCase();
        });
        var notRetrievedElements = operatorData.filter(function(operator) {
            var ref;
            return !cmsValueList.includes((ref = operator.value) === null || ref === void 0 ? void 0 : ref.toLowerCase());
        });
        var newOperatorList = validRetrievedOperators.map(function(operator) {
            return _object_spread({
                type: "STRING"
            }, operator);
        }).concat(notRetrievedElements);
        return _object_spread_props(_object_spread({}, data), {
            portInDetails: _object_spread_props(_object_spread({}, data.portInDetails), {
                mobilePortInDetails: [
                    _object_spread_props(_object_spread({}, data.portInDetails.mobilePortInDetails[0]), {
                        portInProviderCust: _object_spread_props(_object_spread({}, (ref3 = data.portInDetails.mobilePortInDetails[0]) === null || ref3 === void 0 ? void 0 : ref3.portInProviderCust), {
                            availableValues: newOperatorList
                        })
                    }), 
                ]
            })
        });
    }
    return data;
};
export var overrideOperatorsListFixed = function(data, retrievedList) {
    var ref, ref1, ref2, ref3, ref4;
    var operatorData = (ref = data.fixedVoicePortInDetails) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.r1MissingMigrationFixedPortInSpecification) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.r1FirstDonatingNetworkOperator) === null || ref3 === void 0 ? void 0 : ref3.availableValues;
    if (operatorData && retrievedList && ((ref4 = retrievedList.elements) === null || ref4 === void 0 ? void 0 : ref4.length) > 0) {
        retrievedList.elements.forEach(function(entry) {
            var ref;
            var foundIndex = operatorData.findIndex(function(operator) {
                var ref;
                return ((ref = entry.value) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === operator.value.toLowerCase();
            });
            if (foundIndex !== -1 && ((ref = entry.displayValue) === null || ref === void 0 ? void 0 : ref.trim().length) > 0) {
                operatorData[foundIndex].displayValue = entry.displayValue;
            }
        });
        return _object_spread_props(_object_spread({}, data), {
            fixedVoicePortInDetails: [
                _object_spread_props(_object_spread({}, data.fixedVoicePortInDetails[0]), {
                    r1MissingMigrationFixedPortInSpecification: _object_spread_props(_object_spread({}, data.fixedVoicePortInDetails[0].r1MissingMigrationFixedPortInSpecification), {
                        r1FirstDonatingNetworkOperator: _object_spread_props(_object_spread({}, data.fixedVoicePortInDetails[0].r1MissingMigrationFixedPortInSpecification.r1FirstDonatingNetworkOperator), {
                            availableValues: operatorData
                        })
                    })
                }), 
            ]
        });
    }
    return data;
};
