import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var getExperiencesSlider = function(widget) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    return {
        experiences: _object_spread_props(_object_spread({}, widget), {
            slides: widget === null || widget === void 0 ? void 0 : (ref = widget.elements) === null || ref === void 0 ? void 0 : ref.map(function(s) {
                return _object_spread_props(_object_spread({}, s), {
                    withMask: true,
                    maskIconSize: s.maskIconSize || 24
                });
            })
        }),
        action: {
            title: (widget === null || widget === void 0 ? void 0 : (ref1 = widget.customAction) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
            // @ts-ignore
            // eslint-disable-next-line
            type: +(widget === null || widget === void 0 ? void 0 : (ref2 = widget.customAction) === null || ref2 === void 0 ? void 0 : ref2.type),
            url: (widget === null || widget === void 0 ? void 0 : (ref3 = widget.customAction) === null || ref3 === void 0 ? void 0 : ref3.url) || "",
            callMeNow: (widget === null || widget === void 0 ? void 0 : (ref4 = widget.customAction) === null || ref4 === void 0 ? void 0 : ref4.callMeNow) || "",
            localUrl: (widget === null || widget === void 0 ? void 0 : (ref5 = widget.customAction) === null || ref5 === void 0 ? void 0 : ref5.localUrl) || ""
        }
    };
};
