export var DEFAULT_CONTACT_ADDRESS = {
    description: "",
    deliveryAddress: "",
    civicNumber: ""
};
export var IFamilyPlusActionType;
(function(IFamilyPlusActionType) {
    IFamilyPlusActionType["FAMILYPLUS_ACCEPT"] = "1";
    IFamilyPlusActionType["FAMILYPLUS_CONTINUE"] = "2";
    IFamilyPlusActionType["FAMILYPLUS_CTC"] = "3";
    IFamilyPlusActionType["FAMILYPLUS_REDIRECT"] = "4";
})(IFamilyPlusActionType || (IFamilyPlusActionType = {}));
