import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-consumer-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useMutation, useQueryClient } from "react-query";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { datadogRum } from "@datadog/browser-rum";
/**
 * This method is used to validate the address from the Google Autocomplete
 * @param payload
 * @param customOptions
 * @returns
 */ var validateAddressService = function(payload, customOptions) {
    return nextClient.post(API.VALIDATE_ADDRESS, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * validateAddressMutation used to save the Google Autocomplete Service.
 * It sets the query 'coverageToolInputAddress' used to trigger the coverage tool flow.
 * @returns
 */ export var useValidateAddressMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("setAddress", function(placeObject) {
        return queryClient.setQueryData("coverageToolInputAddress", placeObject);
    });
};
/**
 * Custom mutation hook for run validateAddressService once the useQuery coverageToolInputAddress for data storage has been setup
 * Call this after validateAddressMutation has been called
 * @returns
 */ export var useValidateAddress = function(customOptions) {
    var queryClient = useQueryClient();
    var keyString = "validatedAddress";
    return useMutation("addressValidation", function(req) {
        LoggerInstance.debug("useValidateAddress onMutateData : ", req.placeObject);
        var _placeObject = req.placeObject, street = _placeObject.street, streetNumber = _placeObject.streetNumber, city = _placeObject.city, stateOrProvince = _placeObject.stateOrProvince, postalCode = _placeObject.postalCode, displayStateOrProvince = _placeObject.displayStateOrProvince, latitude = _placeObject.latitude, longitude = _placeObject.longitude;
        var newFormattedData = {
            street: street,
            streetNumber: streetNumber,
            city: city,
            stateOrProvince: stateOrProvince,
            postalCode: postalCode,
            displayStateOrProvince: displayStateOrProvince,
            latitude: latitude,
            longitude: longitude
        };
        LoggerInstance.debug("useValidateAddress riformatted : ", newFormattedData);
        var payload = _object_spread_props(_object_spread({}, newFormattedData), {
            displayStateOrProvince: req === null || req === void 0 ? void 0 : req.placeObject.stateOrProvince
        });
        return validateAddressService(payload, customOptions);
    }, {
        onMutate: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(data) {
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            if ((data === null || data === void 0 ? void 0 : data.isBilling) === true) {
                                keyString = "billingAddress";
                            } else if ((data === null || data === void 0 ? void 0 : data.isDelivery) === true) {
                                keyString = "deliveryAddress";
                            } else if ((data === null || data === void 0 ? void 0 : data.isPickup) === true) {
                                keyString = "pickupAddress";
                            }
                        case 1:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(data) {
                return _ref.apply(this, arguments);
            };
        }(),
        onSuccess: function(data, variables) {
            var ref;
            LoggerInstance.debug("SUCCESS ON VALIDATE ADDRESS", data);
            var validErrorCode = [
                "0",
                "1"
            ];
            if ((data === null || data === void 0 ? void 0 : data.validAddressList) && validErrorCode.includes(data === null || data === void 0 ? void 0 : data.errorCode)) {
                LoggerInstance.debug("useValidateAddress keyString : ", keyString);
                LoggerInstance.debug("useValidateAddress data : ", data);
                localStorage.setItem(keyString, JSON.stringify(data));
                queryClient.setQueryData(keyString, data);
            } else {
                var ref1;
                LoggerInstance.debug("ERROR on validateAddress: The address returned errorCode = ".concat(data === null || data === void 0 ? void 0 : data.errorCode));
                LoggerInstance.debug("data error on validatedAddress:", data);
                LoggerInstance.debug("variables in validatedAddress:", variables);
                LoggerInstance.debug("Coverage tool step:", variables === null || variables === void 0 ? void 0 : variables.step);
                datadogRum.addError({
                    inputVariables: variables === null || variables === void 0 ? void 0 : variables.placeObject,
                    step: (variables === null || variables === void 0 ? void 0 : variables.step) || "NO_STEP",
                    address: (data === null || data === void 0 ? void 0 : (ref1 = data.validAddressList) === null || ref1 === void 0 ? void 0 : ref1[0]) || "NO_ADDRESS_LIST",
                    error_code: (data === null || data === void 0 ? void 0 : data.errorCode) || "NO_ERROR_CODE"
                });
                queryClient.setQueryData(keyString, errorMock("validateAddress", {
                    status: "200",
                    statusText: "ERROR on ".concat(keyString),
                    url: API.VALIDATE_ADDRESS
                }, "L'indirizzo inserito non \xe8 valido (Code: ".concat(data.errorCode, ")")));
                localStorage.setItem(keyString, JSON.stringify(errorMock("addressValidation", {
                    status: "200",
                    statusText: "ERROR on ".concat(keyString),
                    url: API.VALIDATE_ADDRESS
                }, "L'indirizzo inserito non \xe8 valido (Code: ".concat(data.errorCode, ")"))));
            }
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
        },
        onError: function(error) {
            var ref;
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
        }
    });
};
