import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useTracking } from "@vfit/shared/providers";
import { useEffect } from "react";
import { getUserInfo, getTagging } from "../../../../checkout.utils";
import { useCheckout } from "../../../../../iBuyFixed.context";
export var useVoucherSuccessCardTagging = function() {
    var ref, ref1;
    var voucher = useCheckout().voucher;
    var tagging = getTagging("voucher typ", "fixed");
    var dataLayer = {
        event: [
            "checkout_step8.3"
        ],
        event_label: "voucher applied",
        opts: tagging.opts,
        cartProduct: tagging.trackingProduct,
        pageProduct: tagging === null || tagging === void 0 ? void 0 : tagging.pageProductsInfo,
        product_discount_value: voucher === null || voucher === void 0 ? void 0 : (ref = voucher.validateVoucher) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : ref1.finalPrice,
        transaction_coupon_code: (voucher === null || voucher === void 0 ? void 0 : voucher.voucherId) || "",
        visitorTrackingOpts: getUserInfo()
    };
    var trackWithNewDatalayer = useTracking(_object_spread_props(_object_spread({}, dataLayer), {
        disableInitialTrack: true
    })).trackWithNewDatalayer;
    useEffect(function() {
        var ref;
        if (voucher === null || voucher === void 0 ? void 0 : (ref = voucher.shoppingCart) === null || ref === void 0 ? void 0 : ref.isSuccess) {
            trackWithNewDatalayer(_object_spread({}, dataLayer));
        }
    }, [
        voucher
    ]);
    return null;
};
