import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { errorManager, ErrorService } from "@vfit/shared/providers";
import { checkWindow, getFromLocalStorageByKey, getHome, openPopup } from "@vfit/shared/data-access";
export var retrieveDXLBundlePo = function(productList) {
    var ref;
    return ((ref = productList === null || productList === void 0 ? void 0 : productList.filter(function(p) {
        var ref;
        return (ref = p.type) === null || ref === void 0 ? void 0 : ref.includes("fixed");
    })) === null || ref === void 0 ? void 0 : ref.find(function(p) {
        return !!p.bundlePO;
    })) || null;
};
export var retrieveRedirectUrl = function(product) {
    return "".concat(getHome(), "/casa/fibra/").concat(product === null || product === void 0 ? void 0 : product.slug, "/shopping-cart?cmsId=").concat(product === null || product === void 0 ? void 0 : product.cmsId);
};
export var showError = function(callMeNowTitle, callMeNow) {
    var appSession = checkWindow() ? getFromLocalStorageByKey === null || getFromLocalStorageByKey === void 0 ? void 0 : getFromLocalStorageByKey("appSession") : null;
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), _object_spread({
        title: "Ops!",
        message: callMeNow ? "Si \xe8 verificato un errore. Premi il tasto in basso per essere ricontattato." : "Si \xe8 verificato un errore. Riprovare in seguito."
    }, callMeNowTitle && callMeNow && {
        actionEvent: function() {
            return openPopup(callMeNow);
        },
        actionText: callMeNowTitle
    }, appSession && {
        trackError: {
            otherInfo: _object_spread_props(_object_spread({}, appSession), {
                dtType: "CBU_APP_LANDING"
            })
        }
    }));
};
export var DEFAULT_CMS_ID = "51";
