import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  from {\n    stroke-dashoffset: 320;\n  }\n  \n  to {\n    stroke-dashoffset: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  animation: ",
        " 4s linear infinite;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled, { keyframes } from "styled-components";
var dash = keyframes(_templateObject());
export var AnimatedPath = styled.path.withConfig({
    componentId: "sc-4e9ca677-0"
})(_templateObject1(), dash);
