import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { errorMock, SELECTED_PAYMENT } from "@vfit/consumer/data-access";
import { EMethodCode } from "@vfit/shared/models";
/**
 * This method is used to call the paymentDistributionService.
 * Method: POST
 * SearchParams:
 * ...nextClient.searchParams,
 * siaOrderId,
 * @param cartId
 * @param siaOrderId
 * @param payload
 * @param customOptions
 * @returns
 */ export var paymentDistributionService = function(cartId, siaOrderId, payload, customOptions) {
    LoggerInstance.debug("paymentDistributionService PAYLOAD -------", payload);
    LoggerInstance.debug("paymentDistributionService siaOrderId -------", siaOrderId);
    LoggerInstance.debug("paymentDistributionService cartId -------", cartId);
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.PAYMENT_DISTRIBUTION), payload, _object_spread({
        searchParams: _object_spread_props(_object_spread({}, nextClient.searchParams), {
            siaOrderId: siaOrderId
        })
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Method to get the billingOfferId.
 * @param array
 * @param filter
 * @returns
 */ var findBillingOfferId = function(array, filter) {
    var ref, ref1;
    LoggerInstance.debug("findBillingOfferId : ", array);
    var orderItem = array === null || array === void 0 ? void 0 : array.filter(function(el) {
        return (el === null || el === void 0 ? void 0 : el.orderActionId) === filter;
    });
    return orderItem === null || orderItem === void 0 ? void 0 : (ref = orderItem[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.orderItemOnBillPayment) === null || ref1 === void 0 ? void 0 : ref1.billingOfferIdForDistribution;
};
/**
 * Add the created object to the payload.
 * @param ids
 * @param selectedPayMean
 * @param orderItemsId
 */ var addPayloadObject = function(ids, selectedPayMean, orderItemsId) {
    // eslint-disable-next-line array-callback-return
    LoggerInstance.debug("PAYMENT DISTRIBUTION", ids, selectedPayMean, orderItemsId);
    return ids === null || ids === void 0 ? void 0 : ids.filter(function(el) {
        return !!el;
    }).map(function(el) {
        return {
            orderActionId: el,
            orderItemOnBillPayment: {
                selectedPayMean: selectedPayMean,
                billingOfferIdForDistribution: findBillingOfferId(orderItemsId, el)
            }
        };
    });
};
/**
 * This method handles the paymentDistrubution Service.
 * @returns
 */ export var usePaymentDistributionFixed = function() {
    var queryClient = useQueryClient();
    return useMutation("paymentDistribution", function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31;
        LoggerInstance.debug("------PaymentDistribution");
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var authorizeCC = getFromLocalStorageByKey("authorize");
        var customerData = getFromLocalStorageByKey("customerData");
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var paymentData = getFromLocalStorageByKey("paymentData");
        var findCaller = getFromLocalStorageByKey("findCaller");
        var authorizationDataRecurring = getFromLocalStorageByKey("authorizationRecurring");
        var authorizationDataDefault = getFromLocalStorageByKey("authorization");
        var authorizationData = authorizationDataRecurring || authorizationDataDefault;
        var orderItemPaymentsFull = paymentData === null || paymentData === void 0 ? void 0 : (ref = paymentData.orderPayment) === null || ref === void 0 ? void 0 : ref.orderItemPayments;
        var siaOrderId = authorizeCC === null || authorizeCC === void 0 ? void 0 : (ref1 = authorizeCC.authorizationParameters) === null || ref1 === void 0 ? void 0 : ref1.siaOrderId;
        var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref2 = customerData[0]) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id);
        var ownerIndividualId = (customerData === null || customerData === void 0 ? void 0 : (ref4 = customerData[0]) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.owningIndividual) === null || ref5 === void 0 ? void 0 : ref5.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref6 = findCaller.individualRef) === null || ref6 === void 0 ? void 0 : ref6.contactId);
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var cartItems = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref7 = shoppingCart.cartItem) === null || ref7 === void 0 ? void 0 : ref7[0];
        var dataSimId = cartItems === null || cartItems === void 0 ? void 0 : (ref8 = cartItems.dataSim) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[0]) === null || ref9 === void 0 ? void 0 : ref9.id;
        var fixedVoiceId = cartItems === null || cartItems === void 0 ? void 0 : (ref10 = cartItems.fixedVoice) === null || ref10 === void 0 ? void 0 : (ref11 = ref10[0]) === null || ref11 === void 0 ? void 0 : ref11.id;
        var internetId = cartItems === null || cartItems === void 0 ? void 0 : (ref12 = cartItems.internet) === null || ref12 === void 0 ? void 0 : (ref13 = ref12[0]) === null || ref13 === void 0 ? void 0 : ref13.id;
        var selectedPayMean = {
            object_type: authorizationData === null || authorizationData === void 0 ? void 0 : (ref14 = authorizationData.authorization) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.payMean) === null || ref15 === void 0 ? void 0 : ref15.object_type,
            id: authorizationData === null || authorizationData === void 0 ? void 0 : (ref16 = authorizationData.authorization) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.payMean) === null || ref17 === void 0 ? void 0 : ref17.id,
            type: authorizationData === null || authorizationData === void 0 ? void 0 : (ref18 = authorizationData.authorization) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.payMean) === null || ref19 === void 0 ? void 0 : ref19.type,
            brand: authorizationData === null || authorizationData === void 0 ? void 0 : (ref20 = authorizationData.authorization) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.payMean) === null || ref21 === void 0 ? void 0 : ref21.brand,
            displayBrand: authorizationData === null || authorizationData === void 0 ? void 0 : (ref22 = authorizationData.authorization) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.payMean) === null || ref23 === void 0 ? void 0 : ref23.brand,
            holderName: " ",
            lastFourDigits: authorizationData === null || authorizationData === void 0 ? void 0 : (ref24 = authorizationData.authorization) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.payMean) === null || ref25 === void 0 ? void 0 : ref25.lastFourDigits,
            expiryMonth: authorizationData === null || authorizationData === void 0 ? void 0 : (ref26 = authorizationData.authorization) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.payMean) === null || ref27 === void 0 ? void 0 : ref27.expiryMonth,
            expiryYear: authorizationData === null || authorizationData === void 0 ? void 0 : (ref28 = authorizationData.authorization) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.payMean) === null || ref29 === void 0 ? void 0 : ref29.expiryYear,
            isVerified: true,
            isExpired: false,
            ppbId: authorizationData === null || authorizationData === void 0 ? void 0 : (ref30 = authorizationData.authorization) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.payMean) === null || ref31 === void 0 ? void 0 : ref31.ppbId
        };
        var orderItemPayments = addPayloadObject([
            dataSimId,
            fixedVoiceId,
            internetId
        ], selectedPayMean, orderItemPaymentsFull) || [];
        var payload = {
            orderPayment: {
                customerId: customerId,
                barId: billingAccount.id,
                ownerIndividualId: ownerIndividualId,
                orderItemPayments: orderItemPayments
            },
            flowType: "fixed"
        };
        return paymentDistributionService(cartId, siaOrderId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("paymentDistribution", JSON.stringify(data));
            queryClient.setQueryData("paymentDistribution", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - paymentDistribution: ", error);
            queryClient.setQueryData("paymentDistribution", errorMock("paymentDistribution", error));
            localStorage.setItem("paymentDistribution", JSON.stringify(errorMock("paymentDistribution", error)));
        }
    });
};
/**
 * This method handles the paymentDistrubutionMobile Service.
 * @returns
 */ export var usePaymentDistributionMobile = function() {
    var queryClient = useQueryClient();
    return useMutation("paymentDistribution", function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
        LoggerInstance.debug("------PaymentDistribution");
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var authorizeCC = getFromLocalStorageByKey("authorize");
        var customerData = getFromLocalStorageByKey("customerData");
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var paymentData = getFromLocalStorageByKey("paymentData");
        var findCaller = getFromLocalStorageByKey("findCaller");
        var authorizationData = getFromLocalStorageByKey("authorizationRecurring");
        var payMeanData = getFromLocalStorageByKey("payMean");
        var lastSelectedType = localStorage.getItem(SELECTED_PAYMENT);
        var isWallet = (lastSelectedType === null || lastSelectedType === void 0 ? void 0 : lastSelectedType.toLowerCase()) === EMethodCode.WALLET.toLowerCase();
        var authorizationPayMean = authorizationData === null || authorizationData === void 0 ? void 0 : (ref = authorizationData.authorization) === null || ref === void 0 ? void 0 : ref.payMean;
        var foundedPayMean = payMeanData === null || payMeanData === void 0 ? void 0 : (ref1 = payMeanData.payMeans) === null || ref1 === void 0 ? void 0 : ref1.find(function(payMean) {
            return payMean.lastFourDigits === (authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.lastFourDigits) && payMean.type === (authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.type) && payMean.object_type === (authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.object_type);
        });
        var orderItemPaymentsFull = paymentData === null || paymentData === void 0 ? void 0 : (ref2 = paymentData.orderPayment) === null || ref2 === void 0 ? void 0 : ref2.orderItemPayments;
        var siaOrderId = authorizeCC === null || authorizeCC === void 0 ? void 0 : (ref3 = authorizeCC.authorizationParameters) === null || ref3 === void 0 ? void 0 : ref3.siaOrderId;
        var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref4 = customerData[0]) === null || ref4 === void 0 ? void 0 : ref4.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref5 = findCaller.customerRef) === null || ref5 === void 0 ? void 0 : ref5.id);
        var ownerIndividualId = (customerData === null || customerData === void 0 ? void 0 : (ref6 = customerData[0]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.owningIndividual) === null || ref7 === void 0 ? void 0 : ref7.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref8 = findCaller.individualRef) === null || ref8 === void 0 ? void 0 : ref8.contactId);
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var cartItems = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref9 = shoppingCart.cartItem) === null || ref9 === void 0 ? void 0 : ref9[0];
        var selectedPayMean;
        if (isWallet) {
            selectedPayMean = {
                type: EMethodCode.WALLET.toLowerCase()
            };
        } else {
            selectedPayMean = _object_spread({
                object_type: authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.object_type,
                type: authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.type,
                brand: authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.brand,
                displayBrand: authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.brand,
                holderName: " ",
                lastFourDigits: authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.lastFourDigits,
                expiryMonth: authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.expiryMonth,
                expiryYear: authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.expiryYear,
                isVerified: true,
                isExpired: false,
                ppbId: authorizationPayMean === null || authorizationPayMean === void 0 ? void 0 : authorizationPayMean.ppbId
            }, (foundedPayMean === null || foundedPayMean === void 0 ? void 0 : foundedPayMean.id) && {
                id: foundedPayMean.id
            });
        }
        var orderItemPayments = addPayloadObject([
            cartItems.id
        ], selectedPayMean, orderItemPaymentsFull) || [];
        var payload = {
            orderPayment: {
                customerId: customerId,
                barId: billingAccount.id,
                ownerIndividualId: ownerIndividualId,
                orderItemPayments: orderItemPayments
            }
        };
        LoggerInstance.debug("Payment distribution Payload", payload);
        return paymentDistributionService(cartId, siaOrderId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("paymentDistribution", JSON.stringify(data));
            queryClient.setQueryData("paymentDistribution", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - paymentDistribution: ", error);
            queryClient.setQueryData("paymentDistribution", errorMock("paymentDistribution", error));
            localStorage.setItem("paymentDistribution", JSON.stringify(errorMock("paymentDistribution", error)));
        }
    });
};
