import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
/**
 * This method is used retrieve the Available Countries' list from ApicWeb.
 * This in used for a FrontEnd dropdown.
 * Method: GET
 * SearchParams: salesChannel: selfService
 * @returns
 */ var countryService = function(customOptions) {
    return nextClient.get(API.COUNTRY, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the countryService
 * @param customOptions
 * @returns
 */ export var useGetCountry = function(customOptions) {
    var getCountrySaved = localStorage.getItem("getCountry");
    var getCountryDataFromStorage = !!getCountrySaved && getCountrySaved !== "undefined" && JSON.parse(getCountrySaved);
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "getCountry";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return countryService(customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: !getCountryDataFromStorage,
            onSuccess: function(data) {
                if (data) localStorage.setItem("getCountry", JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - getCountry: ", error);
                localStorage.setItem("getCountry", JSON.stringify(errorMock("getCountry", error)));
            }
        })
    };
    return handleUseQuery(options);
};
