import { useTracking } from "@vfit/shared/providers";
import { setTrackLink } from "@vfit/shared/data-access";
import { getTagging, getUserInfo } from "../../checkout.utils";
var ContactTagging = function() {
    var tagging = getTagging("contact", "fixed");
    var trackView = useTracking({
        event: [
            "checkout_step3"
        ],
        event_label: "contacts",
        opts: tagging.opts,
        cartProduct: tagging.trackingProduct,
        pageProduct: tagging === null || tagging === void 0 ? void 0 : tagging.pageProductsInfo,
        visitorTrackingOpts: getUserInfo()
    }).trackView;
    setTrackLink(trackView, "contacts");
    return null;
};
export default ContactTagging;
